import { Component, Input } from '@angular/core';
import { HorizontalAlignment, PositionAlignment, VerticalAlignment } from 'devextreme/common';
import { StatusConstants } from '../../constants';

@Component({
    selector: 'app-status-popover',
    templateUrl: './status-popover.component.html',
    styleUrl: './status-popover.component.scss'
})
export class StatusPopoverComponent {
    protected readonly STATUSES_WITH_LONG_FORM_TITLE = [StatusConstants.IMEI_UNAUTHORIZED];

    @Input({required: true})
    public statusId: number = -1;

    @Input({required: true})
    public componentId: string | undefined;

    @Input()
    public position: PositionAlignment | { x?: HorizontalAlignment, y?: VerticalAlignment } | undefined;

    protected getTitleTranslationKey(statusId: number) {
        if (this.STATUSES_WITH_LONG_FORM_TITLE.includes(statusId)) {
            return `status-${statusId}-long`;
        }
        return `status-${statusId}`;
    }
}
