<div *ngIf="showCellData" class="d-flex flex-row">
  <div class="me-auto container-fluid pe-3">
    <span class="col">
      {{ 'formats:number' | i18next: {val: smsCountMo} }}
      <i #popOverControlMO="ngbPopover" [ngbPopover]="popContentMO" autoClose="outside"
         class="fa-solid fa-arrow-up" container="body" placement="bottom"
         data-cy="MO-pop" popoverClass="modal-lg" triggers="mouseenter:mouseleave"></i>
    </span>
    <span *ngIf="showMt" class="col">
      {{ 'formats:number' | i18next: {val: smsCountMt} }}
      <i [ngbPopover]="popContentMT" autoClose="outside"
         class="fa-solid fa-arrow-down" container="body" placement="bottom"
         data-cy="MT-pop" popoverClass="modal-lg" triggers="mouseenter:mouseleave"></i>
    </span>
  </div>
  <div *hasPermission="Permission.SIM_EXECUTE_SENDSMS"
       #popOverControlEnvelope="ngbPopover" [ngbPopover]="popContentEnvelope"
       autoClose="outside" container="body" data-cy="envelope"
       placement="bottom-start" popoverClass="modal-lg" triggers="mouseenter:mouseleave">
    <button (click)="startEditMode()" class="btn fa-regular fa-envelope p-1"></button>
  </div>
</div>

<ng-template #popContentEnvelope>
  <div class="d-flex flex-row">
    <div class="d-flex align-self-center">
      <i class="fa-regular fa-envelope"></i>
    </div>
    <div class="ps-2">
      <div class="d-flex ">
        <span class="fw-bold flex-grow-1">{{ ("hoverPopUps.sms.envelope.title"| i18next) }}</span>
      </div>
      <div>{{ ("hoverPopUps.sms.envelope.content"| i18next) }}</div>
    </div>
  </div>
</ng-template>
<ng-template #popContentMO>
  <div class="d-flex flex-row">
    <div class="d-flex align-self-center">
      <i class="fa-solid fa-arrow-up"></i>
    </div>
    <div class="ps-2">
      <div class="d-flex ">
        <span class="fw-bold flex-grow-1">{{ ("hoverPopUps.sms.MO.title"| i18next) }}</span>
      </div>
      <div>{{ ("hoverPopUps.sms.MO.content"| i18next) }}</div>
    </div>
  </div>
</ng-template>
<ng-template #popContentMT>
  <div class="d-flex flex-row">
    <div class="d-flex align-self-center">
      <i class="fa-solid fa-arrow-down"></i>
    </div>
    <div class="ps-2">
      <div class="d-flex ">
        <span class="fw-bold flex-grow-1">{{ ("hoverPopUps.sms.MT.title"| i18next) }}</span>
      </div>
      <div>{{ ("hoverPopUps.sms.MT.content"| i18next) }}</div>
    </div>
  </div>
</ng-template>
