<div *ngIf="selectedRows" class="dropdown" data-cy="toolbar-bulk-action">
  <button class="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown"
          data-bs-auto-close="outside" aria-expanded="false">
    <i class="fa-solid fa-gear"></i> {{ 'actions' | i18next }}
  </button>
  <ul class="dropdown-menu user-select-none">
    <li *hasPermission="Permission.SIM_WRITE_STATUS">
      <button (click)="openModalSimStatus()" class="dropdown-item" data-cy="button-bulk-change-status">
        {{ 'table.header.statusid' | i18next }}
      </button>
    </li>
    <li *hasPermission="Permission.SIM_WRITE_CUSTOMFIELD1">
      <button (click)="openModalSimCustomField1()" class="dropdown-item" data-cy="button-bulk-change-custom-field1">
        {{ 'table.header.custom_field_1' | i18next }}
      </button>
    </li>
    <li *hasPermission="Permission.SIM_WRITE_DATALIMIT">
      <button (click)="openModalSimDataLimit()" class="dropdown-item" data-cy="button-bulk-change-data-limit">
        {{ 'table.header.monthly_data_limit' | i18next }}
      </button>
    </li>
    <li *hasPermission="Permission.SIM_WRITE_SMSLIMIT">
      <button (click)="openModalSimSmsLimit()" class="dropdown-item" data-cy="button-bulk-change-sms-limit">
        {{ 'table.header.monthly_sms_mt_limit' | i18next }}
      </button>
    </li>
    <li *hasPermission="Permission.SIM_WRITE_VOICELIMIT">
      <button (click)="openModalSimVoiceLimit()" class="dropdown-item" data-cy="button-bulk-change-voice-limit">
        {{ 'table.header.monthly_voice_mo_limit' | i18next }}
      </button>
    </li>
    <li *hasPermission="Permission.SIM_WRITE_END_CUSTOMER_LABEL">
      <button (click)="openModalSimEndCustomerLabel()" class="dropdown-item"
              data-cy="button-bulk-change-sim-end-customer-label">
        {{ 'table.header.end_customer_label' | i18next }}
      </button>
    </li>
    <li *hasPermission="Permission.SIM_WRITE_IMEI_LOCK">
      <button (click)="openModalImeiLock()" class="dropdown-item" data-cy="button-bulk-change-sim-imei-lock">
        {{ 'imeiLock' | i18next }}
      </button>
    </li>
  </ul>
</div>

<app-modal-bulk-change-sim-status #modalSimStatus [selectedSimCards]="selectedRows">
</app-modal-bulk-change-sim-status>
<app-modal-bulk-change-sim-custom-field1 #modalSimCustomField1 [selectedSimCards]="selectedRows">
</app-modal-bulk-change-sim-custom-field1>
<app-modal-bulk-change-sim-data-limit #modalSimDataLimit [selectedSimCards]="selectedRows">
</app-modal-bulk-change-sim-data-limit>
<app-modal-bulk-change-sim-sms-limit #modalSimSmsLimit [selectedSimCards]="selectedRows">
</app-modal-bulk-change-sim-sms-limit>
<app-modal-bulk-change-sim-voice-limit #modalSimVoiceLimit [selectedSimCards]="selectedRows">
</app-modal-bulk-change-sim-voice-limit>
<app-modal-bulk-change-sim-end-customer-label #modalSimEndCustomerLabel [selectedSimCards]="selectedRows">
</app-modal-bulk-change-sim-end-customer-label>
<app-modal-bulk-change-sim-imei-lock #modalSimImeiLock [selectedSimCards]="selectedRows">
</app-modal-bulk-change-sim-imei-lock>
