import { CognitoUser, CognitoUserSession, NodeCallback } from 'amazon-cognito-identity-js';
import i18next from 'i18next';
import { DateTime } from 'luxon';
import { Subject } from 'rxjs';
import { LoggerService } from '../logger';

/**
 * Helper class which is used for authentication and can be used as a singleton instance
 * in the code. This is "adopted" from Auth from AWS Amplify.
 */
export class WSimAuth {

    private userTimezone?: string;
    private currentUser: CognitoUser | null = null;
    private currentUserSession: CognitoUserSession | null = null;
    private customerId?: number;
    private userEmail?: string;
    private adminUser = false;
    private tokenRefreshed = new Subject<CognitoUserSession>();
    private tokenRefreshTimer?: NodeJS.Timeout;

    public setCurrentUser(currentUser: CognitoUser | null) {
        LoggerService.debug('setCurrentUser', currentUser);
        this.currentUser = currentUser;
    }

    public getCurrentUser(): CognitoUser | null {
        return this.currentUser;
    }

    public getUserTimeZone(): string {
        return this.getCurrentUserSession()?.getIdToken().payload['zoneinfo'] || 'UTC';
    }

    public getTokenRefreshedSubscription() {
        return this.tokenRefreshed.asObservable();
    }

    public setCurrentUserSession(currentUserSession: CognitoUserSession | null) {
        if (this.currentUserSession === currentUserSession) {
            return;
        }
        this.currentUserSession = currentUserSession;

        if (currentUserSession) {
            LoggerService.debug('setCurrentUserSession: ', currentUserSession.getIdToken().payload);

            this.scheduleRenewOfTokens(currentUserSession);

            const lang = currentUserSession.getIdToken().payload['locale'] || 'en';
            if (i18next.language !== lang) {
                LoggerService.debug('Changing user language to: ', lang);
                i18next.changeLanguage(lang).then();
            }
            const cognitoUserGroups = currentUserSession.getIdToken().payload['cognito:groups'];
            this.adminUser = cognitoUserGroups && cognitoUserGroups.includes('ADMIN');
            this.customerId = Number(currentUserSession.getIdToken().payload['custom:customerid']);
            this.userEmail = currentUserSession.getIdToken().payload['email'];
            this.userTimezone = currentUserSession.getIdToken().payload['timezone'];

            this.tokenRefreshed.next(currentUserSession);
        }
    }

    private scheduleRenewOfTokens(currentUserSession: CognitoUserSession) {
        const tokenExpire = DateTime.fromSeconds(currentUserSession.getIdToken().getExpiration());
        const delay = tokenExpire.minus({ minutes: 1 }).diff(DateTime.now());

        if (this.tokenRefreshTimer) {
            clearTimeout(this.tokenRefreshTimer);
        }

        this.tokenRefreshTimer = setTimeout(() => {
            this.refreshSession((_, session) => {
                LoggerService.info('Session refreshed!', session);
                if (session) {
                    this.setCurrentUserSession(session);
                }
            });
        }, delay.toMillis());
        LoggerService.info('Schedule token refresh in:', delay.toObject());
    }

    /**
     * Refreshes local session, if the user is logged in
     * @param callback the callback which is executed as soon as the session is refreshed (or not!) - first argument error, second result
     */
    public refreshSession(callback: NodeCallback<unknown, CognitoUserSession>) {
        if (this.currentUser && this.currentUserSession) {
            this.currentUser.refreshSession(this.currentUserSession.getRefreshToken(), callback);
        } else {
            LoggerService.warn('Unable to refresh session. Either the current user is not set or the session is not set!');
            callback.call(true, false);
        }
    }

    /**
     * Returns the current user session. Can be NULL when not initialized yet
     */
    public getCurrentUserSession(): CognitoUserSession | null {
        return this.currentUserSession;
    }

    /**
     * Clears all data
     */
    public clear() {
        this.currentUser = null;
        this.currentUserSession = null;
        this.userEmail = undefined;
        this.adminUser = false;
        this.userEmail = undefined;
        this.customerId = undefined;
    }

    public isAdmin(): boolean {
        return this.adminUser;
    }

    public getCurrentCustomerId(): number | undefined {
        return this.customerId;
    }

    public getCurrentEmail(): string | undefined {
        return this.userEmail;
    }

}

export const Auth = new WSimAuth();
