import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
    selector: 'app-data-volume-limit',
    templateUrl: './data-volume-limit.component.html',
    styleUrls: ['./data-volume-limit.component.scss'],
    animations: [
        trigger('fade', [
            state('visible', style({ opacity: 1 })),
            state('hidden', style({ opacity: 0 })),
            transition('visible <=> hidden', animate('600ms'))
        ])
    ]
})
export class DataVolumeLimitComponent implements ICellRendererAngularComp {
    protected dataVolumeLimit: number | undefined = undefined;
    protected hidden = true;
    private params: ICellRendererParams | undefined;
    protected showEdit: boolean = false;

    public agInit(params: ICellRendererParams): void {
        this.showEdit = !!(params.colDef?.editable);
        this.params = params;
        this.dataVolumeLimit = params.value;
    }

    public refresh(params: ICellRendererParams): boolean {
        if (this.dataVolumeLimit !== params.value) {
            this.dataVolumeLimit = params.value;
            return true;
        } else {
            return false;
        }
    }

    public startEditMode() {
        if (this.params?.column && this.params?.node.rowIndex != undefined) {
            this.params.api.startEditingCell({ rowIndex: this.params.node.rowIndex, colKey: this.params.column });
        }
    }
}
