import { Component } from '@angular/core';

@Component({
    selector: 'app-settings',
    templateUrl: './settings.component.html',
    styleUrl: './settings.component.scss'

})
export class SettingsComponent {

    /**
     * Here would be filled with the options for future settings like security, notifications, etc.
     * At the moment is only notifications implemented
     * */

}
