import { Component, OnDestroy } from '@angular/core';
import i18next from 'i18next';
import { Subscription } from 'rxjs';
import { environment } from '../environments/environment';
import { AuthService, LOGGED_IN } from './service/auth/auth.service';
import { Auth, Permission } from './service/auth/WSimAuth';
import { CustomerData, CustomerService } from './service/customer.service';
import { LoggerService } from './service/logger';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnDestroy {

    public isLoggedIn = false;
    public customerData: Promise<CustomerData> | undefined;
    public userEmail?: string;

    private readonly loginStatusSubscription: Subscription;
    protected readonly i18next = i18next;

    protected readonly LANGS = environment.supportedLanguages;

    public constructor(private authService: AuthService, customerService: CustomerService) {
        this.customerData = customerService.getCurrentCustomer();
        this.loginStatusSubscription = authService.subscribeAuthStatus().subscribe((value) => {
            if (value === LOGGED_IN) {
                this.isLoggedIn = true;
                this.userEmail = Auth.getCurrentEmail();
            } else {
                this.isLoggedIn = false;
            }
        });
    }

    public logout() {
        this.authService.logout();
    }

    public ngOnDestroy(): void {
        if (this.loginStatusSubscription) {
            this.loginStatusSubscription.unsubscribe();
        }
    }

    public switchLang(language: string) {
        LoggerService.debug('switchLang: ', language);
        if (language === this.i18next.language) {
            return;
        }
        if (this.isLoggedIn) {
            this.authService.updateUserAttribute('locale', language, (error, result) => {
                if (!error && result) {
                    // we need to update our local session!
                    Auth.refreshSession(() => {
                        this.i18next.changeLanguage(language).then(() => window.location.reload());
                    });
                } else {
                    LoggerService.warn('Cannot update attribute because: %s, %s', error, result);
                    if (error?.name.startsWith('NotAuthorizedException')) {
                        this.authService.logout();
                    }
                }
            });
        } else {
            this.i18next.changeLanguage(language).then(() => window.location.reload());
        }
    }

    protected readonly Permission = Permission;
}
