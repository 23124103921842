import { Component, Input, ViewChild } from '@angular/core';
import { Sim } from '../../../../graphql/graphql.generated';
import { ModalBulkChangeSimCustomField1Component } from '../../dialogs/modal-bulk-change-sim-custom-field1/modal-bulk-change-sim-custom-field1.component';
import { ModalBulkChangeSimDataLimitComponent } from '../../dialogs/modal-bulk-change-sim-data-limit/modal-bulk-change-sim-data-limit.component';
import { ModalBulkChangeSimEndCustomerLabelComponent } from '../../dialogs/modal-bulk-change-sim-end-customer-label/modal-bulk-change-sim-end-customer-label.component';
import { ModalBulkChangeSimSmsLimitComponent } from '../../dialogs/modal-bulk-change-sim-sms-limit/modal-bulk-change-sim-sms-limit.component';
import { ModalBulkChangeSimStatusComponent } from '../../dialogs/modal-bulk-change-sim-status/modal-bulk-change-sim-status.component';
import { ModalBulkChangeSimVoiceLimitComponent } from '../../dialogs/modal-bulk-change-sim-voice-limit/modal-bulk-change-sim-voice-limit.component';
import { Auth, Permission } from '../../service/auth/WSimAuth';

@Component({
    selector: 'app-bulk-actions',
    templateUrl: './bulk-actions.component.html',
    styleUrl: './bulk-actions.component.scss'
})
export class BulkActionsComponent {

    @Input()
    public selectedRows?: Sim[] = undefined;

    @ViewChild('modalSimStatus') private modalSimStatus?: ModalBulkChangeSimStatusComponent;
    @ViewChild('modalSimCustomField1') private modalSimCustomField1?: ModalBulkChangeSimCustomField1Component;
    @ViewChild('modalSimDataLimit') private modalSimDataLimit?: ModalBulkChangeSimDataLimitComponent;
    @ViewChild('modalSimSmsLimit') private modalSimSmsLimit?: ModalBulkChangeSimSmsLimitComponent;
    @ViewChild('modalSimVoiceLimit') private modalSimVoiceLimit?: ModalBulkChangeSimVoiceLimitComponent;
    @ViewChild('modalSimEndCustomerLabel') private modalSimCustomerLabel?: ModalBulkChangeSimEndCustomerLabelComponent;

    protected hasPermission(key: string) : boolean{
        return Auth.getcurrentUserPermissionsList().has(key)
    }

    public openModalSimStatus(): void {
        this.modalSimStatus?.open();
    }

    public openModalSimCustomField1(): void {
        this.modalSimCustomField1?.open();
    }

    public openModalSimDataLimit(): void {
        this.modalSimDataLimit?.open();
    }

    public openModalSimSmsLimit(): void {
        this.modalSimSmsLimit?.open();
    }

    public openModalSimVoiceLimit(): void {
        this.modalSimVoiceLimit?.open();
    }

    public openModalSimEndCustomerLabel(): void {
        this.modalSimCustomerLabel?.open();
    }

    protected readonly Permission = Permission;

}
