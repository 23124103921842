<div class="d-flex flex-row">

  <div class="me-auto container-fluid pe-3">
    <span *ngIf="smsLimitExceeded" class="col">
      <i [id]="'exclamationMark' + iccId" class="fa-solid fa-triangle-exclamation icon-color-exceeded">
        <app-status-popover [statusId]="StatusConstants.SMS_LIMIT_EXCEEDED"
                            [componentId]="'exclamationMark' + iccId">
        </app-status-popover>
      </i>
    </span>
    <span *ngIf="moLimit" class="col" data-cy="mo-limit">
      {{ 'formats:number' | i18next: {val: moLimit} }}
      <i class="fa-solid fa-arrow-up" data-cy="MO-pop"></i>
    </span>
    <span *ngIf="mtLimit" class="col" data-cy="mt-limit">
      {{ 'formats:number' | i18next: {val: mtLimit} }}
      <i class="fa-solid fa-arrow-down" data-cy="MT-pop"></i>
    </span>
  </div>
  <div *ngIf="showEdit" (click)="startEditMode()" class="btn">
    <i class="fa-solid fa-pen"></i>
  </div>
</div>
