import { Component, Input, TemplateRef, ViewChild } from '@angular/core';
import { FetchResult } from '@apollo/client/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { I18NextPipe } from 'angular-i18next';
import { Sim, UpdateSimMutation } from '../../../../graphql/graphql.generated';
import { GraphqlService } from '../../service/graphql.service';
import { LoggerService } from '../../service/logger';
import { ToastMessageBuilder, ToastService } from '../../service/toast.service';
import { StatusConstants } from '../../shared/constants';
import { ProviderFeatures } from '../../shared/provider-features';

@Component({
    selector: 'app-modal-bulk-change-sim-sms-limit',
    templateUrl: './modal-bulk-change-sim-sms-limit.component.html',
    styleUrl: './modal-bulk-change-sim-sms-limit.component.scss'
})
export class ModalBulkChangeSimSmsLimitComponent {

    // for input from another component
    @Input()
    public selectedSimCards?: Sim[];

    protected inProgress: boolean = false;
    protected saveEnabled: boolean = false;

    @ViewChild('content')
    private content: TemplateRef<never> | undefined;

    private modal?: NgbModalRef;
    protected unsavedMoLimit?: string | undefined;
    protected unsavedMtLimit?: string | undefined;
    protected showMt: boolean = false;

    protected disabled: number = 0;
    protected enabled: number = 0;
    protected enabledList: Sim[] = [];
    protected disabledList: Sim[] = [];

    public constructor(private graphQlService: GraphqlService, private toastService: ToastService,
                       private i18nextPipe: I18NextPipe, private modalService: NgbModal) {
    }

    public open(): NgbModalRef {
        this.evaluateTargets();
        this.modal = this.modalService.open(this.content, { ariaLabelledBy: 'bulkChangeSimSmsLimitLabel' });
        return this.modal;
    }

    public close(): void {
        this.modal?.close();
    }

    protected onKeyDown($event: KeyboardEvent): void {
        if ($event.key === 'Escape') {
            this.close();
        } else if ($event.key === 'Enter') {
            this.commitChange();
        } else if ($event.code.startsWith('Arrow')) {
            // Allow cursor movement
            $event.stopPropagation();
        } else if ($event.metaKey && $event.code === 'KeyA') {
            // Select All
            $event.stopPropagation();
        }
    }

    protected commitChange(): void {
        if (this.inProgress) return;
        if (!this.selectedSimCards || this.selectedSimCards.length === 0) return;
        this.inProgress = true;
        const moLimit: number | undefined = this.unsavedMoLimit ? Number.parseInt(this.unsavedMoLimit) : 0;
        let mtLimit: number | undefined = this.unsavedMtLimit ? Number.parseInt(this.unsavedMtLimit) : undefined;
        if (this.showMt) {
            mtLimit = mtLimit || 0;
        }

        // collect the promises and wait with closing the dialog until we sent out all ...
        const successfulArray: string[] = [];
        const promises: Promise<FetchResult<UpdateSimMutation>>[] = this.selectedSimCards.map((simCard: Sim): Promise<FetchResult<UpdateSimMutation>> => {
            if (ProviderFeatures.hasMtSmsNumbers(simCard.providerid)) {
                return this.graphQlService.updateSim(simCard.iccid, undefined, undefined, undefined, moLimit, mtLimit);
            }
            return this.graphQlService.updateSim(simCard.iccid, undefined, undefined, undefined, moLimit);
        });

        // very important: promises are resolved in order. so we can access the original array for icc id mutation was called for:
        // https://stackoverflow.com/a/67274260
        Promise.allSettled(promises).then((results: PromiseSettledResult<FetchResult<UpdateSimMutation>>[]): void => {
            results.forEach((promiseResult, index: number): void => {
                if (promiseResult.status === 'fulfilled') {
                    if (promiseResult.value.data?.updateSim.iccid) {
                        successfulArray.push(promiseResult.value.data?.updateSim.iccid);
                    }
                } else {
                    const iccId = this.selectedSimCards![index].iccid;
                    const text = this.i18nextPipe.transform('bulkSmsLimitChange.errorChange', {
                        iccId: iccId,
                        error: promiseResult.reason?.message
                    });
                    this.toastService.show(ToastMessageBuilder.error().text(text).build());
                }
            });
            this.inProgress = false;
            if (successfulArray.length > 0) {
                const text = this.i18nextPipe.transform('bulkSmsLimitChange.successChange', { count: successfulArray.length });
                this.toastService.show(ToastMessageBuilder.success().text(text).build());
            }
            this.modal?.close();
        });
    }

    protected evaluateTargets(): void {
        const enableSet: Set<Sim> = new Set<Sim>();
        const disableSet: Set<Sim> = new Set<Sim>();
        const moLimit: number | undefined = this.unsavedMoLimit ? Number.parseInt(this.unsavedMoLimit) : undefined;
        const mtLimit: number | undefined = this.unsavedMtLimit ? Number.parseInt(this.unsavedMtLimit) : undefined;

        let hasMt = false;

        LoggerService.debug('Eval: New molLimit and mtLimit', moLimit, mtLimit);
        this.selectedSimCards?.forEach((sim: Sim): void => {
            LoggerService.debug('Eval:', sim.monthly_sms_mt_limit, sim.monthly_sms_mo_limit, sim.statusid);

            if (!hasMt && ProviderFeatures.hasMtSmsNumbers(sim.providerid)) {
                hasMt = true;
            }

            if (mtLimit && ProviderFeatures.hasMtSmsNumbers(sim.providerid)) {
                if (this.isLimitIncreased(mtLimit, sim, 'mt') && this.isSimGetsActivated(mtLimit, sim, 'mt') && !enableSet.has(sim)) {
                    enableSet.add(sim);
                }

                if (this.isSimGetsDeactivated(mtLimit, sim, 'mt') && !disableSet.has(sim)) {
                    disableSet.add(sim);
                }
            }

            if (this.isLimitIncreased(moLimit, sim, 'mo') && this.isSimGetsActivated(moLimit, sim, 'mo') && !enableSet.has(sim)) {
                enableSet.add(sim);
            }

            if (this.isSimGetsDeactivated(moLimit, sim, 'mo') && !disableSet.has(sim)) {
                disableSet.add(sim);
            }
        });

        this.showMt = hasMt;
        this.disabled = disableSet.size;
        this.enabled = enableSet.size;
        this.enabledList = [...enableSet];
        this.disabledList = [...disableSet];
    }

    private isLimitIncreased(newLimit: number | undefined, sim: Sim, smsType: string): boolean {
        const mtLimit: number | undefined = sim.monthly_sms_mt_limit ? sim.monthly_sms_mt_limit : undefined;
        const moLimit: number | undefined = sim.monthly_sms_mo_limit ? sim.monthly_sms_mo_limit : undefined;
        if (newLimit) {
            if (smsType === 'mt') {
                return (!!mtLimit && newLimit > mtLimit);
            }
            return (!!moLimit && newLimit > moLimit);
        }
        return (!!mtLimit || !!moLimit);
    }

    private isSimGetsActivated(newLimit: number | undefined, sim: Sim, smsType: string): boolean {
        const mt: number = sim.monthly_sms_mt ? sim.monthly_sms_mt : 0;
        const mo: number = sim.monthly_sms_mo ? sim.monthly_sms_mo : 0;
        if (sim.statusid === StatusConstants.SMS_LIMIT_EXCEEDED) {
            if (newLimit) {
                if (smsType === 'mt') {
                    return (newLimit > mt);
                }
                return (newLimit > mo);
            }
            return true;
        }
        return false;
    }

    private isSimGetsDeactivated(newLimit: number | undefined, sim: Sim, smsType: string): boolean {
        const mt: number = sim.monthly_sms_mt ? sim.monthly_sms_mt : 0;
        const mo: number = sim.monthly_sms_mo ? sim.monthly_sms_mo : 0;
        if (sim.statusid === StatusConstants.ACTIVATED) {
            if (newLimit) {
                if (smsType === 'mt') {
                    return (newLimit <= mt);
                }
                return (newLimit <= mo);
            }
            return false;
        }
        return false;
    }

    protected getQueryParamFilter(params: Sim[]): { filter: string } {
        const filter = {
            'iccid': {
                'filterType': 'text',
                'type': 'in',
                'filter': params.map((value: Sim): string => value.iccid).join(',')
            }
        };
        return { filter: JSON.stringify(filter) };
    }
}
