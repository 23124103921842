import { Component, Input } from '@angular/core';
import { Sim } from '../../../../../graphql/graphql.generated';

@Component({
    selector: 'app-sim-detail-status',
    templateUrl: './sim-detail-status.component.html',
    styleUrl: './sim-detail-status.component.scss'
})
export class SimDetailStatusComponent {
    @Input() public sim?: Sim;

}
