<div [style.width]="cellWidth" class="container-fluid p-0">
  <div class="d-flex justify-content-end w-100 g-0 p-0 ps-1">
    <div class="d-inline-flex align-items-center p-1 w-100">
      <label class="visually-hidden col-form-label col-form-label-sm"
             for="inlineFormInputGroupEndCustomerLabel">Limit</label>
      <div class="input-group input-group-sm align-items-center">
        <dx-autocomplete
          #endCustomerLabelInput
          class="form-control form-control-sm text-input"
          [(value)]="unsavedLabel"
          [minSearchLength]="3"
          [searchTimeout]="300"
          [dataSource]="store"
          [maxItemCount]="maxItemCount"
          maxLength="255"
          [height]="10"
          (keydown)="onKeyDown($event)"
          id="inlineFormInputGroupEndCustomerLabel"
        >
        </dx-autocomplete>
        <i class="fa-solid fa-circle-xmark input-container-icon" (click)="clearInput()"></i>
        <i *ngIf="loadingSuggestions" class="fa-solid fa-solid fa-circle-notch fa-spin loading-container-icon"></i>
      </div>
    </div>
    <div class="d-inline-flex align-items-center pe-1">
      <a (click)="submitSave($event)" data-cy="end-customer-label-save-button"
         class="btn btn-sm btn-primary right-rounded-corners">
        <i *ngIf="!saving" class="fa-solid fa-check"></i>
        <i *ngIf="saving" class="fa-solid fa-solid fa-circle-notch fa-spin"></i>
      </a>
    </div>
  </div>
</div>

