import { Component, inject, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FetchResult } from '@apollo/client/core';
import { I18NextPipe } from 'angular-i18next';
import { CancelLocationMutation, Sim } from '../../../../../graphql/graphql.generated';
import { GraphqlService } from '../../../service/graphql.service';
import { ToastMessageBuilder, ToastService } from '../../../service/toast.service';

@Component({
    selector: 'app-sim-detail-cancel-location',
    templateUrl: './sim-detail-cancel-location.component.html',
    styleUrl: './sim-detail-cancel-location.component.scss'
})
export class SimDetailCancelLocationComponent implements OnChanges {

    @Input()
    public sim?: Sim;

    private graphqlService: GraphqlService = inject(GraphqlService);
    private toastService: ToastService = inject(ToastService);
    private i18Next: I18NextPipe = inject(I18NextPipe);

    private timer: NodeJS.Timeout | undefined;
    protected btnDisable: boolean = false;
    protected isLoading: boolean = false;
    protected blockedUntil: number = 0;

    protected sendRequest(): void {
        if (this.isLoading || !this.sim?.iccid) return;
        this.isLoading = true;
        this.btnDisable = true;
        this.graphqlService.cancelLocation(this.sim?.iccid).then((result: FetchResult<CancelLocationMutation>): void => {
            if (!result.data) return;
            this.blockedUntil = result.data.cancelLocation.blockedUntil;
            this.btnDisable = (this.blockedUntil > Date.now());
            if (this.btnDisable) {
                this.timer = setTimeout((): void => {
                    this.resetProperties();
                }, this.blockedUntil - Date.now());
            }
        }).catch((error): void => {
            this.toastService.show(ToastMessageBuilder.error().text(
                this.i18Next.transform('cancelLocation.error', { errorMsg: error.message })
            ).build());
        }).finally((): void => {
            this.isLoading = false;
        });
    }

    private resetProperties(): void {
        this.btnDisable = false;
        this.blockedUntil = 0;
        if (this.timer) {
            clearTimeout(this.timer);
            this.timer = undefined;
        }
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes['sim'].previousValue && (changes['sim'].previousValue !== changes['sim'].currentValue)) this.resetProperties();
    }
}
