import { Component, inject, OnDestroy } from '@angular/core';
import { NavigationEnd, Router, Scroll } from '@angular/router';
import i18next from 'i18next';
import { Subscription } from 'rxjs';
import { environment } from '../environments/environment';
import { AuthService } from './service/auth/auth.service';
import { Auth, Permission } from './service/auth/WSimAuth';
import { CustomerData, CustomerService } from './service/customer.service';
import { LoggerService } from './service/logger';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnDestroy {

    public isLoggedIn = false;
    public customerData: Promise<CustomerData> | undefined;
    public userEmail?: string;

    private readonly loginStatusSubscription?: Subscription;
    private readonly routeChangeSubscription?: Subscription;
    protected readonly i18next = i18next;

    protected readonly LANGS = environment.supportedLanguages;
    protected showSimListNavigation = false;

    private router: Router = inject(Router);
    private authService: AuthService = inject(AuthService);
    private customerService: CustomerService = inject(CustomerService);


    public constructor() {
        this.customerData = this.customerService.getCurrentCustomer();
        // first: check if the user is already logged in (keep in mind - refresh of page!)
        this.authStatusChange();
        // second: subscribe to authentication status changes and react accordingly!
        this.loginStatusSubscription = this.authService.subscribeAuthStatus().subscribe(() => {
            this.authStatusChange();
        });
        // register for route changes - we do not want to display the SIM list navigation when
        // the user is already on a sim list component
        this.routeChangeSubscription = this.router.events.subscribe((value) => {
            if (value instanceof Scroll && value.routerEvent instanceof NavigationEnd) {
                this.showSimListNavigation = value.routerEvent.urlAfterRedirects != '/sim-list';
            }
        });
    }

    public logout() {
        this.authService.logout();
    }

    public ngOnDestroy(): void {
        this.loginStatusSubscription?.unsubscribe();
        this.routeChangeSubscription?.unsubscribe();
    }

    public switchLang(language: string) {
        LoggerService.debug('switchLang: ', language);
        if (language === this.i18next.language) {
            return;
        }
        if (this.isLoggedIn) {
            this.authService.updateUserAttribute('locale', language, (error, result) => {
                if (!error && result) {
                    // we need to update our local session!
                    Auth.refreshSession(() => {
                        this.i18next.changeLanguage(language).then(() => window.location.reload());
                    });
                } else {
                    LoggerService.warn('Cannot update attribute because: %s, %s', error, result);
                    if (error?.name.startsWith('NotAuthorizedException')) {
                        this.authService.logout();
                    }
                }
            });
        } else {
            this.i18next.changeLanguage(language).then(() => window.location.reload());
        }
    }

    private authStatusChange(): void {
        if (this.authService.isLoggedIn()) {
            this.isLoggedIn = true;
            this.userEmail = Auth.getCurrentEmail();
        } else {
            this.isLoggedIn = false;
        }
    }

    protected readonly Permission = Permission;
    protected readonly environment = environment;
}
