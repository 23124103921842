import { Injectable } from '@angular/core';


export interface ToastMessage {
    text: string,
    delay: number,
    autoHide: boolean,
    className: string
}

interface Builder {
    text(text: string): void;

    delay(delay: number): void;

    autoHide(autoHide: boolean): void;

    className(className: string): void;
}

class SuccessToastMessage implements ToastMessage {
    public text: string = '';
    public delay = 5000;
    public autoHide = true;
    public className = 'bg-success text-light';
}

class ErrorToastMessage implements ToastMessage {
    public text: string = '';
    public delay = 5000;
    public autoHide = false;
    public className: string = 'bg-danger text-light';
}

export class ToastMessageBuilder implements Builder {
    private toastMessage: ToastMessage | undefined;

    public static success(): ToastMessageBuilder {
        return new ToastMessageBuilder(new SuccessToastMessage());
    }

    public static error(): ToastMessageBuilder {
        return new ToastMessageBuilder(new ErrorToastMessage());
    }

    protected constructor(toastMessage: ToastMessage) {
        this.toastMessage = toastMessage;
    }

    public text(text: string): ToastMessageBuilder {
        if (!this.toastMessage) throw new Error('Call first success() or error() method!');
        this.toastMessage.text = text;
        return this;
    }

    public delay(delay: number): ToastMessageBuilder {
        if (!this.toastMessage) throw new Error('Call first success() or error() method!');
        this.toastMessage.delay = delay;
        return this;
    }

    public autoHide(autoHide: boolean): ToastMessageBuilder {
        if (!this.toastMessage) throw new Error('Call first success() or error() method!');
        this.toastMessage.autoHide = autoHide;
        return this;
    }

    public className(className: string): ToastMessageBuilder {
        if (!this.toastMessage) throw new Error('Call first success() or error() method!');
        this.toastMessage.className = className;
        return this;
    }

    public reset(): void {
        this.toastMessage = undefined;
    }

    public build(): ToastMessage {
        if (!this.toastMessage) throw new Error('Call first success() or error() method!');
        const result = this.toastMessage;
        this.reset();
        return result;
    }

}

@Injectable({ providedIn: 'root' })
export class ToastService {
    public toasts: ToastMessage[] = [];

    public show(toastMessage: ToastMessage) {
        this.toasts.push(toastMessage);
    }

    public remove(toastMessage: ToastMessage) {
        this.toasts = this.toasts.filter(t => t !== toastMessage);
    }

    public clear() {
        this.toasts.splice(0, this.toasts.length);
    }
}
