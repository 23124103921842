import { Component, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { environment } from '../../../environments/environment';
import { AuthService } from '../../service/auth/auth.service';
import { LoggerService } from '../../service/logger';
import { PasswordStrengthComponent } from '../../shared/password-strength/password-strength.component';

@Component({
    selector: 'app-change-pass',
    templateUrl: './change-password.component.html',
    styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent {
    protected readonly environment = environment;

    protected form: FormGroup;
    protected error: string | undefined;
    protected newPassDisabled = false;

    protected passwordGuidelinesMatch = false;
    protected newPasswordMatching = true;
    protected oldPassMatchesNewPass = false;

    @ViewChild(PasswordStrengthComponent)
    private passwordStrengthComponent!: PasswordStrengthComponent;

    public constructor(private formBuilder: FormBuilder, private authService: AuthService) {
        this.form = this.formBuilder.group({
            oldPassword: [null, Validators.required],
            newPassword: [null, Validators.required],
            newPasswordRepeat: [null, [Validators.required]]
        });
    }

    protected changePassword() {
        this.error = undefined;
        this.newPassDisabled = true;
        const newPass = this.form.value['newPassword'];
        const oldPass = this.form.value['oldPassword'];

        const cognitoUser = this.authService.getCurrentUser();
        if (cognitoUser != null) {
            cognitoUser?.changePassword(oldPass, newPass, (err: Error | undefined) => {
                if (err) {
                    LoggerService.warn(`Got error on changePassword: [message:${err.message}] [name:${err.name}] [cause:${err.cause}]`);
                    if (err.name === 'NotAuthorizedException' && !err.message.startsWith('Incorrect username or password')) {
                        LoggerService.warn('NotAuthorized: Access Token revoked or not logged in. Logout.');
                        this.authService.logout();
                    } else {
                        this.error = this.getError(err);
                        this.newPassDisabled = false;
                    }
                } else {
                    // successful changed password. logout from all!
                    this.authService.logout(true);
                }
            });
        } else {
            this.authService.logout();
        }
    }

    /**
     * Tries to evaluate the error given to a translation that can be shown to the user
     * @param err the err to evaluate
     * @private
     */
    private getError(err: Error): string {
        if (err.name === 'LimitExceededException') {
            return 'changePassword.errors.limitExceeded';
        } else if (err.name === 'InvalidPasswordException') {
            return 'changePassword.errors.invalidNewPassword';
        } else if (err.name === 'NotAuthorizedException' && err.message.startsWith('Incorrect username or password')) {
            return 'changePassword.errors.oldPasswordIncorrect';
        }
        return err.message;
    }

    protected onNewRepeatPasswordChanged() {
        this.validatePasswordsNewAndOld();
    }

    protected onOldPasswordChanged() {
        this.validatePasswordsNewAndOld();
    }

    protected onNewPasswordChanged() {
        const newPass = this.form.value['newPassword'];
        this.passwordGuidelinesMatch = this.passwordStrengthComponent.checkPassword(newPass);

        this.validatePasswordsNewAndOld();
    }

    private validatePasswordsNewAndOld() {
        const newPass = this.form.value['newPassword'];
        const newPassRepeat = this.form.value['newPasswordRepeat'];
        const oldPass = this.form.value['oldPassword'];
        if (newPass && newPass.length > 0 && newPassRepeat && newPassRepeat.length > 0) {
            this.newPasswordMatching = newPass === newPassRepeat;
        }
        if (newPass && newPass.length > 0 && oldPass && oldPass.length > 0) {
            this.oldPassMatchesNewPass = newPass === oldPass;
        }
    }

}
