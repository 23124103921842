import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, inject } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { I18NextPipe } from 'angular-i18next';
import { ImeiLock, ImeiLockInput } from '../../../../../graphql/graphql.generated';
import { Auth, Permission } from '../../../service/auth/WSimAuth';
import { GraphqlService } from '../../../service/graphql.service';
import { LoggerService } from '../../../service/logger';
import { ToastMessageBuilder, ToastService } from '../../../service/toast.service';

@Component({
    selector: 'app-imei',
    templateUrl: './imei.component.html',
    styleUrl: './imei.component.scss',
    animations: [
        trigger('fade', [
            state('visible', style({ opacity: 1 })),
            state('hidden', style({ opacity: 0 })),
            transition('visible <=> hidden', animate('600ms'))
        ])
    ]
})
export class ImeiComponent implements ICellRendererAngularComp {

    public hidden: boolean = true;
    protected params: ICellRendererParams | undefined;

    protected locked = false;
    protected lockedImei?: string;
    protected iccId?: string;
    protected deviceImei?: string;
    protected saving = false;
    protected editEnabled = false;
    protected lockButtonClass = '';
    protected autoDetectButtonEnabled = true;

    protected graphQlService = inject(GraphqlService);
    protected toastService = inject(ToastService);
    protected i18next = inject(I18NextPipe);

    private STANDARD_BUTTON_UNLOCKED = 'fa-solid fa-unlock color-unlocked p-0 mb-1 ';
    private STANDARD_BUTTON_LOCKED = 'fa-solid fa-lock color-locked p-0 mb-1 ';


    public agInit(params: ICellRendererParams): void {
        this.params = params;
        this.evaluateParams();
        this.editEnabled = Auth.getcurrentUserPermissionsList().has(Permission.SIM_WRITE_IMEI_LOCK);
        this.setLockButtonClass();
    }

    /**
     * Mandatory - Return true if you have managed the cell refresh yourself in this method, otherwise return false.
     * If you return false, the grid will remove the component from the DOM and create a new component in its place
     * with the new values.
     * @param params
     */
    public refresh(params: ICellRendererParams): boolean {
        this.params = params;
        this.evaluateParams();
        return false;
    }

    private evaluateParams() {
        this.locked = this.params?.data?.imei_lock?.enabled;
        this.lockedImei = this.params?.data?.imei_lock?.imei;
        this.iccId = this.params?.data?.iccid;
        this.deviceImei = this.params?.data?.imei;
        // disable the auto detect button if auto detect is already active
        if (this.locked && !this.lockedImei) {
            this.autoDetectButtonEnabled = false;
        }
        // in any case: disable auto detect button when the user has no permission to write the imei lock
        if (!Auth.getcurrentUserPermissionsList().has(Permission.SIM_WRITE_IMEI_LOCK)) {
            this.autoDetectButtonEnabled = false;
        }
    }

    protected copyClick($event: MouseEvent) {
        $event.stopPropagation();
    }

    protected switchImeiLock($event: MouseEvent) {
        $event.stopPropagation();
        if (this.editEnabled && !this.saving) {
            const setLock = !this.locked;
            const imeiToLock = setLock ? this.deviceImei : undefined;
            this.callUpdateSim(setLock, setLock ? {
                enabled: setLock,
                imei: imeiToLock ? imeiToLock : null
            } : null, imeiToLock);
        }
    }

    protected setAutoDetect($event: MouseEvent) {
        $event.stopPropagation();
        if (this.editEnabled && !this.saving) {
            this.callUpdateSim(true, {
                imei: null, enabled: true
            }, undefined);
        }
    }

    private setLockButtonClass() {
        const appliedClasses = [];
        if (this.editEnabled) {
            appliedClasses.push('btn');
        }
        if (this.saving) {
            appliedClasses.push('fa-disabled');
        }
        const finalClass = this.locked ? this.STANDARD_BUTTON_LOCKED : this.STANDARD_BUTTON_UNLOCKED;
        this.lockButtonClass = finalClass + appliedClasses.join(' ');
    }

    private callUpdateSim(enableImeiLock: boolean, expectedResult: ImeiLock | null, imeiToLock?: string) {
        if (this.iccId) {
            this.saving = true;
            this.setLockButtonClass();
            const imei_lock_input: ImeiLockInput = { lock: enableImeiLock };
            if (enableImeiLock) {
                imei_lock_input.imei = imeiToLock;
            }
            this.graphQlService.updateSim(this.iccId, undefined, undefined, undefined, undefined, undefined, undefined, undefined, imei_lock_input).then((result) => {
                if (result.data) {
                    if (!this.isImeiLockSame(result.data.updateSim.imei_lock, expectedResult)) {
                        LoggerService.warn('Result from update not ok, result (received, expected): ', result.data.updateSim.imei_lock, expectedResult);
                        this.toastService.show(ToastMessageBuilder.error().text(this.i18next.transform('error.imeiLockUpdate.generic')).build());
                    } else {
                        this.locked = enableImeiLock;
                        this.lockedImei = imeiToLock;
                    }
                    this.saving = false;
                    this.setLockButtonClass();
                }
            }).catch((error) => {
                this.toastService.show(ToastMessageBuilder.error().text(this.i18next.transform('error.imeiLockUpdate.withMessage', { errorMsg: error.message })).build());
                this.saving = false;
                this.setLockButtonClass();
            });
        }
    }

    private isImeiLockSame(lock1: ImeiLock | null | undefined, lock2: ImeiLock | null | undefined): boolean {
        if (lock1 === lock2) {
            return true;
        }
        return !(lock1?.imei !== lock2?.imei || lock1?.enabled !== lock2?.enabled);

    }

    protected readonly Permission = Permission;
}
