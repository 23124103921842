<div class="d-flex flex-row">

  <div class="me-auto container-fluid pe-3">
    <span *ngIf="voiceLimitExceeded" class="col">
      <i [id]="'exclamationMarkVoiceLimit' + iccId" class="fa-solid fa-triangle-exclamation icon-color-exceeded">
        <app-status-popover [statusId]="StatusConstants.VOICE_LIMIT_EXCEEDED"
                            [componentId]="'exclamationMarkVoiceLimit' + iccId">
        </app-status-popover>
      </i>
    </span>
    <span class="col" data-cy="voice-mo-limit">
      {{ moVoiceLimit ? ('voiceLimit.cell.minutes' | i18next: {val: moVoiceLimit}) : undefined }}
    </span>
  </div>
  <div *hasPermission="Permission.SIM_WRITE_SMSLIMIT" (click)="startEditMode()" class="btn">
    <i class="fa-solid fa-pen"></i>
  </div>
</div>




