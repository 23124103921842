<div class="main-panel">
  <div class="row mt-2 ms-2 me-2">
    <p class="col-12 text-center fw-bold">
      {{ 'status_filter_header_text' | i18next }}
    </p>
  </div>
  <div class="d-flex row mt-2 ms-2 me-2">
    <div *ngFor="let option of statuses; let i = index" class="col-6 d-flex mb-1">
      <input
        (change)="statusChanged($event)"
        [(ngModel)]="option.selected"
        [checked]="option.selected"
        class="align-self-center"
        id="checkbox_{{option.id}}"
        type="checkbox"/>
      <label class="align-self-center ms-1"
             for="checkbox_{{option.id}}">{{ 'status.' + option.id | i18next }}</label>
    </div>
  </div>
  <div class="row mt-2 ms-2 me-2 mb-2">
    <div class="col-12 text-center">
      <button (click)="reset()" class="btn btn-dark btn-sm" type="button">{{ 'reset' | i18next }}</button>
    </div>
  </div>
</div>
