"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
/*!
 * Copyright 2017-2018 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: Apache-2.0
 */
var logger_1 = require("./logger");
exports.rootLogger = logger_1.default;