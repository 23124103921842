import { environment } from '../../environments/environment';

/* eslint-disable  @typescript-eslint/no-explicit-any */
export class LoggerService {

    public static info(value: any, ...rest: any[]): void {
        if (!environment.production) {
            console.info('%c%s', 'color: grey;', value, ...rest);
        }
    }

    public static debug(value: any, ...rest: any[]): void {
        if (!environment.production) {
            console.log('%c%s', 'color: green;', value, ...rest);
        }
    }

    public static warn(value: any, ...rest: any[]): void {
        if (!environment.production) {
            console.warn('%c%s', 'color: orange;', value, ...rest);
        }
    }

    public static error(value: any, ...rest: any[]): void {
        if (!environment.production) {
            console.error('%c%s', 'color: red;', value, ...rest);
        }
    }
}
