import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import * as countries from 'i18n-iso-countries';
import { Alpha2Code, Alpha3Code, LocaleData } from 'i18n-iso-countries';
import i18next from 'i18next';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class I18nCountriesService {

    private http = inject(HttpClient);

    private loadedLangs: string[] = [];

    private loadingLang: Observable<LocaleData> | undefined;

    public constructor() {
        this.loadLanguages(i18next.language);
        i18next.on('languageChanged', (lng) => this.loadLanguages(lng));
    }

    public getCountryName(countryCode: string | number | Alpha2Code | Alpha3Code): Promise<string | undefined> {
        return new Promise((resolve) => {
            if (this.loadingLang) {
                this.loadingLang.subscribe(() => {
                    resolve(countries.getName(countryCode, i18next.language));
                });
            } else {
                resolve(countries.getName(countryCode, i18next.language));
            }
        });
    }

    private loadLanguages(lng: string) {
        if (!this.loadedLangs.includes(lng) && !this.loadingLang) {
            this.loadingLang = this.http.get<LocaleData>(`assets/i18n-iso-countries/${lng}.json`);
            this.loadingLang.subscribe((res) => {
                this.loadedLangs.push(lng);
                countries.registerLocale(res);
                this.loadingLang = undefined;
            });
        }
    }

}
