import { __extends } from "tslib";
import IterResult from './iterresult';
/**
 * IterResult subclass that calls a callback function on each add,
 * and stops iterating when the callback returns false.
 */
var CallbackIterResult = /** @class */function (_super) {
  __extends(CallbackIterResult, _super);
  function CallbackIterResult(method, args, iterator) {
    var _this = _super.call(this, method, args) || this;
    _this.iterator = iterator;
    return _this;
  }
  CallbackIterResult.prototype.add = function (date) {
    if (this.iterator(date, this._result.length)) {
      this._result.push(date);
      return true;
    }
    return false;
  };
  return CallbackIterResult;
}(IterResult);
export default CallbackIterResult;
