import { Component, inject, Input, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router, UrlTree } from '@angular/router';
import { FetchResult } from '@apollo/client/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { I18NextPipe } from 'angular-i18next';
import { DxAutocompleteComponent } from 'devextreme-angular';
import { LoadOptions } from 'devextreme/data';
import CustomStore from 'devextreme/data/custom_store';
import i18next from 'i18next';
import { ListEndCustomerLabelsFilterInput, Sim, UpdateSimMutation } from '../../../../graphql/graphql.generated';
import { GraphqlService } from '../../service/graphql.service';
import { ToastMessageBuilder, ToastService } from '../../service/toast.service';

@Component({
    selector: 'app-modal-bulk-change-sim-end-customer-label',
    templateUrl: './modal-bulk-change-sim-end-customer-label.component.html',
    styleUrl: './modal-bulk-change-sim-end-customer-label.component.scss'
})
export class ModalBulkChangeSimEndCustomerLabelComponent {

    @Input() public selectedSimCards?: Sim[];
    @ViewChild('endCustomerLabelInput') public input: DxAutocompleteComponent | undefined;
    public inProgress: boolean = false;
    public saveEnabled: boolean = true;
    public currentLabel: string = '';

    protected readonly store: CustomStore;
    protected loadingSuggestions: boolean = false;
    protected readonly maxItemCount = 20;
    protected changedList: Sim[] = [];


    @ViewChild('content') private content: TemplateRef<never> | undefined;
    private modal?: NgbModalRef;
    private graphQlService: GraphqlService = inject(GraphqlService);
    private toastService: ToastService = inject(ToastService);
    protected i18nextPipe: I18NextPipe = inject(I18NextPipe);
    private modalService: NgbModal = inject(NgbModal);
    private router: Router = inject(Router);
    private route: ActivatedRoute = inject(ActivatedRoute);

    public constructor() {
        this.store = new CustomStore({
            load: async (loadOptions: LoadOptions): Promise<string[]> => {
                this.loadingSuggestions = true;
                const filter: ListEndCustomerLabelsFilterInput = {
                    name: {
                        contains: loadOptions.searchValue
                    }
                };
                const response = await this.graphQlService.listEndCustomerLabels(filter,
                    this.maxItemCount, undefined);
                const result: string[] = [];
                if (response.data?.listEndCustomerLabels?.items) {
                    response.data.listEndCustomerLabels.items.forEach((item) => {
                        if (item) {
                            result.push(item.name);
                        }
                    });
                }
                this.loadingSuggestions = false;
                return result;
            }
        });

    }

    public open(): NgbModalRef {
        this.checkChangedSims();
        this.modal = this.modalService.open(this.content, { ariaLabelledBy: 'modalSimEndCustomerLabel' });
        this.modal.result.then((): void => {
            this.saveEnabled = true;
        });
        return this.modal;
    }


    public commitChange(): void {
        if (this.inProgress) {
            return;
        }
        this.inProgress = true;
        // collect the promises and wait with closing the dialog until we sent out all ...
        const promises: Promise<FetchResult<UpdateSimMutation>>[] = [];
        const successfulArray: string[] = [];
        // create an end customer label object to be saved
        const newEndCustomerLabel = {
            'name': this.currentLabel || ''
        };
        if (this.selectedSimCards) {
            this.selectedSimCards.forEach((simCard: Sim): void => {
                promises.push(this.graphQlService.updateSim(
                    simCard.iccid, undefined, undefined, undefined, undefined, undefined, undefined, newEndCustomerLabel));
            });

            // very important: promises are resolved in order. so we can access the original array for icc id mutation was called for:
            // https://stackoverflow.com/a/67274260
            Promise.allSettled(promises).then((results: PromiseSettledResult<FetchResult<UpdateSimMutation>>[]): void => {
                results.forEach((promiseResult: PromiseSettledResult<FetchResult<UpdateSimMutation>>, index: number): void => {
                    if (promiseResult.status === 'fulfilled') {
                        if (promiseResult.value.data?.updateSim.iccid) {
                            successfulArray.push(promiseResult.value.data?.updateSim.iccid);
                        }
                    } else {
                        if (this.selectedSimCards) {
                            const iccId = this.selectedSimCards[index].iccid;
                            const text = this.i18nextPipe.transform(
                                'bulkEndCustomerLabelChange.errorEndCustomerLabelChangeForIccId', {
                                    iccId: iccId,
                                    error: promiseResult.reason?.message
                                });
                            this.toastService.show(ToastMessageBuilder.error().text(text).build());
                        }
                    }
                });
                this.inProgress = false;
                if (successfulArray.length > 0) {
                    const text: string = this.i18nextPipe.transform('bulkEndCustomerLabelChange.successEndCustomerLabelChange',
                        { count: successfulArray.length });
                    this.toastService.show(ToastMessageBuilder.success().text(text).build());
                }
                this.modal?.close();
            });
        }
    }

    protected onKeyDown($event: KeyboardEvent): void {
        this.checkChangedSims();
        if ($event.key === 'Escape') {
            this.onClosed();
        } else if ($event.key === 'Enter') {
            this.checkChangedSims();
            $event.stopPropagation();
            this.commitChange();
        } else if ($event.code.startsWith('Arrow')) {
            // Allow cursor movement
            $event.stopPropagation();
        } else if ($event.metaKey && $event.code === 'KeyA') {
            // Select All
            $event.stopPropagation();
        }
    }

    protected getQueryParamFilter(params: Sim[]): { filter: string } {
        const filter = {
            'iccid': {
                'filterType': 'text',
                'type': 'in',
                'filter': params.map((value: Sim): string => value.iccid).join(',')
            }
        };
        return { filter: JSON.stringify(filter) };
    }

    protected checkChangedSims(): void {
        this.changedList = [];
        this.selectedSimCards?.forEach(((sim: Sim): void => {
            const isSimLabelNull: boolean = sim.end_customer_label === null;
            const isLabelEmptyString: boolean = this.currentLabel === '';
            const isLabelNull: boolean = this.currentLabel === null;
            const simLabelChanged: boolean = (this.currentLabel != sim.end_customer_label?.name);
            if (simLabelChanged) {
                if (!(isSimLabelNull && (isLabelEmptyString || isLabelNull))) this.changedList.push(sim);
            }
        }));
    }

    protected onClosed(): void {
        this.currentLabel = '';
        this.changedList = [];
        this.modal?.close('Cancel');
    }

    protected displaySimCount(): boolean {
        return this.changedList.length > 1;
    }

    protected onInput(): void {
        this.checkChangedSims();
    }

    protected readonly i18next = i18next;
    protected readonly String = String;

    protected generateLink(length: number): string {
        const queryParams: { filter: string } = this.getQueryParamFilter(this.changedList);
        const urlTree: UrlTree = this.router.createUrlTree([], {
            relativeTo: this.route, queryParams
        });
        const newUrl: string = this.router.serializeUrl(urlTree);
        return `<a href="${newUrl}" target="_blank">${this.i18nextPipe.transform('sim-cards', { count: length })}</a>`;
    }
}
