<div class="row">
  <div [hidden]="!showStatus" class="col-12 text-center">
    <i #popOverControl="ngbPopover" (focusout)="popOverControl.close()" [ngbPopover]="popContent"
       autoClose="outside" class="fa fa-circle" [ngClass]="inSession? 'text-online':'text-offline'" container="body"
       (click)="popOverControl.open()" data-cy="session-status" placement="bottom"
       triggers="mouseenter:mouseleave"></i>
  </div>
</div>

<ng-template #popContent data-cy="pop-up">
  <div class="d-flex flex-row">
    <div class="d-flex align-self-center">
      <i class="fa fa-circle text-online"></i>
    </div>
    <div class="ps-2">
      <div class="d-flex ">
        <span class="fw-bold flex-grow-1">{{ ('hoverPopUps.inSession.title.online'| i18next) }}</span>
      </div>
      <div>{{ ('hoverPopUps.inSession.content.online'| i18next) }}</div>
    </div>
  </div>
  <hr>
  <div class="d-flex flex-row">
    <div class="d-flex align-self-center">
      <i class="fa fa-circle text-offline"></i>
    </div>
    <div class="ps-2">
      <div class="fw-bold">{{ ('hoverPopUps.inSession.title.offline'| i18next) }}</div>
      <div>{{ ('hoverPopUps.inSession.content.offline'| i18next) }}</div>
    </div>
  </div>
</ng-template>
