import { Component, Input } from '@angular/core';

interface JsonDataInterface {
    json: {
        userEmail: string | null | undefined,
        blockedUntil: number
    };
}


@Component({
  selector: 'app-detail-cancel-location',
  templateUrl: './detail-cancel-location.component.html',
  styleUrl: './detail-cancel-location.component.scss'
})
export class DetailCancelLocationComponent {

    @Input({ required: true })
    public data!: JsonDataInterface;

}
