import { Injectable } from '@angular/core';
import staticAssetsList from '../../assets.json';

@Injectable({
    providedIn: 'root'
})
export class CacheBustingService {

    private readonly staticAssets: { [key: string]: string };

    public constructor() {
        this.staticAssets = staticAssetsList;
    }

    public getHashForStaticAsset(assetPath: string): string {
        const path: string = assetPath.split('#')[0];

        if (typeof this.staticAssets[path] === 'string') {
            return this.addParamsToUrl(assetPath, `c=${this.staticAssets[path]}`);
        }
        return assetPath;
    }

    private addParamsToUrl(givenUrl: string, urlParameters: string): string {

        if (typeof urlParameters !== 'string' || urlParameters.length === 0) {
            return givenUrl;
        }

        const urlSplitByHash: string[] = givenUrl.split('#');
        const hash: string = urlSplitByHash[1] || '';
        const params: string[] = urlParameters.split('&');
        let url: string = urlSplitByHash[0];

        if (url.indexOf('?') === -1) {
            url += '?';
        } else {
            url += '&';
        }

        url += params.map((paramItem: string): string => {
            const p: string[] = paramItem.split('=');

            return `${p[0]}=${window.encodeURIComponent(p[1])}`;
        })
            .join('&');

        url = url.slice(0, -1); // remove last &

        return hash ? `${url}#${hash}` : url;
    }
}
