import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { Auth } from '../service/auth/WSimAuth';

type HasPermissionLogicalOperation = 'AND' | 'OR';

@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: '[hasPermission]'
})
export class HasPermissionDirective {

    private permissions: string[] = [];
    private logicalOp: HasPermissionLogicalOperation = 'AND';
    private isHidden = true;

    public constructor(private templateRef: TemplateRef<never>, private viewContainer: ViewContainerRef) {
    }

    @Input()
    public set hasPermission(permissions: string[] | string) {
        if (Array.isArray(permissions)) {
            this.permissions = permissions;
        } else {
            this.permissions = [permissions];
        }
        this.updateView();
    }

    @Input()
    public set hasPermissionOp(logicalOperation: HasPermissionLogicalOperation) {
        this.logicalOp = logicalOperation;
        this.updateView();
    }

    private updateView() {
        if (this.checkPermission()) {
            if (this.isHidden) {
                this.viewContainer.createEmbeddedView(this.templateRef);
                this.isHidden = false;
            }
        } else {
            this.isHidden = true;
            this.viewContainer.clear();
        }
    }

    private checkPermission() {
        let hasPermission = false;

        const userPermissions = Auth.getcurrentUserPermissionsList();

        if (userPermissions) {
            for (const checkPermission of this.permissions) {
                const permissionFound = userPermissions.has(checkPermission);

                if (permissionFound) {
                    hasPermission = true;

                    if (this.logicalOp === 'OR') {
                        break;
                    }
                } else {
                    hasPermission = false;
                    if (this.logicalOp === 'AND') {
                        break;
                    }
                }
            }
        }
        return hasPermission;
    }
}
