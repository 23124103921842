import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { Permission } from '../../../service/auth/WSimAuth';
import { StatusConstants } from '../../constants';

@Component({
    selector: 'app-voice-limit',
    templateUrl: './voice-limit.component.html',
    styleUrl: './voice-limit.component.scss',
    animations: [
        trigger('fade', [
            state('visible', style({ opacity: 1 })),
            state('hidden', style({ opacity: 0 })),
            transition('visible <=> hidden', animate('600ms'))
        ])
    ]

})
export class VoiceLimitComponent implements ICellRendererAngularComp {

    protected moVoiceLimit?: number;
    protected hidden: boolean = true;
    protected value?: string;
    protected showEdit: boolean = false;
    protected voiceLimitExceeded: boolean = false;
    protected iccId?: string;

    private params?: ICellRendererParams;

    public agInit(params: ICellRendererParams): void {
        this.showEdit = !!(params.colDef?.editable);
        this.params = params;
        this.updateData(params);
    }

    public refresh(params: ICellRendererParams): boolean {
        this.updateData(params);
        return true;
    }

    private updateData(params: ICellRendererParams): void {
        if (params.data) {
            this.moVoiceLimit = params.data.monthly_voice_mo_limit / 60;
            this.voiceLimitExceeded = params.data.statusid === StatusConstants.VOICE_LIMIT_EXCEEDED;
            this.iccId = params.data.iccid;

        }
    }

    public startEditMode() {
        if (this.params?.column && this.params?.node.rowIndex != undefined) {
            this.params.api.startEditingCell({ rowIndex: this.params.node.rowIndex, colKey: this.params.column });
        }
    }

    protected readonly StatusConstants = StatusConstants;
    protected readonly Permission = Permission;
}
