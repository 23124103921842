import { NgOptimizedImage } from '@angular/common';
import { HttpClient, provideHttpClient } from '@angular/common/http';
import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DigitOnlyModule } from '@uiowa/digit-only';
import { AgGridModule } from 'ag-grid-angular';
import { I18NEXT_SERVICE, I18NextModule, ITranslationService } from 'angular-i18next';
import { DevExtremeModule, DxDataGridModule, DxDateBoxModule, DxPopoverModule, DxRadioGroupModule } from 'devextreme-angular';
import config from 'devextreme/core/config';
import { loadMessages, locale } from 'devextreme/localization';
import * as i18next from 'i18next';
import I18nextBrowserLanguageDetector from 'i18next-browser-languagedetector';
import i18nHttpBackend from 'i18next-http-backend';
import LocizeBackend from 'i18next-locize-backend';
import LocizeLastusedPlugin from 'locize-lastused';
import { ClipboardModule } from 'ngx-clipboard';
import { DndModule } from 'ngx-drag-drop';
import { devExtremeLicenseKey } from '../devextreme-license';
import { environment } from '../environments/environment';
import { localizeApikey, localizeProjectId } from '../localize-apikey';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ChangePasswordComponent } from './auth/change-password/change-password.component';
import { ForgotPasswordComponent } from './auth/forgot-password/forgot-password.component';
import { SetPasswordComponent } from './auth/forgot-password/set-password/set-password.component';
import { InitialPasswordChangeComponent } from './auth/sign-in/initial-password-change/initial-password-change.component';
import { LoginComponent } from './auth/sign-in/login/login.component';
import { NewPassComponent } from './auth/sign-in/new-pass/new-pass.component';
import { SignInComponent } from './auth/sign-in/sign-in.component';
import { ModalBulkChangeSimCustomField1Component } from './dialogs/modal-bulk-change-sim-custom-field1/modal-bulk-change-sim-custom-field1.component';
import { ModalBulkChangeSimDataLimitComponent } from './dialogs/modal-bulk-change-sim-data-limit/modal-bulk-change-sim-data-limit.component';
import { ModalBulkChangeSimSmsLimitComponent } from './dialogs/modal-bulk-change-sim-sms-limit/modal-bulk-change-sim-sms-limit.component';
import { ModalBulkChangeSimStatusComponent } from './dialogs/modal-bulk-change-sim-status/modal-bulk-change-sim-status.component';
import { ModalBulkChangeSimVoiceLimitComponent } from './dialogs/modal-bulk-change-sim-voice-limit/modal-bulk-change-sim-voice-limit.component';
import { ModalUpdateUserComponent } from './dialogs/modal-update-user/modal-update-user.component';
import { AutofocusDirective } from './directive/autofocus.directive';
import { HasPermissionDirective } from './directive/has-permission.directive';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { ByteFormatPipe } from './pipes/byte-format.pipe';
import { CountryCodeToFlagPipe } from './pipes/country-code-to-flag.pipe';
import { CountryCodeToNamePipe } from './pipes/country-code-to-name.pipe';
import { DurationFormatPipe } from './pipes/duration-format.pipe';
import { FileHashPipe } from './pipes/file-hash.pipe';
import { MsisdnFormatPipe } from './pipes/msisdn-format.pipe';
import { CacheBustingService } from './service/cache-busting.service';
import { NotificationsComponent } from './settings/notifications/notifications.component';
import { SettingsComponent } from './settings/settings.component';
import { UserManagementComponent } from './settings/user-management/user-management.component';
import { CustomFieldComponent } from './shared/ag-cell-editor/custom-field/custom-field.component';
import { DataVolumeEditComponent } from './shared/ag-cell-editor/data-volume-edit/data-volume-edit.component';
import { SendSmsComponent } from './shared/ag-cell-editor/send-sms/send-sms.component';
import { SmsLimitEditComponent } from './shared/ag-cell-editor/sms-limit-edit/sms-limit-edit.component';
import { DataVolumeLimitComponent } from './shared/ag-cell-renderer/data-volume-limit/data-volume-limit.component';
import { InSessionComponent } from './shared/ag-cell-renderer/in-session/in-session.component';
import { MsisdnComponent } from './shared/ag-cell-renderer/msisdn/msisdn.component';
import { SimStatusComponent } from './shared/ag-cell-renderer/sim-status/sim-status.component';
import { SmsAmountComponent } from './shared/ag-cell-renderer/sms-amount/sms-amount.component';
import { SmsLimitComponent } from './shared/ag-cell-renderer/sms-limit/sms-limit.component';
import { TextContainerWithCopyToClipboardComponent } from './shared/ag-cell-renderer/text-container-with-copy-to-clipboard/text-container-with-copy-to-clipboard.component';
import { VoiceLimitComponent } from './shared/ag-cell-renderer/voice-limit/voice-limit.component';
import { StatusFilterComponent } from './shared/ag-filter/status-filter/status-filter.component';
import { CheckboxClearSelectorComponent } from './shared/ag-headers/checkbox-clear-selector/checkbox-clear-selector.component';
import { CopyToClipboardComponent } from './shared/copy-to-clipboard/copy-to-clipboard.component';
import { CancelLocationPopoverComponent } from './shared/dx-popovers/cancel-location-popover/cancel-location-popover.component';
import { StatusPopoverComponent } from './shared/dx-popovers/status-popover/status-popover.component';
import { PasswordStrengthComponent } from './shared/password-strength/password-strength.component';
import { ToastsContainerComponent } from './shared/toasts-container/toasts-container.component';
import { BulkActionsComponent } from './sim-list/bulk-actions/bulk-actions.component';
import { SimDetailCancelLocationComponent } from './sim-list/sim-detail/sim-detail-cancel-location/sim-detail-cancel-location.component';
import { SimDetailDataUsageComponent } from './sim-list/sim-detail/sim-detail-data-usage/sim-detail-data-usage.component';
import { DetailsHeaderComponent } from './sim-list/sim-detail/sim-detail-event-table-devex/custom-cell-header/details-header/details-header.component';
import { TimestampHeaderComponent } from './sim-list/sim-detail/sim-detail-event-table-devex/custom-cell-header/timestamp-header/timestamp-header.component';
import { DetailCancelLocationComponent } from './sim-list/sim-detail/sim-detail-event-table-devex/detail-cancel-location/detail-cancel-location.component';
import { DetailConsumptionComponent } from './sim-list/sim-detail/sim-detail-event-table-devex/detail-consumption/detail-consumption.component';
import { DetailDefaultComponent } from './sim-list/sim-detail/sim-detail-event-table-devex/detail-default/detail-default.component';
import { DetailLifecycleComponent } from './sim-list/sim-detail/sim-detail-event-table-devex/detail-lifecycle/detail-lifecycle.component';
import { DetailPresenceComponent } from './sim-list/sim-detail/sim-detail-event-table-devex/detail-presence/detail-presence.component';
import { DetailUsageNotificationComponent } from './sim-list/sim-detail/sim-detail-event-table-devex/detail-usage-notification/detail-usage-notification.component';
import { SimDetailEventTableDevexComponent } from './sim-list/sim-detail/sim-detail-event-table-devex/sim-detail-event-table-devex.component';
import { SimDetailStatusComponent } from './sim-list/sim-detail/sim-detail-status/sim-detail-status.component';
import { SimDetailComponent } from './sim-list/sim-detail/sim-detail.component';
import { SimListComponent } from './sim-list/sim-list.component';
import { TableConfigComponent } from './sim-list/table-config/table-config.component';
import { TableQuicksearchComponent } from './sim-list/table-quicksearch/table-quicksearch.component';
import { StatisticComponent } from './statistic/statistic.component';
import { DefaultPopoverComponent } from './shared/dx-popovers/default-popover/default-popover.component';
import { ModalCreateUserComponent } from './dialogs/modal-create-user/modal-create-user.component';
import { DropdownButtonComponent } from './shared/dropdown-button/dropdown-button.component';
import { ModalResendUserInviteComponent } from './dialogs/modal-resend-user-invite/modal-resend-user-invite.component';
import { ModalDeleteUserComponent } from './dialogs/modal-delete-user/modal-delete-user.component';
import { locizePlugin } from 'locize';
import { VoiceLimitEditComponent } from './shared/ag-cell-editor/voice-limit-edit/voice-limit-edit.component';
import { EndCustomerLabelEditComponent } from './shared/ag-cell-editor/end-customer-label-edit/end-customer-label-edit.component';
import { EndCustomerLabelComponent } from './shared/ag-cell-renderer/end-customer-label/end-customer-label.component';
import { ModalBulkChangeSimEndCustomerLabelComponent } from './dialogs/modal-bulk-change-sim-end-customer-label/modal-bulk-change-sim-end-customer-label.component';

export function appInit(i18next: ITranslationService, http: HttpClient, cacheBusting: CacheBustingService) {
    return () => {
        const i18nConfig: i18next.InitOptions = {
            supportedLngs: environment.supportedLanguages,
            fallbackLng: 'en',
            debug: !environment.production,
            returnEmptyString: false,
            ns: [
                'default',
                'formats',
                'grid'
            ],
            defaultNS: 'default',
            saveMissing: environment.localDev,
            saveMissingPlurals: environment.localDev,
            detection: {
                lookupCookie: 'lng',
                lookupLocalStorage: 'lng',
                lookupSessionStorage: 'lng',
                caches: ['localStorage']
            }
        };

        const i18n = i18next.use(I18nextBrowserLanguageDetector);
        if (environment.localDev && localizeProjectId && localizeProjectId) {
            i18n.use(LocizeBackend)
                .use(LocizeLastusedPlugin)
                .use(locizePlugin);
            i18nConfig.backend = {
                projectId: localizeProjectId,
                apiKey: localizeApikey,
                version: 'latest',
                private: false,
                referenceLng: 'en'
            };
            i18nConfig.locizeLastUsed = {
                projectId: localizeProjectId,
                apiKey: localizeApikey,
                version: 'latest',
                referenceLng: 'en',
                allowedHosts: ['localhost'],
                debounceSubmit: 30000
            };
        } else {
            i18n.use(i18nHttpBackend);
            i18nConfig.backend = {
                loadPath: (languages: string, namespaces: string): string => {
                    const url = `assets/locales/${languages}/${namespaces}.json`;
                    const cacheBustedUrl = cacheBusting.getHashForStaticAsset(url);
                    return cacheBustedUrl || url;
                }
            };
        }
        const promise = i18n.init(i18nConfig);

        promise.then(() => {
            // Add custom formatter
            i18next.services.formatter?.add('cap', (value) => {
                return value.charAt(0).toUpperCase() + value.slice(1);
            });
            i18next.events.languageChanged.subscribe((lng) => {
                // setting locale for DevExtreme
                if (lng) {
                    locale(lng);
                    const url = `assets/devex/${lng}.json`;
                    const cacheBustedUrl = cacheBusting.getHashForStaticAsset(url);
                    http.get(cacheBustedUrl || url, { responseType: 'json' }).subscribe((data) => {
                        loadMessages(data);
                    });
                }
            });
        });
        return promise;
    };
}

export function localeIdFactory(i18next: ITranslationService) {
    return i18next.language;
}

export const I18N_PROVIDERS = [
    {
        provide: APP_INITIALIZER,
        useFactory: appInit,
        deps: [I18NEXT_SERVICE, HttpClient, CacheBustingService],
        multi: true
    },
    {
        provide: LOCALE_ID,
        deps: [I18NEXT_SERVICE],
        useFactory: localeIdFactory
    }];

declare global {
    interface Window {
        Cypress: boolean;
    }
}

// DevExtreme Config
config({ licenseKey: devExtremeLicenseKey });

@NgModule({
    declarations: [
        AppComponent,
        SignInComponent,
        SimListComponent,
        NewPassComponent,
        AutofocusDirective,
        HasPermissionDirective,
        LoginComponent,
        InitialPasswordChangeComponent,
        StatusFilterComponent,
        SimStatusComponent,
        MsisdnComponent,
        MsisdnFormatPipe,
        InSessionComponent,
        DataVolumeLimitComponent,
        ByteFormatPipe,
        DurationFormatPipe,
        CustomFieldComponent,
        SendSmsComponent,
        SmsAmountComponent,
        DataVolumeEditComponent,
        ChangePasswordComponent,
        ModalBulkChangeSimStatusComponent,
        ToastsContainerComponent,
        ModalBulkChangeSimCustomField1Component,
        ModalBulkChangeSimDataLimitComponent,
        ModalBulkChangeSimVoiceLimitComponent,
        SettingsComponent,
        NotificationsComponent,
        TableConfigComponent,
        CheckboxClearSelectorComponent,
        TextContainerWithCopyToClipboardComponent,
        StatisticComponent,
        BulkActionsComponent,
        TableQuicksearchComponent,
        SimDetailComponent,
        PageNotFoundComponent,
        ForgotPasswordComponent,
        SetPasswordComponent,
        PasswordStrengthComponent,
        SimDetailEventTableDevexComponent,
        SimDetailStatusComponent,
        CopyToClipboardComponent,
        DetailPresenceComponent,
        DetailLifecycleComponent,
        DetailConsumptionComponent,
        DetailDefaultComponent,
        DetailUsageNotificationComponent,
        TimestampHeaderComponent,
        DetailsHeaderComponent,
        SimDetailDataUsageComponent,
        SmsLimitComponent,
        StatusPopoverComponent,
        SmsLimitEditComponent,
        ModalBulkChangeSimSmsLimitComponent,
        DetailCancelLocationComponent,
        CancelLocationPopoverComponent,
        SimDetailCancelLocationComponent,
        UserManagementComponent,
        DefaultPopoverComponent,
        ModalCreateUserComponent,
        DropdownButtonComponent,
        ModalUpdateUserComponent,
        ModalResendUserInviteComponent,
        ModalDeleteUserComponent,
        VoiceLimitComponent,
        VoiceLimitEditComponent,
        EndCustomerLabelEditComponent,
        EndCustomerLabelComponent,
        ModalBulkChangeSimEndCustomerLabelComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        I18NextModule.forRoot(),
        BrowserModule,
        AppRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        NgOptimizedImage,
        AgGridModule,
        ClipboardModule,
        DigitOnlyModule,
        NgbModule,
        DndModule,
        DxDataGridModule,
        CountryCodeToFlagPipe,
        CountryCodeToNamePipe,
        DxRadioGroupModule,
        DxDateBoxModule,
        DxPopoverModule,
        DevExtremeModule,
        FileHashPipe
    ],
    providers: [
        I18N_PROVIDERS,
        ByteFormatPipe,
        DurationFormatPipe,
        MsisdnFormatPipe,
        provideHttpClient()
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
