<dx-popover [target]="'#' + componentId" showEvent="mouseenter" hideEvent="mouseleave"
            [showTitle]="true" titleTemplate="title" [width]="300">

  <div *dxTemplate="let data of 'title'" class="sim-limit-exceeded-title text-center">
    <i class="fa-solid fa-triangle-exclamation"></i> {{ 'status.' + statusId | i18next }}
  </div>

  <dxo-position *ngIf="position" [my]="position" at="right" [of]="'#' + componentId" offset="0 0"></dxo-position>

  <div *dxTemplate="let data = model; of: 'content'">
    {{ 'statusDescription.' + statusId | i18next }}
  </div>
</dx-popover>

