<ng-template #content let-modal>
    <div class="modal-header" data-cy="modal-bulk-change-sim-end-customer-label-dialog">
      <h1 class="modal-title fs-5"
          id="bulkEndCustomerLabelChange">{{ 'bulkEndCustomerLabelChange.title' | i18next }}</h1>
      <button type="button" class="btn-close" aria-label="Close" [disabled]="inProgress" (click)="modal.close('X')">
      </button>
    </div>
    <div class="modal-body">
      <div class="col fw-bold">
        {{ 'table.header.end_customer_label' | i18next }}
      </div>
      <div class="pt-2">
        <div class="col">
          <div class="d-inline-flex align-items-center p-1 w-100">
            <label class="visually-hidden col-form-label col-form-label-sm"
                   for="formInputGroupEndCustomerLabel">  {{ 'table.header.end_customer_label' | i18next }}</label>
            <div class="input-group input-group-sm align-items-center">
              <dx-autocomplete
                data-cy="modal-bulk-change-sim-end-customer-label-input"
                class="form-control form-control-sm text-input p-0 m-0"
                #endCustomerLabelInput
                [(value)]="currentLabel"
                [minSearchLength]="3"
                [searchTimeout]="300"
                [dataSource]="store"
                maxLength="255"
                [height]="10"
                (keydown)="onKeyDown($event)"
                [showClearButton]="true"
                (onInput)="onInput()"
                (onChange)="checkChangedSims()"
                (onItemClick)="checkChangedSims()"
                id="formInputGroupEndCustomerLabel"
              ></dx-autocomplete>
              <i *ngIf="loadingSuggestions" class="fa-solid fa-solid fa-circle-notch fa-spin loading-container-icon"></i>
            </div>
          </div>
        </div>
      </div>
      <div class="row pt-1">
        <div class="col">
          <div class="pb-1">
            <div class="form-text">{{ 'bulkEndCustomerLabelChange.hint' | i18next }}</div>
          </div>
          <div class="alert alert-light pt-3 mt-1 d-flex flex-row" *ngIf="displaySimCount()"
               data-cy="modal-bulk-change-sim-end-customer-label-alert" >
            <i class="fa-solid fa-circle-info p-1"></i>
            <div [innerHTML]="this.i18nextPipe.transform('bulkEndCustomerLabelChange.changedSims',
             {link: generateLink(changedList.length), interpolation: { escapeValue: false } })">
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer d-flex float-end gap-3">
        <button type="button" class="btn btn-outline-secondary" [disabled]="inProgress" (click)="onClosed()"
                data-cy="modal-bulk-change-sim-end-customer-label-cancel-button">
          {{ 'shared.btn.cancel' | i18next }}
        </button>
        <button type="button" [disabled]="!saveEnabled || inProgress" (click)="commitChange()"
                class="btn btn-secondary" data-cy="modal-bulk-change-sim-end-customer-label-save-button">
          {{ 'apply' | i18next }}
          <span *ngIf="inProgress" class="spinner-border spinner-border-sm" aria-hidden="true"></span>
        </button>
    </div>
</ng-template>
