<ng-template #content let-modal>
  <!-- header -->
  <div class="modal-header" data-cy="modal-bulk-change-imei-lock-dialog">
    <h1 class="modal-title fs-5"
        id="bulkImeiLock">{{ 'bulkImeiLock.title' | i18next }}</h1>
    <button type="button" class="btn-close" aria-label="Close" [disabled]="inProgress" (click)="modal.close('X')">
    </button>
  </div>
  <!-- body -->
  <div class="modal-body">
    <div class="col">
      <div *ngIf="selectedSimCards" [innerHTML]="this.i18nextPipe.transform('bulkImeiLock.question',
           {link: generateLink(selectedSimCards.length), interpolation: { escapeValue: false }, count: selectedSimCards.length })">
      </div>
    </div>
    <div class="row pt-1">
      <div class="col fw-bold">
        {{ 'imeiLock' | i18next }}:
      </div>
    </div>
    <div class="row pt-1">
      <div class="col">
        <div class="form-check form-switch">
          <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault" [(ngModel)]="imeiLockActive"
                 data-cy="bulk-imei-lock-active-slider">
        </div>
      </div>
    </div>
  </div>
  <!-- footer -->
  <div class="modal-footer d-flex float-end gap-3">
    <button type="button" class="btn btn-outline-secondary" [disabled]="inProgress" (click)="onClosed()"
            data-cy="modal-bulk-change-imei-lock-cancel-button">
      {{ 'shared.btn.cancel' | i18next }}
    </button>
    <button type="button" [disabled]="!saveEnabled || inProgress" (click)="commitChange()"
            class="btn btn-secondary" data-cy="modal-bulk-change-imei-lock-save-button">
      {{ 'apply' | i18next }}
      <span *ngIf="inProgress" class="spinner-border spinner-border-sm" aria-hidden="true"></span>
    </button>
  </div>
</ng-template>
