<nav class="navbar fixed-top navbar-expand-md bg-light">
  <div class="container-fluid">
    <a class="navbar-brand" routerLink="/">
      <img attr.src="{{ 'assets/whereverSIM-Logo.svg' | fileHash }}" alt="wherever SIM" height="40">
    </a>

    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav me-auto">
        <li *ngIf="isLoggedIn" class="nav-item order-0">
          <a class="nav-link" aria-current="page" routerLink="/sim-list" routerLinkActive="active">
            <i class="fa-solid fa-sim-card"></i> {{ 'sim cards' | i18next }}</a>
        </li>
      </ul>
      <ul class="navbar-nav ms-auto">
        <li *ngIf="!isLoggedIn" class="nav-item dropdown">
          <a class="nav-link dropdown-bs-toggle text-secondary" role="button" data-bs-toggle="dropdown"
             aria-expanded="false">
            {{ 'lang.' + i18next.language | i18next }}
            <i class="fa-solid fa-chevron-down"></i>
          </a>
          <ul class="dropdown-menu dropdown-menu-end">
            <ng-container *ngTemplateOutlet="langlist"></ng-container>
          </ul>
        </li>

        <span *ngIf="isLoggedIn && (customerData | async)?.testCustomer"
              class="navbar-text fw-light">{{ 'test account' | i18next }}</span>

        <li *ngIf="isLoggedIn" class="nav-item dropdown" data-cy="dropdown-main-menu">
          <a class="nav-link dropdown-bs-toggle text-secondary" role="button" data-bs-toggle="dropdown"
             aria-expanded="false">
            {{ userEmail }}
            <i class="fa-solid fa-chevron-down"></i>
          </a>
          <ul class="dropdown-menu dropdown-menu-end">
            <li class="dropdown-header">{{ 'language' | i18next }}</li>
            <ng-container *ngTemplateOutlet="langlist"></ng-container>
            <li class="dropdown-divider"></li>
            <li><a class="dropdown-item" data-toggle="collapse" data-target=".navbar-collapse.show"
                   data-cy="dropdown-main-item-change-password" routerLink="/change-pass">
              {{ 'changePassword.menuItem' | i18next }}
            </a></li>
            <li *hasPermission="Permission.CUSTOMER_WRITE_USAGENOTIFICATIONS">
              <a class="dropdown-item" routerLink="/settings" data-cy="dropdown-main-item-settings">
                <i class="fa-solid fa-gear"></i> {{ 'button.settings' | i18next }}
              </a>
            </li>
            <li class="dropdown-divider"></li>
            <li><a (click)="logout()" routerLink class="dropdown-item" data-cy="dropdown-main-item-logout">
              <i class="fa-solid fa-right-from-bracket"></i> {{ 'button.logout' | i18next }}
            </a></li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
</nav>

<router-outlet></router-outlet>

<app-toasts aria-live="polite" aria-atomic="true"></app-toasts>
<ng-template #langlist>
  <li *ngFor="let lang of LANGS">
    <button (click)="switchLang(lang)" [class]="(i18next.language === lang)?'active':''" class="dropdown-item"
            data-toggle="collapse" data-cy="dropdown-main-item-change-language" data-target=".navbar-collapse.show">
      {{ 'lang.' + lang | i18next }}
    </button>
  </li>
</ng-template>
