import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { HorizontalAlignment, PositionAlignment, VerticalAlignment } from 'devextreme/common';

@Component({
    selector: 'app-cancel-location-popover',
    templateUrl: './cancel-location-popover.component.html',
    styleUrl: './cancel-location-popover.component.scss'
})
export class CancelLocationPopoverComponent implements OnChanges {

    @Input({ required: true })
    public isRequestAvailable: boolean | undefined;

    @Input({ required: true })
    public blockedUntil: number | undefined;

    @Input({ required: true })
    public iccid: string | undefined;

    @Input()
    public position: PositionAlignment | { x?: HorizontalAlignment, y?: VerticalAlignment } | undefined;

    protected timer: NodeJS.Timeout | undefined;
    protected leftover: number | undefined;

    private updateTimer(): void {
        if (!this.blockedUntil) return;
        if (this.blockedUntil > Date.now()) {
            this.leftover = this.blockedUntil - Date.now();
        } else {
            this.resetTimer();
        }
    }

    private resetTimer(): void {
        clearInterval(this.timer);
    }

    private startTimer(): void {
        if (this.timer) this.resetTimer();
        if (!this.isRequestAvailable) {
            this.updateTimer();
            this.timer = setInterval((): void => {
                this.updateTimer();
            }, 1000);
        }
    }


    public ngOnChanges(changes: SimpleChanges): void {
        if (!changes) return;
        this.resetTimer();
        if (changes['blockedUntil'] && changes['blockedUntil'].currentValue !== changes['blockedUntil'].previousValue) this.startTimer();
    }
}
