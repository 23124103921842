import { Component, Input } from '@angular/core';
import { Sim } from '../../../../../graphql/graphql.generated';
import { Permission } from '../../../service/auth/WSimAuth';

@Component({
    selector: 'app-sim-detail-status',
    templateUrl: './sim-detail-status.component.html',
    styleUrl: './sim-detail-status.component.scss'
})
export class SimDetailStatusComponent {

    @Input()
    public sim?: Sim;

    protected constructor() {
    }

    protected readonly Permission = Permission;
}
