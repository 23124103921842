<ng-template #content let-modal>
  <div class="modal-header" data-cy="modal-bulk-change-sim-sms-limit-dialog">
    <h1 class="modal-title fs-5"
        id="bulkChangeSimSmsLimitLabel">{{ 'bulkSmsLimitChange.title' | i18next }}</h1>
    <button type="button" class="btn-close" aria-label="Close" [disabled]="inProgress" (click)="modal.close('X')">
    </button>
  </div>
  <div class="modal-body">
    <div class="mb-3 d-grid gap-3">

      <div>
        <label *ngIf="showMt" class="fw-bold" for="inlineFormInputGroupLimit">
          {{ 'bulkSmsLimitChange.mt.headline' | i18next }}
        </label>
        <div *ngIf="showMt" class="input-group">
          <input (keydown)="onKeyDown($event)" [(ngModel)]="unsavedMtLimit" (ngModelChange)="evaluateTargets()"
                 autofocus class="form-control number-input" id="inlineFormInputGroupLimit" required digitOnly
                 data-cy="modal-bulk-change-sim-sms-limit-mt-input" type="text">
        </div>
      </div>

      <div>
        <label class="fw-bold" for="inlineFormInputGroupLimit2">
          {{ 'bulkSmsLimitChange.mo.headline' | i18next }}
        </label>
        <input (keydown)="onKeyDown($event)" [(ngModel)]="unsavedMoLimit" (ngModelChange)="evaluateTargets()"
               class="form-control number-input" id="inlineFormInputGroupLimit2" required digitOnly
               data-cy="modal-bulk-change-sim-sms-limit-mo-input" type="text">
        <div class="form-text">{{ 'bulkSmsLimitChange.hint' | i18next }}</div>
      </div>
    </div>
    <div class="alert alert-light" *ngIf="enabled>0"
         data-cy="modal-bulk-change-sim-sms-limit-alert-sims-enabled">
      <a [routerLink]="[]" [queryParams]="getQueryParamFilter(enabledList)" target="_blank">
        {{ 'bulkSmsLimitChange.enabled' | i18next: {count: enabled} }}
      </a>
    </div>
    <div class="alert alert-light" *ngIf="disabled>0"
         data-cy="modal-bulk-change-sim-sms-limit-alert-sims-disabled">
      <a [routerLink]="[]" [queryParams]="getQueryParamFilter(disabledList)" target="_blank">
        {{ 'bulkSmsLimitChange.disabled' | i18next: {count: disabled} }}
      </a>
    </div>

  </div>
  <div class="modal-footer">
    <div class="col float-start">
      <button type="button" class="btn btn-secondary" [disabled]="inProgress" (click)="modal.close('Cancel')"
              data-cy="modal-bulk-change-sim-sms-limit-cancel-button">
        {{ 'shared.btn.cancel' | i18next }}
      </button>
    </div>
    <div class="col float-end text-end" [hidden]="saveEnabled">
      <button type="button" (click)="commitChange()" [disabled]="inProgress"
              class="btn btn-primary" data-cy="modal-bulk-change-sim-sms-limit-save-button">
        {{ 'apply' | i18next }}
        <span *ngIf="inProgress" class="spinner-border spinner-border-sm" aria-hidden="true"></span>
      </button>
    </div>
  </div>
</ng-template>
