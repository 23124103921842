<form (ngSubmit)="onChangePassword()" [formGroup]="form">
  <h2 class="text-center py-4">{{ 'login.title_change_password_required' | i18next }}</h2>

  <div class="mt-4">
    {{ 'login.newPasswordInfo' | i18next }}
  </div>

  <div class="form-group mt-4">
    <label for="password">{{ 'login.new_password' | i18next }}</label>
    <input (input)="onNewPasswordChanged()" class="form-control password mt-1" formControlName="newPassword"
           id="password" name="newPassword" [placeholder]="'password' | i18next" required type="password"
           [max]="environment.passwordRequirements.maxLength" autocomplete="new-password" data-cy="newPasswordInput"/>
  </div>

  <div class="mt-2">
    <app-password-strength></app-password-strength>
  </div>
  <div class="d-grid gap-2 col-12 mx-auto">
    <button [disabled]="form.invalid || newPassDisabled || !passwordGuidelinesMatch"
            class="btn btn-primary btn-customized mt-4"
            type="submit" data-cy="submitBtn">
      {{ 'button.change_password' | i18next }}
    </button>
    <span class="text-center py-3">
            <a (click)="returnToLogin()" routerLink class="link-secondary" data-cy="link-back-to-login" >
              {{ 'button.back_to_login' | i18next }}
            </a>
    </span>
  </div>
</form>
