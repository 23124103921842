import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { AuthService } from './auth.service';
import { Auth } from './WSimAuth';

export const permissionGuard: CanActivateFn = (route) => {
    const authService = inject(AuthService);

    if (!authService.isLoggedIn())
        return false;

    const requiredPermission = route.data['permission'];
    return Auth.getcurrentUserPermissionsList().has(requiredPermission);
};
