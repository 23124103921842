<div class="d-flex flex-row align-items-stretch gap-4 placeholder-glow text-secondary">
  <div class="flex-column fw-bold align-self-center">
    {{ 'statistic.title' | i18next }}
  </div>
  <div class="flex-column">
    <div class="fw-bold">{{ 'sim cards' | i18next }}</div>
    <div class="fs-2">
      <ng-container *ngIf="!isFirstLoading">
        {{ 'formats:number' | i18next: {val: totalSimCards} }}
      </ng-container>
      <span *ngIf="isFirstLoading" class="placeholder col-12"></span>
    </div>
  </div>
  <div class="flex-column">
    <div class="fw-bold">{{ 'statistic.status.header' | i18next }}</div>
    <div>{{ 'status.2' | i18next }}</div>
    <div class="fs-5">
      <ng-container *ngIf="!isFirstLoading">
        {{ 'formats:number' | i18next: {val: simActiveAmount} }}
      </ng-container>
      <span *ngIf="isFirstLoading" class="placeholder col-12"></span>
    </div>
  </div>
  <div class="flex-column">
    <div class="fw-bold">{{ 'statistic.dataConsumption.header' | i18next }}</div>
    <div class="d-flex flex-row gap-4">
      <span>
        <div>{{ 'statistic.dataConsumption.lastMonth' | i18next }}</div>
        <div class="fs-5">
          <ng-container *ngIf="!isFirstLoading">
            {{ lastMonthDataConsumption | byteFormat }}
          </ng-container>
          <span *ngIf="isFirstLoading" class="placeholder col-12"></span>
        </div>
      </span>
      <span>
        <div>{{ 'statistic.currentMonth' | i18next }}</div>
        <div class="fs-5">
          <ng-container *ngIf="!isFirstLoading">
            {{ currentDataConsumption | byteFormat }}
          </ng-container>
          <span *ngIf="isFirstLoading" class="placeholder col-12"></span>
        </div>
      </span>
    </div>
  </div>
</div>
