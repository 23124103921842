<div class="d-flex flex-row text-break">
  <div class="ps-2"><i class="fa-solid fa-envelope-circle-check color-icon"></i></div>
  <!-- data has other format than SMS! -->
  <div class="ps-1" *ngIf="data.json.usageTypeId && data.json.usageTypeId === USAGE_TYPE_DATA">
    {{ 'usageNotification.text.' + data.json.usageTypeId | i18next: {limit: data.json.limit | byteFormat, reached: data.json.amount | byteFormat, percent: data.json.notificationType, emailList: data.json.emails.join().replaceAll(',',', ')} }}
  </div>
  <!-- sms output -->
  <div class="ps-1" *ngIf="data.json.usageTypeId && data.json.usageTypeId === USAGE_TYPE_SMS">
    {{ 'usageNotification.text.' + data.json.usageTypeId | i18next: {limit: data.json.limit, reached: data.json.amount, percent: data.json.notificationType, emailList: data.json.emails.join().replaceAll(',',', ')} }}
  </div>
  <!-- voice output -->
  <div class="ps-1" *ngIf="data.json.usageTypeId && data.json.usageTypeId === USAGE_TYPE_VOICE">
    {{ 'usageNotification.text.' + data.json.usageTypeId | i18next: {limit: data.json.limit, reached: data.json.amount / 60, percent: data.json.notificationType, emailList: data.json.emails.join().replaceAll(',',', ')} }}
  </div>
  <!-- old: safely remove the following div after 2025-01-31! This is only here to show older entries (where no usageTypeId is present in event!) -->
  <div class="ps-1" *ngIf="!data.json.usageTypeId">
    {{ 'usageNotification.text.1' | i18next: {limit: data.json.dataLimit | byteFormat, reached: data.json.dataAmount | byteFormat, percent: data.json.notificationType, emailList: data.json.emails.join().replaceAll(',',', ')} }}
  </div>
</div>
