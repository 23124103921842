import { Component, OnInit } from '@angular/core';
import { ApolloQueryResult } from '@apollo/client/core';
import { GetStatisticsQuery } from '../../../graphql/graphql.generated';
import { GraphqlService } from '../service/graphql.service';

@Component({
    selector: 'app-statistic',
    templateUrl: './statistic.component.html',
    styleUrl: './statistic.component.scss'
})
export class StatisticComponent implements OnInit {

    protected totalSimCards: number = 0;
    protected simActiveAmount: number = 0;
    protected currentDataConsumption: number = 0;
    protected lastMonthDataConsumption: number = 0;

    protected isFirstLoading: boolean = true;

    public constructor(private graphQlService: GraphqlService) {
    }

    public ngOnInit(): void {
        this.getStatistic();
    }

    /**
     * Set the current statistics for the page
     * */
    private getStatistic(): void {
        this.graphQlService.getStatistics().then((statistic: ApolloQueryResult<GetStatisticsQuery>): void => {
            const { activeSimCards, totalSimCards, currentMonthlyDataUsage, previousMonthDataUsage } = statistic.data.getStatistics;
            this.simActiveAmount = activeSimCards;
            this.totalSimCards = totalSimCards;
            this.currentDataConsumption = currentMonthlyDataUsage;
            this.lastMonthDataConsumption = previousMonthDataUsage;
        }).finally((): void => {
            this.isFirstLoading = false;
        });
    }
}
