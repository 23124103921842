<ng-template #content let-modal>
  <div class="modal-header" data-cy="modal-bulk-change-sim-status-dialog">
    <h1 class="modal-title fs-5" id="bulkChangeSimStatusLabel">{{ 'bulkStatusChange.title' | i18next }}</h1>
    <button type="button" class="btn-close" aria-label="Close" [disabled]="inProgress" (click)="modal.close('X')">
    </button>
  </div>
  <div class="modal-body">
    <div class="col">
      {{ 'bulkStatusChange.changeStatusOfXSims' | i18next: {count: selectedSimCards?.length} }}
      <!-- at least one sim cards status can be changed -->
      <div *ngIf="availableStatus && availableStatus.length > 0; else noStatusChangeAvailable" class="dropdown">
        <button [ngClass]="STATUS_ICON_MAPPING[selectedStatusId]" aria-expanded="false"
                class="btn dropdown-toggle fw-semibold mb-1" data-bs-toggle="dropdown" type="button"
                data-cy="modal-bulk-change-sim-status-dropdown-button">
          {{ 'status-' + selectedStatusId | i18next }}
        </button>
        <div class="dropdown-menu">
          <a (click)="changeStatus(statusId)" *ngFor="let statusId of availableStatus"
             class="dropdown-item d-flex mt-1 mb-1" data-cy="modal-bulk-change-sim-status-dropdown-item">
            <button [ngClass]="STATUS_ICON_MAPPING[statusId]" class="btn fw-semibold pe-none ms-1" type="button">
              {{ 'status-' + statusId | i18next }}
            </button>
          </a>
        </div>
      </div>
      <ng-template #noStatusChangeAvailable>
        {{ 'bulkStatusChange.noStatusChangePossible' | i18next: {count: selectedSimCards?.length} }}
      </ng-template>
    </div>
  </div>
  <!-- no status changes available -->
  <div class="row">
    <div class="col">
      <div *ngIf="simCardStatusChangeUnavailable.length > 0" class="alert alert-light"
           data-cy="modal-bulk-change-sim-status-unavailable-sims-div">
        <a [routerLink]="[]" [queryParams]="getQueryParamFilter(simCardStatusChangeUnavailable)" target="_blank">
          {{
            'bulkStatusChange.multipleSimCardsCannotChangeLink' | i18next: {
              count: simCardStatusChangeUnavailable.length,
              status: 'status-' + selectedStatusId
            }
          }}
        </a>
        {{
          'bulkStatusChange.multipleSimCardsCannotChange' | i18next: {
            status: 'status-' + selectedStatusId,
            count: simCardStatusChangeUnavailable.length
          }
        }}
      </div>
      <div *ngIf="simCardStatusChangeAvailable.length > 0" class="alert alert-light mb-0"
           data-cy="modal-bulk-change-sim-status-available-sims-div">
        {{ 'bulkStatusChange.multipleSimCardsCanBeChangedStart' | i18next }}
        <a [routerLink]="[]" [queryParams]="getQueryParamFilter(simCardStatusChangeAvailable)" target="_blank">
          {{ 'bulkStatusChange.multipleSimCardsCanBeChangedLink' | i18next: {count: simCardStatusChangeAvailable.length} }}
        </a>
        {{ 'bulkStatusChange.multipleSimCardsCanBeChangedEnd' | i18next }}
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="col float-start">
      <button type="button" class="btn btn-secondary" [disabled]="inProgress" (click)="modal.close('Cancel')"
              data-cy="modal-bulk-change-sim-cancel-button">
        {{ 'shared.btn.cancel' | i18next }}
      </button>
    </div>
    <div class="col float-end text-end">
      <button type="button" (click)="commitStatusChange()"
              [disabled]="(simCardStatusChangeAvailable.length === 0) || inProgress"
              class="btn btn-primary" data-cy="modal-bulk-change-sim-status-save-button">
        {{ 'apply' | i18next }}
        <span *ngIf="inProgress" class="spinner-border spinner-border-sm" aria-hidden="true"></span>
      </button>
    </div>
  </div>
</ng-template>
