<div class="m-3">
  <div class="d-flex flex-row justify-content-between align-items-center my-3">
  <span class="text-secondary fs-2">
    {{ 'billingData.export.title' | i18nextCap }}
  </span>
  </div>
  <div>
    <dx-data-grid
      id="billingDataExportTable"
      [dataSource]="dataSource"
      [showBorders]="true"
      [showRowLines]="true"
      [allowColumnResizing]="true"
      [wordWrapEnabled]="true"
      [rowAlternationEnabled]="true"
      [repaintChangesOnly]="true"
      [remoteOperations]="true"
      class="set-height"
    >
      <dxo-scrolling mode="infinite"></dxo-scrolling>
      <dxo-load-panel [enabled]="true"></dxo-load-panel>

      <!-- year -->
      <dxi-column dataField="month" dataType="string" [allowSorting]="false"
                  [caption]="this.i18nextPipe.transform('billingData.table.header.month', { format: 'Cap'})"
                  [allowHeaderFiltering]="false"
                  [alignment]="'left'"
                  [width]="'35%'"></dxi-column>

      <!-- month -->
      <dxi-column dataField="year" dataType="string" [allowSorting]="false"
                  [caption]="this.i18nextPipe.transform('billingData.table.header.year', { format: 'Cap'})"
                  [allowHeaderFiltering]="false"
                  [alignment]="'right'"></dxi-column>

      <!-- data volume -->
      <dxi-column dataField="dataVolume" dataType="string" [allowSorting]="false"
                  [caption]="this.i18nextPipe.transform('billingData.table.header.dataVolume', { format: 'Cap'})"
                  [allowFiltering]="false"
                  [alignment]="'right'"
                  cellTemplate="cellTemplateDataVolume"></dxi-column>

      <div *dxTemplate="let data of 'cellTemplateDataVolume'">
        <div class="d-flex flex-row justify-content-end">
          {{ data.data.dataVolume | byteFormat }}
        </div>
      </div>

      <!-- invoice amount -->
      <dxi-column dataField="invoiceAmount" dataType="string" [allowSorting]="false"
                  [caption]="this.i18nextPipe.transform('billingData.table.header.invoiceAmount', { format: 'Cap'})"
                  [allowFiltering]="false"
                  [alignment]="'right'"></dxi-column>

      <!-- download billing data -->
      <dxi-column dataField=""
                  [allowSorting]="false"
                  [caption]=""
                  [allowFiltering]="false"
                  [width]="'2%'"
                  [minWidth]="50"
                  cellTemplate="cellTemplateActions"></dxi-column>

      <div *dxTemplate="let data of 'cellTemplateActions'">
        <div class="d-flex flex-row justify-content-center">
          <i [class]="DEFAULT_DOWNLOAD_BUTTON" [attr.id]="'download-billing-data-' + data.data.id"
             [attr.data-cy]="'download-billing-data-' + data.data.id" (click)="downloadBillingData(data.data)">
          </i>
        </div>
      </div>
    </dx-data-grid>
  </div>
</div>
