import { Injectable } from '@angular/core';
import { I18NextPipe } from 'angular-i18next';
import { GraphQLFormattedError } from 'graphql/error';
import { KeyValueInput } from '../../../graphql/graphql.generated';
import { AuthService, LOGGED_OUT } from './auth/auth.service';
import { GraphqlService } from './graphql.service';
import { ToastMessageBuilder, ToastService } from './toast.service';

@Injectable({
    providedIn: 'root'
})
export class UserConfigService {

    public static readonly KEY_COLUMN_STATE_SIM_LIST: string = 'simListColumnState';

    private localStore: Map<string, (string | undefined | null)> = new Map();
    private promise?: Promise<Map<string, (string | undefined | null)>>;

    public constructor(private graphQlService: GraphqlService,
                       private authService: AuthService,
                       private toastService: ToastService,
                       private i18nextPipe: I18NextPipe) {

        this.authService.subscribeAuthStatus().subscribe((status) => {
            if (status == LOGGED_OUT) {
                this.promise = Promise.resolve(new Map<string, (string | undefined | null)>());
            } else {
                this.promise = new Promise<Map<string, (string | undefined | null)>>((resolve) => {
                    this.authService.getLoggedInPromise().then(() => {
                        this.loadUserConfig(resolve);
                    });
                });
            }
        });
    }

    private loadUserConfig(resolve: (value: (PromiseLike<Map<string, string | undefined | null>> | Map<string, string | undefined | null>)) => void) {
        this.graphQlService.getUserConfig(false).then((value) => {
            if (value.data) {
                value.data.getUserConfig.forEach((key) => {
                    if (key) {
                        this.localStore.set(key.key, key.value);
                    }
                });
                resolve(this.localStore);
            } else if (value.errors) {
                this.showError(value.errors[0], 'errorLoad');
            }
        }).catch((error) => {
            if (error) {
                this.showError(error, 'errorLoad');
            }
        });
    }

    /**
     * Stores all locally stored user config values to the backend
     */
    public storeAll(showSuccess: boolean = true) {
        const mapToStore: KeyValueInput[] = [];
        this.localStore.forEach((value, key) => {
            mapToStore.push({ key: key, value: value });
        });
        this.graphQlService.setUserConfig(mapToStore).then((result) => {
            if (!result.data && (result.errors && result.errors.length > 0)) {
                this.showError(result.errors[0], 'errorStore');
            } else if (showSuccess) {
                this.toastService.show(ToastMessageBuilder.success().text(this.i18nextPipe.transform('userConfig.storeTableConfigSuccessful')).build());
            }
        }).catch((error) => {
            if (error) {
                this.showError(error, 'errorStore');
            }
        });
    }

    /**
     * Returns the user configuration as a Map - may have to wait
     */
    public getUserConfig(): Promise<Map<string, (string | undefined | null)>> | undefined {
        return this.promise;
    }

    /**
     * Shows an error via ToastService - either while storing or loading data
     * @param error the error object to get a message from
     * @param kind the kind - 'errorStore' or 'errorLoad'
     */
    private showError(error: GraphQLFormattedError, kind: string) {
        const text: string = this.i18nextPipe.transform('userConfig.' + kind, { error: error.message });
        this.toastService.show(ToastMessageBuilder.error().text(text).build());
    }
}
