import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { StatusConstants } from '../../constants';

@Component({
    selector: 'app-sms-limit',
    templateUrl: './sms-limit.component.html',
    styleUrl: './sms-limit.component.scss'
})
export class SmsLimitComponent implements ICellRendererAngularComp {

    protected moLimit: number | undefined;
    protected mtLimit: number | undefined;
    protected smsLimitExceeded: boolean = false;
    protected showEdit = false;
    protected iccId: string | undefined;

    private params: ICellRendererParams | undefined;

    public agInit(params: ICellRendererParams): void {
        this.updateData(params);
        this.showEdit = (params.data);
    }

    public refresh(params: ICellRendererParams): boolean {
        this.updateData(params);
        return true;
    }

    private updateData(params: ICellRendererParams) {
        this.params = params;
        if (params.data) {
            this.smsLimitExceeded = params.data.statusid === StatusConstants.SMS_LIMIT_EXCEEDED;
            this.moLimit = params.data.monthly_sms_mo_limit;
            this.mtLimit = params.data.monthly_sms_mt_limit;
            this.iccId = params.data.iccid;
        }
    }

    public startEditMode() {
        if (this.params?.column && this.params?.node.rowIndex) {
            this.params.api.startEditingCell({ rowIndex: this.params.node.rowIndex, colKey: this.params.column });
        }
    }

    protected readonly StatusConstants = StatusConstants;
}
