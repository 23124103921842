<h2 class="text-center pt-4">{{ 'login.title_login' | i18next }}</h2>

<div *ngIf="error" class="alert alert-danger mt-3" role="alert">
  {{ error }}
</div>

<form (ngSubmit)="doLogin()" [formGroup]="form">

  <div class="form-group mt-4">
    <label class="form-label" for="username">{{ 'email' | i18nextCap }}</label>
    <input [hidden]="!emailEdit" autofocus class="form-control username"
           formControlName="emailAddress" id="username" name="username" required type="text" data-cy="input-username"
           autocomplete="username"/>
    <div *ngIf="!emailEdit" class="text-secondary py-2">
      {{ userEmail }}
      <button (click)="emailEdit=true" type="button" class="btn fa-solid fa-pen text-secondary"></button>
    </div>
  </div>

  <div class="form-group mt-3">
    <label class="form-label" for="password">{{ 'password' | i18next }}</label>
    <input class="form-control password" formControlName="password" id="password" name="password"
           required type="password" data-cy="input-password" autocomplete="current-password"/>
  </div>
  <a routerLink="../reset-password" data-cy="forgot-password-link" [state]="{email: form.value['emailAddress']}"
     class="link-secondary">
    {{ 'button.forgot_password' | i18next }}
  </a>

  <div class="d-grid gap-2 col-12 mx-auto">
    <button [disabled]="form.invalid || loginDisabled" class="btn btn-secondary mt-4"
            type="submit" data-cy="button-login">
      {{ 'button.login' | i18next }}
      <i *ngIf="!loginDisabled" class="fa-solid fa-arrow-right"></i>
      <i *ngIf="loginDisabled" class="fa-solid fa-solid fa-circle-notch fa-spin"></i>
    </button>
  </div>
</form>

<div class="footer">
  <div class="d-inline-flex gap-2 p-2">
    <div>
      <a href="mailto:support@whereversim.de" class="btn btn-light" role="button">
        <i class="fa-solid fa-envelope"></i> support&commat;whereversim.de
      </a>
    </div>
    <div>
      <a href="tel:+4940228525777" class="btn btn-light" role="button">
        <i class="fa-solid fa-phone"></i> +49 40 2285257-77
      </a>
    </div>
  </div>
</div>
