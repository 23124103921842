import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { environment } from '../environments/environment';
import { ChangePasswordComponent } from './auth/change-password/change-password.component';
import { ForgotPasswordComponent } from './auth/forgot-password/forgot-password.component';
import { SetPasswordComponent } from './auth/forgot-password/set-password/set-password.component';
import { InitialPasswordChangeComponent } from './auth/sign-in/initial-password-change/initial-password-change.component';
import { SignInComponent } from './auth/sign-in/sign-in.component';
import { ExportBillingDataComponent } from './billing-data/export-billing-data/export-billing-data.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { authGuard } from './service/auth/auth-guard.guard';
import { permissionGuard } from './service/auth/permission.guard';
import { Permission } from './service/auth/WSimAuth';
import { NotificationsComponent } from './settings/notifications/notifications.component';
import { SettingsComponent } from './settings/settings.component';
import { UserManagementComponent } from './settings/user-management/user-management.component';
import { SimListComponent } from './sim-list/sim-list.component';

const routes: Routes = [
    { path: '', redirectTo: '/sim-list', pathMatch: 'full' },

    { path: 'account/signin', component: SignInComponent },
    { path: 'account/signin/initial-password-change', component: InitialPasswordChangeComponent },
    { path: 'account/reset-password', component: ForgotPasswordComponent },
    { path: 'account/reset-password/set', component: SetPasswordComponent },
    { path: 'account/reset-password/:code', component: SetPasswordComponent },
    { path: 'account/change-password', component: ChangePasswordComponent, canActivate: [authGuard] },
    // Changed URLs on 27.06.2024 ... can be removed in future
    { path: 'signin', redirectTo: '/account/signin', pathMatch: 'full' },
    { path: 'change-pass', redirectTo: '/account/change-password', pathMatch: 'full' },

    { path: 'sim-list', component: SimListComponent, canActivate: [authGuard] },
    { path: 'sim-list/:iccid', component: SimListComponent, canActivate: [authGuard] },

    {
        path: 'settings',
        component: SettingsComponent,
        canActivate: [authGuard],
        children: [
            { path: '', pathMatch: 'prefix', redirectTo: 'notifications' },
            {
                path: 'notifications', component: NotificationsComponent,
                canActivate: [permissionGuard],
                data: { permission: [Permission.CUSTOMER_READ_USAGENOTIFICATIONS, Permission.CUSTOMER_WRITE_USAGENOTIFICATIONS] }
            },
            {
                path: 'usermanagement', component: UserManagementComponent,
                canActivate: [permissionGuard],
                data: { permission: [Permission.CUSTOMER_READ_ACCOUNTS] }
            }
        ]
    },

    {
        path: 'billing-data/billing-data-export',
        component: environment.showBillingDataExport ? ExportBillingDataComponent : PageNotFoundComponent,
        canActivate: [permissionGuard],
        data: { permission: [Permission.CUSTOMER_READ_BILLING_DATA_EXPORT] }
    },

    { path: '**', component: PageNotFoundComponent }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { bindToComponentInputs: true, enableTracing: !environment.production })],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
