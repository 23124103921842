import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
    selector: 'app-end-customer-label',
    templateUrl: './end-customer-label.component.html',
    styleUrl: './end-customer-label.component.scss',
    animations: [
        trigger('fade', [
            state('visible', style({ opacity: 1 })),
            state('hidden', style({ opacity: 0 })),
            transition('visible <=> hidden', animate('600ms'))
        ])
    ]
})
export class EndCustomerLabelComponent implements ICellRendererAngularComp {

    protected labelName: string = '';
    protected hidden: boolean = true;
    protected params: ICellRendererParams | undefined;
    protected showEdit: boolean = false;

    public agInit(params: ICellRendererParams): void {
        this.labelName = params.value?.name;
        this.params = params;
        this.showEdit = !!(params.colDef?.editable);
    }

    public refresh(params: ICellRendererParams): boolean {
        if (params !== this.params) {
            this.params = params;
        }
        if (this.labelName !== params.value?.name) {
            this.labelName = params.value?.name;
            return true;
        }
        return false;
    }

    public startEditMode(): void {
        if (this.params?.column && this.params?.node.rowIndex != undefined) {
            this.params.api.startEditingCell({ rowIndex: this.params.node.rowIndex, colKey: this.params.column });
        }
    }

    protected copyClick($event: MouseEvent) {
        $event.stopPropagation();
    }

}
