import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
    selector: 'app-text-container-with-copy-to-clipboard',
    templateUrl: './text-container-with-copy-to-clipboard.component.html',
    styleUrl: './text-container-with-copy-to-clipboard.component.scss',
    animations: [
        trigger('fade', [
            state('visible', style({ opacity: 1 })),
            state('hidden', style({ opacity: 0 })),
            transition('visible <=> hidden', animate('600ms'))
        ])
    ]
})
export class TextContainerWithCopyToClipboardComponent implements ICellRendererAngularComp {

    public text: string = '';
    public hidden: boolean = true;
    protected params: ICellRendererParams | undefined;
    protected showEdit: boolean = false;

    public agInit(params: ICellRendererParams): void {
        this.text = params.value;
        this.params = params;
        this.showEdit = !!(params.colDef?.editable);
    }

    public refresh(params: ICellRendererParams): boolean {
        if (params !== this.params) {
            this.params = params;
        }
        if (this.text !== params.value) {
            this.text = params.value;
            return true;
        }
        return false;
    }

    public startEditMode(): void {
        if (this.params?.column && this.params?.node.rowIndex != undefined) {
            this.params.api.startEditingCell({ rowIndex: this.params.node.rowIndex, colKey: this.params.column });
        }
    }

    protected copyClick($event: MouseEvent) {
        $event.stopPropagation();
    }
}
