import { Component, Input, TemplateRef, ViewChild } from '@angular/core';
import { FetchResult } from '@apollo/client/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { I18NextPipe } from 'angular-i18next';
import { Sim, UpdateSimMutation } from '../../../../graphql/graphql.generated';
import { GraphqlService } from '../../service/graphql.service';
import { LoggerService } from '../../service/logger';
import { StatusTransitionsService } from '../../service/status-transitions.service';
import { ToastMessageBuilder, ToastService } from '../../service/toast.service';
import { STATUS_ICON_MAPPING, StatusConstants } from '../../shared/constants';


interface StatusTransition {
    iccId: string;
    deviceName: string | undefined;
}

@Component({
    selector: 'app-modal-bulk-change-sim-status',
    templateUrl: './modal-bulk-change-sim-status.component.html',
    styleUrl: './modal-bulk-change-sim-status.component.scss'
})
export class ModalBulkChangeSimStatusComponent {

    protected readonly STATUS_ICON_MAPPING = STATUS_ICON_MAPPING;

    // for input from another component
    @Input()
    public selectedSimCards?: Sim[];

    // status available for selection
    public availableStatus: number[] = [];

    // selected status - 0 means "select status please"
    public selectedStatusId: number = 0;

    // when the user clicks on a status an internal evaluation is started which sets which cards
    // can be set into the selected status and which not
    public simCardStatusChangeAvailable: StatusTransition[] = [];
    public simCardStatusChangeUnavailable: StatusTransition[] = [];

    private availableTransitions: { [iccId: string]: number[] } = {};

    public inProgress = false;

    @ViewChild('content')
    private content: TemplateRef<never> | undefined;

    private modal?: NgbModalRef;

    public constructor(private availableTransitionsService: StatusTransitionsService, private graphQlService: GraphqlService,
                       private toastService: ToastService, private i18nextPipe: I18NextPipe, private modalService: NgbModal) {
    }

    public open() {
        this.evaluateAvailableStatus();
        this.modal = this.modalService.open(this.content, { ariaLabelledBy: 'bulkChangeSimStatusLabel' });
        this.modal.result.then(() => {
            this.clearData();
        });
        return this.modal;
    }

    public changeStatus(statusId: number) {
        // status change in dropdown
        this.selectedStatusId = statusId;
        this.simCardStatusChangeAvailable = [];
        this.simCardStatusChangeUnavailable = [];
        // evaluate that every SIM selected can be changed to the selected status
        this.selectedSimCards?.forEach((value: Sim) => {
            const item = <StatusTransition>{
                iccId: value.iccid,
                deviceName: value.custom_field_1
            };
            if (this.canChangeToStatus(value, statusId)) {
                this.simCardStatusChangeAvailable.push(item);
            } else {
                this.simCardStatusChangeUnavailable.push(item);
            }

        });
    }

    public commitStatusChange() {
        this.inProgress = true;
        // collect the promises and wait with closing the dialog until we sent out all ...
        const promises: Promise<FetchResult<UpdateSimMutation>>[] = [];
        const successfulArray: string[] = [];
        // execute the updateSim mutations and
        this.simCardStatusChangeAvailable.forEach((simCard) => {
            promises.push(this.graphQlService.updateSim(simCard.iccId, this.selectedStatusId));
        });

        // very important: promises are resolved in order. so we can access the original array for icc id mutation was called for:
        // https://stackoverflow.com/a/67274260
        Promise.allSettled(promises).then((results: PromiseSettledResult<FetchResult<UpdateSimMutation>>[]) => {
            results.forEach((promiseResult, index) => {
                if (promiseResult.status === 'fulfilled') {
                    if (promiseResult.value.data?.updateSim.iccid) {
                        successfulArray.push(promiseResult.value.data?.updateSim.iccid);
                    }
                } else {
                    const iccId = this.simCardStatusChangeAvailable[index].iccId;
                    const text = this.i18nextPipe.transform('bulkStatusChange.errorChangeStatusForIccId', {
                        iccId: iccId,
                        error: promiseResult.reason?.message
                    });
                    this.toastService.show(ToastMessageBuilder.error().text(text).build());
                }
            });
            this.inProgress = false;
            if (successfulArray.length > 0) {
                const text = this.i18nextPipe.transform('bulkStatusChange.successChangeStatus', { count: successfulArray.length });
                this.toastService.show(ToastMessageBuilder.success().text(text).build());
            }
            this.modal?.close();
        });
    }

    private canChangeToStatus(simCard: Sim, statusIdToChangeTo: number): boolean {
        return this.availableTransitions[simCard.iccid] && this.availableTransitions[simCard.iccid].indexOf(statusIdToChangeTo) > -1;
    }

    private evaluateAvailableStatus() {
        this.availableStatus = [];
        this.availableTransitions = {};
        this.selectedSimCards?.forEach((value: Sim) => {
            this.availableTransitionsService.getAvailableTransitions(value.providerid, value.statusid).then((result) => {
                result?.forEach((status) => {
                    LoggerService.debug('found status to transit to: ', status);
                    if (this.availableStatus.indexOf(status) == -1) {
                        this.availableStatus.push(status);
                    }
                    if (!this.availableTransitions[value.iccid]) {
                        this.availableTransitions[value.iccid] = [];
                    }
                    this.availableTransitions[value.iccid].push(status);
                });
            });
        });
    }

    private clearData() {
        this.availableTransitions = {};
        this.simCardStatusChangeUnavailable = [];
        this.simCardStatusChangeAvailable = [];
        this.selectedStatusId = StatusConstants.SELECT_STATUS;
        this.availableStatus = [];
    }

    public getQueryParamFilter(params: StatusTransition[]) {
        const filter = {
            'iccid': {
                'filterType': 'text',
                'type': 'in',
                'filter': params.map((value) => value.iccId).join(',')
            }
        };
        return { filter: JSON.stringify(filter) };
    }
}
