<div class="row">
  <div class="col">
    <div class="card bg-light h-100">
      <div class="card-body" data-cy="sim-details-card-left">
        <span class="text-secondary fw-bold">{{ 'connection-status' | i18nextCap }}</span><br>
        <i class="fa fa-circle" [ngClass]="sim?.in_session_since>0?'text-online':'text-offline'"></i>
        &nbsp;{{ (sim?.in_session_since > 0 ? 'online' : 'offline') | i18nextCap }}<br>
        <span *ngIf="(sim?.in_session_since > 0)">
        <span>{{ 'nwc' | i18next }}: <app-copy-to-clipboard
          [text]="sim?.nwc"></app-copy-to-clipboard><br>
          {{ 'since' | i18nextCap }}: {{ 'formats:dateTimeMedium' | i18next: {date: sim?.in_session_since} }}</span><br>
        <span class="text-secondary">
          <b>{{ 'ip-address' | i18nextCap }}:</b>&nbsp;<app-copy-to-clipboard
          [text]="sim?.ip_address"></app-copy-to-clipboard>
        </span>
        </span>
        <div class="mt-1">
          <app-sim-detail-cancel-location [sim]="sim">
          </app-sim-detail-cancel-location>
        </div>
      </div>
    </div>
  </div>
  <div class="col">
    <div class="card bg-light h-100">
      <div class="card-body text-secondary" data-cy="sim-details-card-right">
        <b>{{ 'imei' | i18next }}:</b>&nbsp;<app-copy-to-clipboard [text]="sim?.imei"></app-copy-to-clipboard>
        <br>
        <b>{{ 'imsi' | i18next }}:</b>&nbsp;<app-copy-to-clipboard [text]="sim?.imsi"></app-copy-to-clipboard>
        <br>
        <b>{{ 'msisdn' | i18next }}:</b>&nbsp;
        <app-copy-to-clipboard [text]="sim?.msisdn" [formatted]="(sim!.msisdn! | msisdnFormat)"></app-copy-to-clipboard>
        <br>
      </div>
    </div>
  </div>
</div>
