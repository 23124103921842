import { Component, inject, ViewChild } from '@angular/core';
import { ICellEditorAngularComp } from 'ag-grid-angular';
import { ICellEditorParams } from 'ag-grid-community';
import { I18NextPipe } from 'angular-i18next';
import { DxAutocompleteComponent } from 'devextreme-angular';
import { LoadOptions } from 'devextreme/data';
import CustomStore from 'devextreme/data/custom_store';
import { EndCustomerLabel, ListEndCustomerLabelsFilterInput } from '../../../../../graphql/graphql.generated';
import { GraphqlService } from '../../../service/graphql.service';
import { LoggerService } from '../../../service/logger';
import { ToastMessageBuilder, ToastService } from '../../../service/toast.service';

@Component({
    selector: 'app-end-customer-label-edit',
    templateUrl: './end-customer-label-edit.component.html',
    styleUrl: './end-customer-label-edit.component.scss'
})
export class EndCustomerLabelEditComponent implements ICellEditorAngularComp {
    @ViewChild('endCustomerLabelInput')
    public input: DxAutocompleteComponent | undefined;

    protected saving = false;
    protected unsavedLabel: string = '';
    protected readonly store: CustomStore;
    protected cellWidth: string | undefined;
    protected readonly maxItemCount = 20;
    protected loadingSuggestions = false;

    private iccId: string | undefined;
    private params: ICellEditorParams | undefined;
    private saveSuccessful = false;
    private graphQlService: GraphqlService = inject(GraphqlService);
    private toastService: ToastService = inject(ToastService);
    private i18Next: I18NextPipe = inject(I18NextPipe);
    private graphql: GraphqlService = inject(GraphqlService);

    public constructor() {
        this.store = new CustomStore({
            load: async (loadOptions: LoadOptions) => {
                this.loadingSuggestions = true;
                LoggerService.debug('CustomStore load with load options: ', loadOptions);
                const filter: ListEndCustomerLabelsFilterInput = {
                    name: {
                        contains: loadOptions.searchValue
                    }
                };
                const response = await this.graphql.listEndCustomerLabels(filter, this.maxItemCount, undefined);
                LoggerService.debug('CustomStore load result: ', response.data);
                const result: string[] = [];
                if (response.data?.listEndCustomerLabels?.items) {
                    response.data.listEndCustomerLabels.items.forEach((item) => {
                        if (item) {
                            result.push(item.name);
                        }
                    });
                }
                this.loadingSuggestions = false;
                return result;
            }
        });
    }

    public agInit(params: ICellEditorParams): void {
        const data = params.data;
        this.iccId = data.iccid;
        this.params = params;
        this.saveSuccessful = false;
        if (!this.unsavedLabel && data.end_customer_label) {
            this.unsavedLabel = data.end_customer_label.name;
        }
        this.cellWidth = params.column.getActualWidth() + 'px';
    }

    /**
     * On Key down in the input field
     * @param $event
     */
    protected onKeyDown($event: KeyboardEvent) {
        if ($event.key === 'Escape') {
            this.close();
        } else if ($event.key === 'Enter') {
            $event.stopPropagation();
            this.save();
        } else if ($event.code.startsWith('Arrow')) {
            // Allow cursor movement
            $event.stopPropagation();
        } else if ($event.metaKey && $event.code === 'KeyA') {
            // Select All
            $event.stopPropagation();
        }
    }

    protected submitSave($event: MouseEvent) {
        if (!this.saving) {
            $event.stopPropagation();
            this.save();
        }
    }

    /**
     * Stores the data currently configured in this view
     */
    protected save() {
        if (!this.saving && this.iccId) {
            this.saving = true;
            // create an end customer label object to be saved
            const newEndCustomerLabel = {
                'name': this.unsavedLabel || ''
            };
            this.graphQlService.updateSim(this.iccId, undefined, undefined, undefined, undefined, undefined, undefined, newEndCustomerLabel).then((result) => {
                if (result.data) {
                    this.saveSuccessful = true;
                    this.saving = false;
                    this.close();
                }
            }).catch((error) => {
                this.unsavedLabel = '';
                // show an error message!
                this.toastService.show(ToastMessageBuilder.error().text(this.i18Next.transform('error.endCustomerLabelUpdate', { errorMsg: error.message })).build());
                this.saving = false;
                this.close();
            });
        }
    }

    public close() {
        this.params?.stopEditing(true);
    }

    /**
     * This method should return undefined when not changed.
     * If the stored value changed, it should return the new EndCustomerLabel containing the name only currently.
     */
    public getValue(): EndCustomerLabel | undefined {
        if (this.saveSuccessful) {
            let resultValue = undefined;
            if (this.unsavedLabel) {
                resultValue = {
                    name: this.unsavedLabel
                };
            }
            return resultValue;
        }
        return undefined;
    }

    public afterGuiAttached() {
        this.input?.instance.focus();
    }

    protected clearInput(): void {
        this.unsavedLabel = '';
        this.afterGuiAttached();
    }

}
