<div (mouseenter)="hidden = !hidden" (mouseleave)="hidden = !hidden" class="container-fluid p-0">
  <div class="d-flex flex-row">
    <!-- lock / unlock icon -->
    <div class="mb-auto me-1" [id]="'imei-lock-lock-popover-' + iccId">
      <i [class]="lockButtonClass" [attr.data-cy]="'imei-lock-lock-button-' + iccId"
         (click)="switchImeiLock($event)"></i>
    </div>

    <!-- imei or auto detect -->
    <div>
      <span *ngIf="!locked" class="text-truncate flex-fill mb-auto">{{ deviceImei }}</span>
      <span *ngIf="locked && lockedImei" class="text-truncate flex-fill mb-auto">{{ lockedImei }}</span>
      <span *ngIf="locked && !lockedImei" [attr.data-cy]="'auto-detect-badge-' + iccId" aria-expanded="false"
            class="btn mb-1 badge-auto-detect disable-hover">
        {{ 'imeiLockAutoDetect' | i18next }}
      </span>
    </div>

    <!-- reset lock and copy imei icons -->
    <div class="ms-auto">
      <i *ngIf="autoDetectButtonEnabled" class="ms-auto fa-solid fa-arrow-rotate-right btn p-0 m-0 me-1 mb-1"
         (click)="setAutoDetect($event)" [class]="saving ? 'fa-disabled' : ''"
         [id]="'imei-lock-auto-detect-popover-' + iccId" [attr.data-cy]="'change-device-' + iccId"></i>
      <!-- KEWE: disable copy button when no IMEI is available -->
      <i *ngIf="deviceImei" [@fade]="hidden ? 'hidden' : 'visible'" (click)="copyClick($event)" [cbContent]="deviceImei"
         class="ms-auto fa-solid fa-copy p btn p-0 m-0 mb-1" ngxClipboard></i>
      <i *ngIf="!deviceImei" class="ms-auto fa-solid btn fa-copy p p-0 m-0 mb-1 pointer-events-disabled" [@fade]="'hidden'"></i>
    </div>
  </div>
</div>

<app-default-popover [text]="i18next.transform('imeiLockPopover')"
                     [componentId]="'imei-lock-lock-popover-' + iccId">
</app-default-popover>

<app-default-popover [text]="i18next.transform('imeiLockAutoDetectPopover')"
                     [componentId]="'imei-lock-auto-detect-popover-' + iccId">
</app-default-popover>
