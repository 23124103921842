<dx-popover [target]="'#cancelLocationBtn'" showEvent="mouseenter" hideEvent="mouseleave"
            [showTitle]="true" titleTemplate="title" [width]="300" data-cy="cancel-location-popover">

  <div *dxTemplate="let data of 'title'" class="cancel-location-popover-title text-center">
    <i class="fa-solid fa-rotate-right"></i>
    {{ 'button.cancel_location.title' | i18nextCap }}
    <span *ngIf="!isRequestAvailable" data-cy="cancel-location-timer">
      {{ leftover | date: "mm:ss":  undefined: 'en' }}
    </span>
  </div>

  <dxo-position *ngIf="position" [my]="position" at="right" [of]="'#cancelLocationBtn'" offset="0 0"></dxo-position>

  <div *dxTemplate="let data = model; of: 'content'">
    <div *ngIf="isRequestAvailable">
      {{ 'button.cancel_location.info' | i18nextCap }}
    </div>
    <div *ngIf="!isRequestAvailable">
      {{ 'button.cancel_location.waiting' | i18nextCap }}
    </div>
  </div>
</dx-popover>
