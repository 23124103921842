<div [style.width]="cellWidth" class="container-fluid p-0">
  <div class="d-flex justify-content-end w-100 g-0 p-0 ps-1">
    <div class="d-inline-flex align-items-center p-1 input-container">
      <label class="visually-hidden col-form-label col-form-label-sm" for="inlineFormInputGroupLimitSmsMo">Limit</label>
      <div class="input-group input-group-sm align-items-center">
        <input #limitInputSmsMo (keydown)="onKeyDown($event)"
               [(ngModel)]="unsavedSmsMoLimit" class="form-control form-control-sm number-input"
               digitOnly id="inlineFormInputGroupLimitSmsMo" required type="text">
        <i class="fa-solid fa-arrow-up input-container-icon"></i>
      </div>
    </div>
    <div class="d-inline-flex align-items-center p-1 input-container" *ngIf="showMtValues">
      <label class="visually-hidden col-form-label col-form-label-sm" for="inlineFormInputGroupLimitSmsMt">Limit</label>
      <div class="input-group input-group-sm align-items-center">
        <input (keydown)="onKeyDown($event)"
               [(ngModel)]="unsavedSmsMtLimit" class="form-control form-control-sm number-input"
               digitOnly id="inlineFormInputGroupLimitSmsMt" required type="text">
        <i class="fa-solid fa-arrow-down input-container-icon"></i>
      </div>
    </div>
    <div class="d-inline-flex align-items-center pe-1">
      <a (click)="submitSave($event)" data-cy="sms-limit-save-button"
         class="btn btn-sm btn-primary right-rounded-corners">
        <i *ngIf="!saving" class="fa-solid fa-check"></i>
        <i *ngIf="saving" class="fa-solid fa-solid fa-circle-notch fa-spin"></i>
      </a>
    </div>
  </div>
</div>

