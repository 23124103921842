<div class="dropdown" data-cy="table-column-config">
  <button class="btn btn-outline-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown"
          data-bs-auto-close="outside" aria-expanded="false">
    <i class="fa-solid fa-table-columns"></i> {{ 'modify columns' | i18next }}
  </button>
  <ul class="dropdown-menu user-select-none">
    <li>
      <button data-cy="table-column-config-button-store" class="dropdown-item" (click)="storeTable()">{{ 'store table' | i18next }}</button>
    </li>
    <li>
      <button data-cy="table-column-config-button-reset" class="dropdown-item" (click)="resetTable()">{{ 'reset table' | i18next }}</button>
    </li>
    <li>
      <hr class="dropdown-divider">
    </li>
    <span dndDropzone dndEffectAllowed="move" (dndDrop)="onDrop($event)">
      <li dndPlaceholderRef>
        <div class="form-check d-flex"></div>
      </li>
      <ng-container *ngFor="let col of columns; let index = index">
        <li *ngIf="showEntry(col)" class="dropdown-item"
            dndEffectAllowed="move"
            [dndDisableIf]="!isMovable(col)"
            [dndDraggable]="col"
            (dndMoved)="onDragMoved(col)"
        >
          <div class="form-check d-flex">
            <input type="checkbox" class="form-check-input" [id]="col.colId" [checked]="!col.hide"
                   (click)="changeVisibility(col)" [disabled]="!isVisibleLocked(col)">
            <label class="form-check-label ms-2" [for]="col.colId">{{ 'table.header.' + col.colId | i18next }}</label>
            <i *ngIf="isMovable(col)" class="fa-solid fa-grip-lines ms-auto ps-2"></i>
          </div>
        </li>
      </ng-container>
    </span>
  </ul>
</div>
