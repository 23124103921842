import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-copy-to-clipboard',
    templateUrl: './copy-to-clipboard.component.html',
    styleUrl: './copy-to-clipboard.component.scss',
    animations: [
        trigger('fade', [
            state('visible', style({ opacity: 1 })),
            state('hidden', style({ opacity: 0 })),
            transition('visible <=> hidden', animate('600ms'))
        ])
    ]
})
export class CopyToClipboardComponent {

    @Input({
        transform: (value: string | null | undefined) => {
            if (value) return value;
            return undefined;
        }
    }) public text: string | undefined;

    @Input({
        transform: (value: string | null | undefined) => {
            if (value) return value;
            return undefined;
        }
    }) public formatted: string | undefined;

    protected hidden = true;

}
