import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'countryCodeToFlag',
    standalone: true
})
export class CountryCodeToFlagPipe implements PipeTransform {

    private static alphaToFlagAlpha = (a: string) => String.fromCodePoint(0x1f1a5 + a.toUpperCase().codePointAt(0)!);
    private static emojiFlag = (countryCode: string) => countryCode.slice(0, 2).split('').map(CountryCodeToFlagPipe.alphaToFlagAlpha).join('');

    public transform(value: string | undefined): string | undefined {
        if (value) return CountryCodeToFlagPipe.emojiFlag(value);
        return value;
    }
}
