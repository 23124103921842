{
  "WSIM-Super-Admin": [
    "sim.read.batchJob",
    "sim.write.batchJob",
    "sim.write.customfield1",
    "sim.write.status",
    "sim.write.datalimit",
    "sim.write.smslimit",
    "sim.write.voicelimit",
    "sim.write.endcustomerlabel",
    "sim.write.imeilock",
    "sim.execute.sendsms",
    "sim.execute.cancellocation",
    "customer.read.usagenotifications",
    "customer.read.accounts",
    "customer.read.billingdataexport",
    "customer.write.usagenotifications",
    "customer.write.accounts"
  ],
  "Administrator": [
    "sim.read.batchJob",
    "sim.write.batchJob",
    "sim.write.customfield1",
    "sim.write.status",
    "sim.write.datalimit",
    "sim.write.smslimit",
    "sim.write.voicelimit",
    "sim.write.endcustomerlabel",
    "sim.write.imeilock",
    "sim.execute.sendsms",
    "sim.execute.cancellocation",
    "customer.read.usagenotifications",
    "customer.read.accounts",
    "customer.read.billingdataexport",
    "customer.write.usagenotifications",
    "customer.write.accounts"
  ],
  "Observer": [
    "sim.read.batchJob"
  ],
  "Manager": [
    "sim.read.batchJob",
    "sim.write.batchJob",
    "sim.write.customfield1",
    "sim.write.status",
    "sim.write.datalimit",
    "sim.write.smslimit",
    "sim.write.voicelimit",
    "sim.write.endcustomerlabel",
    "sim.write.imeilock",
    "sim.execute.sendsms",
    "sim.execute.cancellocation",
    "customer.read.usagenotifications",
    "customer.read.accounts",
    "customer.read.billingdataexport"
  ]
}