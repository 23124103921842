import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { I18NextPipe } from 'angular-i18next';
import { environment } from '../../../../environments/environment';
import { AuthService } from '../../../service/auth/auth.service';
import { ToastMessageBuilder, ToastService } from '../../../service/toast.service';
import { ErrorMessage } from '../../../shared/error-message';
import { PasswordStrengthComponent } from '../../../shared/password-strength/password-strength.component';
import { LoginStep } from '../sign-in.component';

@Component({
    selector: 'app-new-pass',
    templateUrl: './new-pass.component.html',
    styleUrls: ['./new-pass.component.scss']
})
export class NewPassComponent {

    @Output()
    protected showError: EventEmitter<ErrorMessage> = new EventEmitter<ErrorMessage>();

    @Output()
    protected changeStep: EventEmitter<LoginStep> = new EventEmitter<LoginStep>();

    @ViewChild(PasswordStrengthComponent)
    private passwordStrengthComponent!: PasswordStrengthComponent;

    protected readonly environment = environment;
    protected form: FormGroup;
    protected error: ErrorMessage | undefined;
    protected newPassDisabled: boolean = false;
    protected password?: string;
    protected passwordGuidelinesMatch: boolean = false;

    public constructor(private toastService: ToastService, private formBuilder: FormBuilder, private authService: AuthService,
                       private i18pipe: I18NextPipe) {
        this.form = this.formBuilder.group({
                newPassword: [null, Validators.required]
            }
        );
    }

    protected onChangePassword(): void {
        this.error = undefined;
        this.newPassDisabled = true;
        const newPass = this.form.value['newPassword'];

        const cognitoUser: CognitoUser | null = this.authService.getCurrentUser();
        // required attributes to be set when user is enabled!
        const requiredAttributes: { zoneinfo: string, locale: string } = {
            'zoneinfo': 'Europe/Berlin',
            'locale': 'de'
        };
        if (cognitoUser != null) {
            cognitoUser?.completeNewPasswordChallenge(newPass, requiredAttributes, {
                onSuccess: (): void => {
                    this.authService.logout();
                    const msg: string = this.i18pipe.transform('login.newPasswordLogin');
                    this.toastService.show(ToastMessageBuilder.success().text(msg).build());
                    this.newPassDisabled = false;
                    this.changeStep.emit(LoginStep.Login);
                },
                onFailure: (err: ErrorMessage): void => {
                    this.error = new ErrorMessage('Error.', err.message);
                    this.showError.emit(this.error);
                    this.newPassDisabled = false;
                }
            });
        } else {
            this.changeStep.emit(LoginStep.Login);
            this.newPassDisabled = false;
        }
    }

    /**
     * To do password validation through the password guideline object
     * */
    protected onNewPasswordChanged(): void {
        const newPass = this.form.value['newPassword'];
        this.passwordGuidelinesMatch = this.passwordStrengthComponent.checkPassword(newPass);
    }

    /**
     *  To emit the login step and change the sign in a component
     * */
    protected returnToLogin(): void {
        this.changeStep.emit(LoginStep.Login);
    }

}
