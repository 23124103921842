import { inject, Pipe, PipeTransform } from '@angular/core';
import { I18nCountriesService } from '../service/i18n-countries.service';

@Pipe({
    name: 'countryCodeToName',
    standalone: true
})
export class CountryCodeToNamePipe implements PipeTransform {

    private i18Countries = inject(I18nCountriesService);

    public constructor() {
    }

    public transform(value: string | undefined) {
        if (value) return this.i18Countries.getCountryName(value);
        return Promise.resolve(value);
    }

}
