import { Component, Input } from '@angular/core';

interface JsonDataInterface {
    json: {
        enabled?: boolean
        imei?: string
        lockedImei?: string
        detectedImei?: string
    };
}


@Component({
    selector: 'app-detail-imei-lock',
    templateUrl: './detail-imei-lock.component.html',
    styleUrl: './detail-imei-lock.component.scss'
})
export class DetailImeiLockComponent {

    @Input({ required: true })
    public data!: JsonDataInterface;


}
