import { Component, EventEmitter, inject, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { AuthenticationDetails, CognitoUser, CognitoUserPool, CognitoUserSession } from 'amazon-cognito-identity-js';
import { I18NextPipe } from 'angular-i18next';
import { LoggerService } from 'src/app/service/logger';
import { environment } from '../../../../environments/environment';
import { AuthService } from '../../../service/auth/auth.service';
import { Auth } from '../../../service/auth/WSimAuth';
import { ToastService } from '../../../service/toast.service';
import { LoginStep } from '../sign-in.component';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent {

    protected form: FormGroup;
    protected loginDisabled: boolean = false;
    protected userEmail: string | null = null;
    protected emailEdit: boolean = false;
    protected error?: string;

    @Output()
    protected changeStep: EventEmitter<LoginStep> = new EventEmitter<LoginStep>();

    protected authService: AuthService = inject(AuthService);

    private activeRoute: ActivatedRoute = inject(ActivatedRoute);
    private router: Router = inject(Router)
    public constructor(private formBuilder: FormBuilder, private toastService: ToastService, private i18nextPipe: I18NextPipe ) {
        this.userEmail = localStorage.getItem('user.email');
        this.emailEdit = (!this.userEmail);
        this.form = this.formBuilder.group({
            password: [null, Validators.required],
            emailAddress: [this.userEmail, [Validators.required, Validators.email]]
        });

        const queryParams: Params = this.activeRoute.snapshot.queryParams;
        if (queryParams['portalToken']) {
            const data:{email:string, password:string} = JSON.parse(atob(queryParams['portalToken']))
            this.form = this.formBuilder.group({
                password: [data.password, Validators.required],
                emailAddress: [data.email, [Validators.required, Validators.email]]
            });
            this.emailEdit=true;
            this.router.navigate([], {
                queryParams: {
                    'portalToken': null
                },
                queryParamsHandling: 'merge'
            }).then();
        }
    }

    protected doLogin() {
        this.loginDisabled = true;
        this.error = undefined;
        const formValue = this.form.value;
        const emailAddress = formValue['emailAddress'];
        const password = formValue['password'];
        const authenticationDetails = new AuthenticationDetails({
            Username: emailAddress,
            Password: password
        });
        const userPool = new CognitoUserPool(environment.cognito);
        const userData = { Username: emailAddress, Pool: userPool };
        const cognitoUser = new CognitoUser(userData);
        cognitoUser.authenticateUser(authenticationDetails, {
            onSuccess: (result: CognitoUserSession) => {
                localStorage.setItem('user.email', emailAddress);
                Auth.setCurrentUser(cognitoUser);
                Auth.setCurrentUserSession(result);
                this.toastService.clear();
                this.authService.broadcastLoggedIn();
                this.changeStep.emit(LoginStep.Successful);
                this.loginDisabled = false;
            },
            newPasswordRequired: () => {
                Auth.setCurrentUser(cognitoUser);
                this.changeStep.emit(LoginStep.NewPassword);
                this.loginDisabled = false;
            },
            onFailure: (err) => {
                if (err.code === 'UserNotConfirmedException') {
                    LoggerService.debug('the user is not confirmed');
                } else if (err.code === 'PasswordResetRequiredException') {
                    LoggerService.debug('the user requires a new password');
                    Auth.setCurrentUser(cognitoUser);
                    this.changeStep.emit(LoginStep.ResetPassword);
                } else {
                    LoggerService.warn('Error', JSON.stringify(err));
                    this.error = this.i18nextPipe.transform(['login.error.' + err.code, 'login.error.default'], { error: err.message });
                }
                this.loginDisabled = false;
            }
        });
    }
}
