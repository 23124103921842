<ng-template #content let-offcanvas data-cy="sim-detail">
  <div class="offcanvas-header pb-0">
    <h2 class="offcanvas-title" id="offcanvas-basic-title">
      <app-copy-to-clipboard [text]="sim?.iccid"></app-copy-to-clipboard>
    </h2>
    <button type="button" class="btn-close" aria-label="Close" (click)="offcanvas.dismiss('Cross click')"></button>
  </div>
  <div class="offcanvas-header py-1 border-bottom">
    <h6 class="title text-wsim-gray">
      <app-copy-to-clipboard [text]="sim?.custom_field_1"></app-copy-to-clipboard>
    </h6>
  </div>
  <div class="offcanvas-body">
    <app-sim-detail-status [sim]="sim"></app-sim-detail-status>

    <app-sim-detail-data-usage [sim]="sim"></app-sim-detail-data-usage>

    <app-sim-detail-event-table-devex [iccid]="sim?.iccid"></app-sim-detail-event-table-devex>
  </div>
</ng-template>
