import { Component, inject } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import i18next from 'i18next';
import { GraphqlService } from '../../../service/graphql.service';
import { ToastMessageBuilder, ToastService } from '../../../service/toast.service';

@Component({
    selector: 'app-send-sms',
    templateUrl: './send-sms.component.html',
    styleUrls: ['./send-sms.component.scss']
})
export class SendSmsComponent implements ICellRendererAngularComp {


    private params: ICellRendererParams | undefined;

    protected smsBody = '';
    protected sending = false;
    protected gsm7BitConform = true;

    // GSM 7-bit alphabet regex for validating the message to be sent
    private regexp = new RegExp("^[A-Za-z0-9 \\r\\n@£$¥èéùìòÇØøÅå\u0394_\u03A6\u0393\u039B\u03A9\u03A0\u03A8\u03A3\u0398\u039EÆæßÉ!\"#%&'()*+,\\-./:;<=>?¡ÄÖÑÜ§¿äöñüà^{}\\\\\\[~\\]|\u20AC]*$");

    private graphQlClient: GraphqlService = inject(GraphqlService);
    private toastService: ToastService = inject(ToastService);


    public agInit(params: ICellRendererParams): void {
        this.params = params;
    }

    public refresh(): boolean {
        return false;
    }

    public close() {
        this.params?.api.stopEditing(true);
    }

    protected sendSMS() {
        if (this.params?.node.id && !this.sending) {
            if (this.regexp.test(this.smsBody)) {
                this.sending = true;
                this.graphQlClient.sendSMS(this.params?.node.id, this.smsBody).finally(() => {
                    this.sending = false;
                    this.close();
                });
            } else {
                this.toastService.show(ToastMessageBuilder.error().text(i18next.t('send_sms.noGsmAlphabet')).build());
            }
        }
    }

    protected checkSmsBody () {
        this.gsm7BitConform = this.regexp.test(this.smsBody);
    }

    // please leave this here - otherwise focus lost will produce weird behaviour!
    public getValue() {
        return this.params?.value;
    }
}
