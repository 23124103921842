import { FilterDescriptor } from 'devextreme/data';

export class DevxFilterConverter {

    private readonly _hasTimestampConfigured: boolean = false;
    private readonly _startTimestamp: number = 0;
    private readonly _stopTimestamp: number = 0;

    private readonly _hasEventTypesConfigured: boolean = false;
    private readonly _eventTypes: string[] | undefined = undefined;


    // see: https://bitbucket.org/whereversim/lambda-datasource-sim-events/src/main/converter_internal.py
    // the sim-events datasource converts the portalInternal... to non-prefixed ones
    private eventInternalToApiMapping: Map<string, string> = new Map([
        ['portalInternalLifeCycle', 'lifecycle'],
        ['portalInternalUsageNotification', 'usageNotification'],
        ['portalInternalCancelLocation', 'cancelLocation'],
        ['portalInternalImeiLockSwitch', 'imeiLockSwitch'],
        ['portalInternalImeiUnauthorized', 'imeiUnauthorized']
    ]);


    public constructor(devxFilter: FilterDescriptor | Array<FilterDescriptor>) {
        if (devxFilter) {
            this._hasTimestampConfigured = devxFilter.indexOf('startTimestamp') > -1;
            if (this._hasTimestampConfigured) {
                this._startTimestamp = devxFilter[devxFilter.indexOf('startTimestamp') + 1];
                this._stopTimestamp = devxFilter[devxFilter.indexOf('stopTimestamp') + 1];
            }
            this._hasEventTypesConfigured = devxFilter.indexOf('eventTypes') > -1;
            if (this._hasEventTypesConfigured) {
                this._eventTypes = devxFilter[devxFilter.indexOf('eventTypes') + 1];
            }
        }
    }

    /**
     * Our API doesn't know the "portalInternal..." events, but the filter for getting the events knows (because it's not translated in
     * the SIM events datasource for some reason).
     *
     * So the following happens:
     * - filter defines e.g.: portalInternalImeiLockSwitch, portalInternalImeiUnauthorized (show me IMEI Lock events)
     * - this is ok as the filter we send to the API to filter events KNOWS these events (although it should only know the non-internals)
     * - e.g: a SIM Event comes with imeiLockSwitch - the filter normally doesn't know because it only knows the internal ones
     * So this method translates it for incoming subscription calls. We should consider changing this behavior - but not now.
     */
    public convertSelectedInternalEventTypesToApiEventTypes(): string[] {
        const apiEventTypes: string[] = [];
        if (this._hasEventTypesConfigured) {
            this._eventTypes?.forEach((eventType: string) => {
                if (apiEventTypes.indexOf(eventType) == -1) {
                    const apiType = this.eventInternalToApiMapping.get(eventType);
                    if (!apiType) {
                        apiEventTypes.push(eventType);
                    } else {
                        apiEventTypes.push(apiType);
                    }
                }
            });
        }
        return apiEventTypes;
    }


    public get hasTimestampConfigured(): boolean {
        return this._hasTimestampConfigured;
    }

    public get startTimestamp(): number {
        return this._startTimestamp;
    }

    public get stopTimestamp(): number {
        return this._stopTimestamp;
    }

    public get hasEventTypesConfigured(): boolean {
        return this._hasEventTypesConfigured;
    }

    public get eventTypes(): string[] | undefined {
        return this._eventTypes;
    }

}
