<div class="mt-2">
  <i [ngClass]="(passwordGuideLines.minimumLength.result)?'success':'failed'"
     class="fa-solid fa-check-circle me-1"
     data-cy="error-not-8-chars"></i>
  {{ 'changePassword.guidelines.minimum_length' | i18next: {length: environment.passwordRequirements.minLength} }}
</div>
<div>
  <i [ngClass]="(passwordGuideLines.atLeastOneNumber.result)?'success':'failed'"
     class="fa-solid fa-check-circle me-1"
     data-cy="error-not-a-number"></i>
  {{ 'changePassword.guidelines.at_least_a_number' | i18next }}
</div>
<div>
  <i [ngClass]="(passwordGuideLines.atLeastOneSmallLetter.result)?'success':'failed'"
     class="fa-solid fa-check-circle me-1"
     data-cy="error-not-lowercase-letter"></i>
  {{ 'changePassword.guidelines.at_least_a_lowercase_letter' | i18next }}
</div>
<div>
  <i [ngClass]="(passwordGuideLines.atLeastOneBigLetter.result)?'success':'failed'"
     class="fa-solid fa-check-circle me-1"
     data-cy="error-not-uppercase-letter"></i>
  {{ 'changePassword.guidelines.at_least_a_uppercase_letter' | i18next }}
</div>
<div>
  <i [ngClass]="(passwordGuideLines.atLeastOneSpecialChar.result)?'success':'failed'"
     class="fa-solid fa-check-circle me-1"
     data-cy="error-not-special-char"></i>
  <span [ngbTooltip]="specialChars">
  {{ 'changePassword.guidelines.at_least_a_special_chars_or_space_between' | i18next }}
  </span>
</div>
