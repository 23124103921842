import { Component, inject, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import DevExpress from 'devextreme';
import { DxChartComponent, DxRangeSelectorComponent } from 'devextreme-angular';
import { DataConsumptionFilterInput, Sim } from '../../../../../graphql/graphql.generated';
import { GraphqlService } from '../../../service/graphql.service';
import { LoggerService } from '../../../service/logger';
import VisualRange = DevExpress.common.charts.VisualRange;

class DataUsage {
    public day: Date;
    public bytes: number;

    public constructor(day: Date, bytes: number) {
        this.day = day;
        this.bytes = bytes;
    }
}

@Component({
    selector: 'app-sim-detail-data-usage',
    templateUrl: './sim-detail-data-usage.component.html',
    styleUrl: './sim-detail-data-usage.component.scss'
})
export class SimDetailDataUsageComponent implements OnChanges {

    @Input() public sim?: Sim;

    @ViewChild('chart', {static: false})
    protected chart: DxChartComponent | undefined;

    @ViewChild('rangeSelector', {static: false})
    protected rangeSelector: DxRangeSelectorComponent | undefined;

    private graphql = inject(GraphqlService);

    protected visualRange: VisualRange = {};
    protected startDate: Date;
    protected endDate: Date;
    protected selectedStartDate: Date;
    protected selectedEndDate: Date;

    protected dataUsage: DataUsage[] = [];

    protected readonly QUERY_DAYS_BACK = 3 * 31;
    protected readonly DEFAULT_VISIBLE_DAYS = 31;

    public constructor() {
        this.endDate = new Date();
        this.endDate.setHours(0, 0, 0, 0);
        this.startDate = new Date(this.endDate);
        this.startDate.setDate(this.startDate.getDate() - this.QUERY_DAYS_BACK);

        this.selectedEndDate = new Date(this.endDate);
        this.selectedStartDate = new Date(this.endDate);
        this.selectedStartDate.setDate(this.startDate.getDate() - this.DEFAULT_VISIBLE_DAYS);

        this.visualRange = {startValue: this.selectedStartDate, endValue: this.selectedEndDate};
    }

    public ngOnChanges(changes: SimpleChanges): void {
        this.loadData(changes['sim'].currentValue.iccid);
    }

    private loadData(iccid: string) {
        const filter: DataConsumptionFilterInput = {
            start: this.startDate.toISOString().substring(0, 10),
            stop: this.endDate.toISOString().substring(0, 10)
        };

        this.graphql.getDataConsumptionReport(iccid, filter).then((response) => {
            LoggerService.debug('Got DataConsumption events: ', response.data.getDataConsumptionReport);
            return response.data.getDataConsumptionReport;
        }).then((data) => {
            if (data && data.length > 0) {
                this.dataUsage = data.filter((e) => e !== undefined)
                    .map((element) => {
                        return new DataUsage(new Date(element!.day), element!.bytes);
                    });
            } else {
                this.dataUsage = [new DataUsage(this.startDate, 0), new DataUsage(this.endDate, 0)];
            }
            this.visualRange = {startValue: this.selectedStartDate, endValue: this.selectedEndDate};
        });
    }
}
