import { CognitoUser, CognitoUserSession, NodeCallback } from 'amazon-cognito-identity-js';
import i18next from 'i18next';
import { DateTime } from 'luxon';
import { Subject } from 'rxjs';
import permissionData from 'src/permissions.json';
import { LoggerService } from '../logger';

export enum Permission {
    SIM_WRITE_STATUS = 'sim.write.status',
    SIM_WRITE_CUSTOMFIELD1 = 'sim.write.customfield1',
    SIM_WRITE_DATALIMIT = 'sim.write.datalimit',
    SIM_WRITE_SMSLIMIT = 'sim.write.smslimit',
    SIM_WRITE_VOICELIMIT = 'sim.write.voicelimit',
    SIM_WRITE_END_CUSTOMER_LABEL = 'sim.write.endcustomerlabel',
    SIM_EXECUTE_SENDSMS = 'sim.execute.sendsms',
    SIM_EXECUTE_CANCELLOCATION = 'sim.execute.cancellocation',
    CUSTOMER_READ_USAGENOTIFICATIONS = 'customer.read.usagenotifications',
    CUSTOMER_WRITE_USAGENOTIFICATIONS = 'customer.write.usagenotifications',
    CUSTOMER_READ_ACCOUNTS = 'customer.read.accounts',
    CUSTOMER_WRITE_ACCOUNTS = 'customer.write.accounts'
}

/**
 * Helper class which is used for authentication and can be used as a singleton instance
 * in the code. This is "adopted" from Auth from AWS Amplify.
 */
export class WSimAuth {

    private readonly SUPER_USER_GROUPS: string[] = ['ADMIN', 'WSIM-Super-Admin'];
    private readonly PERMISSIONS: { [key: string]: string[] };

    private userTimezone = 'UTC';  // default user timezone
    private currentUser: CognitoUser | null = null;
    private currentUserSession: CognitoUserSession | null = null;
    private customerId?: number;
    private userEmail?: string;
    private adminUser: boolean = false;
    private currentUserPermissionsList: Set<string> = new Set<string>();
    private tokenRefreshed: Subject<CognitoUserSession> = new Subject<CognitoUserSession>();
    private tokenRefreshTimer?: NodeJS.Timeout;
    private cognitoUserGroups: string[] = [];

    public constructor() {
        this.PERMISSIONS = permissionData;
    }

    public setCurrentUser(currentUser: CognitoUser | null): void {
        LoggerService.debug('setCurrentUser', currentUser);
        this.currentUser = currentUser;
    }

    public getCurrentUser(): CognitoUser | null {
        return this.currentUser;
    }

    public getUserTimeZone(): string {
        return this.userTimezone;
    }

    public getTokenRefreshedSubscription() {
        return this.tokenRefreshed.asObservable();
    }

    public getcurrentUserPermissionsList(): Set<string> {
        return this.currentUserPermissionsList;
    }

    public setCurrentUserSession(currentUserSession: CognitoUserSession | null) {
        if (this.currentUserSession === currentUserSession) {
            return;
        }
        this.currentUserSession = currentUserSession;

        if (currentUserSession) {
            LoggerService.debug('setCurrentUserSession: ', currentUserSession.getIdToken().payload);

            this.scheduleRenewOfTokens(currentUserSession);

            const lang = currentUserSession.getIdToken().payload['locale'] || 'en';
            if (i18next.language !== lang) {
                LoggerService.debug('Changing user language to: ', lang);
                i18next.changeLanguage(lang).then();
            }
            this.cognitoUserGroups = currentUserSession.getIdToken().payload['cognito:groups'] || [];
            this.adminUser = this.cognitoUserGroups.some(value => this.SUPER_USER_GROUPS.includes(value));
            this.customerId = Number(currentUserSession.getIdToken().payload['custom:customerid']);
            this.userEmail = currentUserSession.getIdToken().payload['email'];
            this.userTimezone = currentUserSession.getIdToken().payload['zoneinfo'] || 'UTC';
            this.setPermissionsList();
            this.tokenRefreshed.next(currentUserSession);
        }
    }

    private scheduleRenewOfTokens(currentUserSession: CognitoUserSession) {
        const tokenExpire = DateTime.fromSeconds(currentUserSession.getIdToken().getExpiration());
        const delay = tokenExpire.minus({ minutes: 1 }).diff(DateTime.now());

        if (this.tokenRefreshTimer) {
            clearTimeout(this.tokenRefreshTimer);
        }

        this.tokenRefreshTimer = setTimeout(() => {
            this.refreshSession((_, session) => {
                LoggerService.info('Session refreshed!', session);
                if (session) {
                    this.setCurrentUserSession(session);
                }
            });
        }, delay.toMillis());
        LoggerService.info('Schedule token refresh in:', delay.toObject());
    }

    /**
     * Refreshes local session, if the user is logged in
     * @param callback the callback which is executed as soon as the session is refreshed (or not!) - first argument error, second result
     */
    public refreshSession(callback: NodeCallback<unknown, CognitoUserSession>) {
        if (this.currentUser && this.currentUserSession) {
            this.currentUser.refreshSession(this.currentUserSession.getRefreshToken(), callback);
        } else {
            LoggerService.warn('Unable to refresh session. Either the current user is not set or the session is not set!');
            callback.call(true, false);
        }
    }

    /**
     * Returns the current user session. Can be NULL when not initialized yet
     */
    public getCurrentUserSession(): CognitoUserSession | null {
        return this.currentUserSession;
    }

    /**
     * Clears all data
     */
    public clear(): void {
        this.currentUser = null;
        this.currentUserSession = null;
        this.userEmail = undefined;
        this.adminUser = false;
        this.currentUserPermissionsList.clear();
        this.userEmail = undefined;
        this.customerId = undefined;
    }

    public isAdmin(): boolean {
        return this.adminUser;
    }

    public getCurrentCustomerId(): number | undefined {
        return this.customerId;
    }

    public getCurrentEmail(): string | undefined {
        return this.userEmail;
    }

    private setPermissionsList() {
        const permissionsFound: Set<string> = new Set<string>();
        this.cognitoUserGroups.forEach((group: string): void => {
            if (this.PERMISSIONS[group]) {
                this.PERMISSIONS[group].forEach((permission: string): void => {
                    permissionsFound.add(permission);
                });
            }
        });
        this.currentUserPermissionsList = permissionsFound;
    }

    public getPermissionsKeys():Array<string>{
        return Object.keys(this.PERMISSIONS)
    }

}

export const Auth = new WSimAuth();
