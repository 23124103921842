import { Component, inject, OnDestroy, ViewChild } from '@angular/core';
import { I18NextPipe } from 'angular-i18next';
import i18next from 'i18next';
import { DxDataGridComponent } from 'devextreme-angular';
import { LoadOptions } from 'devextreme/data';
import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';
import { BillingData } from '../../../../graphql/graphql.generated';
import { Auth } from '../../service/auth/WSimAuth';
import { GraphqlService } from '../../service/graphql.service';
import { ToastMessageBuilder, ToastService } from '../../service/toast.service';
import { StatusConstants } from '../../shared/constants';

interface ExportBillingDataTableItem {
    id: string,
    year: number,
    month: string,
    dataVolume: number,
    invoiceAmount: string,
    originalData: BillingData
}

@Component({
    selector: 'app-export-billing-data',
    templateUrl: './export-billing-data.component.html',
    styleUrl: './export-billing-data.component.scss'
})
export class ExportBillingDataComponent implements OnDestroy {

    @ViewChild('dataGrid', { static: false }) protected userGrid: DxDataGridComponent | undefined;

    private PAGE_SIZE = 30;

    private graphql: GraphqlService = inject(GraphqlService);
    private toastService: ToastService = inject(ToastService);
    private nextToken?: string;

    protected readonly i18next = i18next;

    private readonly store: CustomStore;

    protected i18nextPipe: I18NextPipe = inject(I18NextPipe);
    protected dataSource: DataSource;

    protected readonly StatusConstants = StatusConstants;
    protected readonly Auth = Auth;
    protected readonly Object = Object;
    protected isDownloading = false;

    protected DEFAULT_DOWNLOAD_BUTTON = 'fa-solid fa-download p btn p-0';
    protected DOWNLOAD_IN_PROGRESS_BUTTON = 'fa-solid fa-circle-notch fa-spin p btn p-0';

    public constructor() {
        this.store = new CustomStore({
            load: async (loadOptions: LoadOptions) => {
                // Reset nextToken if reading from beginning
                if (loadOptions.skip === 0 && this.nextToken) this.nextToken = undefined;
                // it is possible that loadOptions.take is different from our set PAGE_SIZE ... but loadOptions is taken into
                // account from the component when continue loading data.
                const limit = loadOptions.take || this.PAGE_SIZE;

                return this.graphql.listBillingData(limit, this.nextToken).then((response) => {
                    let tableData;
                    if (response.data && response.data.listBillingData) {
                        this.nextToken = response.data.listBillingData.nextToken || undefined;
                        tableData = {
                            data: response.data?.listBillingData?.items || []
                        };
                    } else {
                        tableData = {
                            data: []
                        };
                        if (response.errors) {
                            this.showError(response.errors[0].message, 'billingData.list.error');
                        }
                    }
                    return tableData;
                }).catch((error) => {
                    this.showError(error.message, 'billingData.list.error');
                    return {
                        data: []
                    };
                });
            }
        });

        this.dataSource = new DataSource({
            store: this.store,
            reshapeOnPush: true,
            pageSize: this.PAGE_SIZE,
            map: (item) => this.convertToTableItem(item)
        });
    }


    public ngOnDestroy(): void {
        this.dataSource.dispose();
        this.nextToken = undefined;
    }

    private setDownloading(downloading: boolean, sourceElement: HTMLElement) {
        if (downloading) {
            sourceElement.className = this.DOWNLOAD_IN_PROGRESS_BUTTON;
        } else {
            sourceElement.className = this.DEFAULT_DOWNLOAD_BUTTON;
        }
        this.isDownloading = downloading;
    }

    protected downloadBillingData(data: ExportBillingDataTableItem) {
        if (this.isDownloading) {
            return;
        }
        const downloadButtonHtml = document.getElementById('download-billing-data-' + data.id)!;
        this.setDownloading(true, downloadButtonHtml);
        this.graphql.getBillingDataDownloadLink(data.originalData.month, data.originalData.year).then((response) => {
            if (response.data) {
                // this is a little "hacky" but seems to be the official way to force a download
                // we can also use "HttpClient" but then we would run into CORS - which then needs the file
                // on the server side to have an allow-origin header! I've searched for multiple ways, but this
                // seems to be the best one
                const link = document.createElement('a');
                link.setAttribute('target', '_blank');
                link.setAttribute('href', response.data.getBillingDataDownloadLink.link);
                link.setAttribute('download', `billing_data_${data.month}_${data.year}.csv`);
                document.body.appendChild(link);
                link.click();
                link.remove();
            } else {
                if (response.errors) {
                    this.showError(response.errors[0]?.message, 'billingData.download.error');
                }
            }
            this.setDownloading(false, downloadButtonHtml);
        }).catch((error) => {
            this.showError(error.message, 'billingData.download.error');
            this.setDownloading(false, downloadButtonHtml);
        });
    }

    private convertToTableItem(graphQlItem: BillingData): ExportBillingDataTableItem {
        const date = new Date(`${graphQlItem.year}-${graphQlItem.month}`);
        return {
            year: date.getFullYear(),
            month: date.toLocaleString(this.i18next.language, { month: 'long' }),
            dataVolume: graphQlItem.data_volume,
            invoiceAmount: i18next.t('amountWithCurrency', { val: graphQlItem.invoice_amount, currency: graphQlItem.currency }),
            originalData: graphQlItem,
            id: `${graphQlItem.month}-${graphQlItem.year}`
        };
    }

    private showError(errorText: string, translationKey: string) {
        const text: string = this.i18nextPipe.transform(translationKey, { error: errorText });
        this.toastService.show(ToastMessageBuilder.error().text(text).build());
    }

}
