/**
 * This is more or less a state machine, describing the transitions available between the different status of SIM(s) based
 * on transitions allowed per provider!
 */
import { Injectable } from '@angular/core';
import { GraphqlService } from './graphql.service';
import { LoggerService } from './logger';
import { AuthService } from "./auth/auth.service";


@Injectable({
    providedIn: 'root'
})
export class StatusTransitionsService {

    private data: Promise<Map<string, number[]>>;

    public constructor(private graphQlService: GraphqlService, private authService: AuthService) {
        this.data = this.authService.getLoggedInPromise().then(() => this.graphQlService.getAvailableStatusTransitions())
            .then((value) => {
                const result = new Map<string, number[]>();
                value.data.getAvailableStatusTransitions.forEach((transition) => {
                    if (transition) {
                        const key = transition.providerid + '_' + transition.fromstatusid;
                        let transitionsAvailable = result.get(key);
                        if (!transitionsAvailable) {
                            transitionsAvailable = [];
                            result.set(key, transitionsAvailable);
                        }
                        transitionsAvailable.push(transition.tostatusid);
                    }
                });
                LoggerService.debug('StatusTransitionsService: ready. Cached transitions available: ', result);
                return result;
            });
    }

    public getAvailableTransitions(providerId: number, statusId: number):
        Promise<number[] | undefined> {
        return new Promise((resolve) => {
            this.data.then((data) => {
                resolve(data.get(providerId + '_' + statusId));
            })
        })
    }
}
