<div [style.width]="cellWidth" class="container-fluid p-0">
  <div class="d-flex justify-content-end w-100 g-0 p-0 ps-1">
    <div class="d-inline-flex align-items-center p-1 input-container">
      <label class="visually-hidden col-form-label col-form-label-sm"
             for="inlineFormInputGroupLimitVoiceMo">Limit</label>
      <div class="input-group input-group-sm align-items-center">
        <input #limitInputVoiceMo (keydown)="onKeyDown($event)"
               [(ngModel)]="unsavedVoiceMoLimit" class="form-control form-control-sm number-input"
               digitOnly id="inlineFormInputGroupLimitVoiceMo" required type="text">
        <i class="fa-solid fa-circle-xmark input-container-icon" (click)="clearInput()"></i>
      </div>
    </div>
    <div class="d-inline-flex align-items-center p-1">
      {{ 'minutes.abbreviation' | i18nextCap }}
    </div>
    <div class="d-inline-flex align-items-center pe-1">
      <a (click)="submitSave($event)" data-cy="voice-limit-save-button"
         class="btn btn-sm btn-primary right-rounded-corners">
        <i *ngIf="!saving" class="fa-solid fa-check"></i>
        <i *ngIf="saving" class="fa-solid fa-solid fa-circle-notch fa-spin"></i>
      </a>
    </div>
  </div>
</div>

