import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../service/auth/auth.service';
import { LoggerService } from '../../service/logger';
import { ErrorMessage } from '../../shared/error-message';

export enum LoginStep {Login, ResetPassword, NewPassword, Successful}

@Component({
    selector: 'app-sign-in',
    templateUrl: './sign-in.component.html',
    styleUrls: ['./sign-in.component.scss']
})
export class SignInComponent implements OnInit {

    protected step: LoginStep = LoginStep.Login;
    protected readonly LoginStep = LoginStep;

    protected error: ErrorMessage | undefined;

    public constructor(private router: Router, private authService: AuthService, private route: ActivatedRoute) {
    }

    public ngOnInit(): void {
        // additional check if the user is already logged in
        if (this.authService.isLoggedIn()) {
            this.routeUser();
        }
    }

    public showError(error: ErrorMessage | undefined) {
        this.error = error;
    }

    public changeStep(nextStep: LoginStep) {
        this.step = nextStep;

        if (this.step === LoginStep.Successful) {
            this.routeUser();
        }
    }

    private routeUser() {
        const redirect = this.route.snapshot.queryParams['redirect'];
        if (redirect) {
            LoggerService.info('Redirecting to: %s', redirect);
            this.router.navigateByUrl(redirect).then();
        } else {
            this.router.navigate(['']).then();
        }
    }

}
