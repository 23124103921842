<nav class="navbar fixed-top navbar-expand-md bg-light">
  <div class="container-fluid">
    <a class="navbar-brand" routerLink="/">
      <img [attr.src]="'assets/whereverSIM-Logo.svg' | fileHash" alt="wherever SIM" height="40">
    </a>

    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav me-auto">
        <li *ngIf="isLoggedIn && showSimListNavigation" class="nav-item order-0">
          <a class="nav-link" aria-current="page" routerLink="/sim-list" routerLinkActive="active"
             data-cy="navigation-sim-list">
            <i class="fa-solid fa-sim-card"></i> {{ 'sim cards' | i18next }}</a>
        </li>
      </ul>
      <ul class="navbar-nav ms-auto">
        <li *ngIf="!isLoggedIn" class="nav-item dropdown">
          <a class="nav-link dropdown-bs-toggle text-secondary" role="button" data-bs-toggle="dropdown"
             aria-expanded="false">
            {{ 'lang.' + i18next.language | i18next }}
            <i class="fa-solid fa-chevron-down"></i>
          </a>
          <ul class="dropdown-menu dropdown-menu-end">
            <ng-container *ngTemplateOutlet="langlist"></ng-container>
          </ul>
        </li>

        <span *ngIf="isLoggedIn && (customerData | async)?.testCustomer"
              class="navbar-text fw-light">{{ 'test account' | i18next }}</span>

        <li *ngIf="isLoggedIn" class="nav-item dropdown" data-cy="dropdown-main-menu">
          <a class="nav-link dropdown-bs-toggle text-secondary" role="button" data-bs-toggle="dropdown"
             aria-expanded="false">
            {{ userEmail }}
            <i class="fa-solid fa-chevron-down"></i>
          </a>
          <ul class="dropdown-menu dropdown-menu-end">

            <!-- settings headline -->
            <li class="dropdown-header fw-bold text-dark">{{ 'button.settings' | i18next }}</li>
            <!-- notifications -->
            <a class="dropdown-item my-1 me-4" routerLink="/settings/notifications" data-cy="dropdown-main-item-notifications"
               *hasPermission="[Permission.CUSTOMER_READ_USAGENOTIFICATIONS,Permission.CUSTOMER_WRITE_USAGENOTIFICATIONS]">
              <div class="row">
                <div class="col-2 p-0 text-center"><i class="fa-solid fa-bell"></i></div>
                <div clasS="col-10">{{ 'button.notifications' | i18next }}</div>
              </div>
            </a>
            <!-- user management -->
            <a class="dropdown-item my-1 me-4" routerLink="/settings/usermanagement" data-cy="dropdown-main-item-user-management"
               *hasPermission="[Permission.CUSTOMER_READ_ACCOUNTS]">
              <div class="row">
                <div class="col-2 p-0 text-center"><i class="fa-solid fa-users"></i></div>
                <div class="col-10">{{ 'button.usermanagement' | i18next }}</div>
              </div>
            </a>
            <!-- change password -->
            <a class="dropdown-item my-1 me-4" data-toggle="collapse" data-target=".navbar-collapse.show"
               data-cy="dropdown-main-item-change-password" routerLink="/change-pass">
              <div class="row">
                <div class="col-2 p-0 text-center"><i class="fa-solid fa-lock"></i></div>
                <div class="col-10">{{ 'changePassword.menuItem' | i18next }}</div>
              </div>
            </a>
            <li class="dropdown-divider mx-1"></li>

            <!-- billing data headline -->
            <div *ngIf="environment.showBillingDataExport">
              <div *hasPermission="[Permission.CUSTOMER_READ_BILLING_DATA_EXPORT]">
                <li class="dropdown-header fw-bold text-dark">{{ 'billingData.menu.header' | i18next }}</li>
                <!-- export -->
                <a class="dropdown-item my-1 me-4" routerLink="/billing-data/billing-data-export" data-cy="dropdown-main-item-billing-data-export">
                  <div class="row">
                    <div class="col-2 p-0 text-center"><i class="fa-solid fa-chart-column"></i></div>
                    <div clasS="col-10">{{ 'billingData.menu.export' | i18next }}</div>
                  </div>
                </a>
                <li class="dropdown-divider mx-1"></li>
              </div>
            </div>

            <!-- change language section -->
            <li class="dropdown-header fw-bold text-dark">{{ 'language' | i18next }}</li>
            <ng-container *ngTemplateOutlet="langlist"></ng-container>
            <li class="dropdown-divider mb-1 mt-2 mx-1"></li>
            <!-- setting routerLink="null" prevents using routing to / - which leads to /sim-list -->
            <a (click)="logout()" routerLink="null" class="dropdown-item mt-2 me-4" data-cy="dropdown-main-item-logout">
              <div class="row">
                <div class="col-2 p-0 text-center"><i class="fa-solid fa-right-from-bracket"></i></div>
                <div class="col-10">{{ 'button.logout' | i18next }}</div>
              </div>
            </a>
          </ul>
        </li>
      </ul>
    </div>
  </div>
</nav>

<router-outlet></router-outlet>

<app-toasts aria-live="polite" aria-atomic="true"></app-toasts>
<ng-template #langlist>
  <li *ngFor="let lang of LANGS" class="mx-1">
    <button (click)="switchLang(lang)" [class]="(i18next.language === lang)?'active':''" class="dropdown-item my-1"
            data-toggle="collapse" data-cy="dropdown-main-item-change-language" data-target=".navbar-collapse.show">
      {{ 'lang.' + lang | i18next }}
    </button>
  </li>
</ng-template>
