import { Component, ElementRef, ViewChild } from '@angular/core';
import { ICellEditorAngularComp } from 'ag-grid-angular';
import { ICellEditorParams } from 'ag-grid-community';
import { I18NextPipe } from 'angular-i18next';
import { GraphqlService } from '../../../service/graphql.service';
import { ToastMessageBuilder, ToastService } from '../../../service/toast.service';
import { ProviderFeatures } from '../../provider-features';

@Component({
    selector: 'app-sms-limit-edit',
    templateUrl: './sms-limit-edit.component.html',
    styleUrl: './sms-limit-edit.component.scss'
})
export class SmsLimitEditComponent implements ICellEditorAngularComp {

    @ViewChild('limitInputSmsMo')
    public input: ElementRef | undefined;

    private iccId: string | undefined;
    protected saving = false;
    protected unsavedSmsMoLimit?: string;
    protected unsavedSmsMtLimit?: string;
    private params: ICellEditorParams | undefined;
    protected cellWidth: string | undefined;
    protected showMtValues: boolean = false;
    private saveSuccessful = false;


    public constructor(private graphQlService: GraphqlService,
                       private toastService: ToastService, private i18Next: I18NextPipe) {
        // the following part resizes this components width according to the size of the column itself
        // this is only done when resizing the grid is final!
    }

    public agInit(params: ICellEditorParams): void {
        const data = params.data;
        this.iccId = data.iccid;
        this.params = params;
        this.saveSuccessful = false;
        this.showMtValues = ProviderFeatures.hasMtSmsNumbers(data.providerid);
        if (!this.unsavedSmsMoLimit) {
            this.unsavedSmsMoLimit = data.monthly_sms_mo_limit;
        }
        if (!this.unsavedSmsMtLimit) {
            this.unsavedSmsMtLimit = data.monthly_sms_mt_limit;
        }
        this.cellWidth = params.column.getActualWidth() + 'px';
    }

    /**
     * On Key down in the input field
     * @param $event
     */
    protected onKeyDown($event: KeyboardEvent) {
        if ($event.key === 'Escape') {
            this.close();
        } else if ($event.key === 'Enter') {
            $event.stopPropagation();
            this.save();
        } else if ($event.code.startsWith('Arrow')) {
            // Allow cursor movement
            $event.stopPropagation();
        } else if ($event.metaKey && $event.code === 'KeyA') {
            // Select All
            $event.stopPropagation();
        }
    }

    protected submitSave($event: MouseEvent) {
        if (!this.saving) {
            $event.stopPropagation();
            this.save();
        }
    }

    /**
     * Stores the data currently configured in this view
     */
    protected save() {
        if (!this.saving && this.iccId) {
            this.saving = true;
            // we need to recalculate the current datasize back to bytes as we only stores bytes!
            const newSmsMoLimit = Number(this.unsavedSmsMoLimit || 0);
            const newSmsMtLimit = Number(this.unsavedSmsMtLimit || 0);
            this.graphQlService.updateSim(this.iccId, undefined, undefined, undefined, newSmsMoLimit, newSmsMtLimit).then((result) => {
                if (result.data) {
                    this.saveSuccessful = true;
                    this.saving = false;
                    this.close();
                }
            }).catch((error) => {
                this.unsavedSmsMtLimit = undefined;
                this.unsavedSmsMoLimit = undefined;
                // show an error message!
                this.toastService.show(ToastMessageBuilder.error().text(this.i18Next.transform('error.smsLimitUpdate', { errorMsg: error.message })).build());
                this.saving = false;
                this.close();
            });
        }
    }

    public close() {
        this.params?.stopEditing(true);
    }

    public getValue() {
        if (this.saveSuccessful) {
            return {
                'monthly_sms_mo_limit': this.unsavedSmsMoLimit,
                'monthly_sms_mt_limit': this.unsavedSmsMtLimit
            };
        }
        return undefined;
    }

    public afterGuiAttached() {
        // force to focus the MO input field when gui is shown!
        this.input?.nativeElement.focus();
    }

}
