<div class="row pt-2">
  <div class="col">
    <h4 class="text-secondary">{{ 'data usage' | i18nextCap }}</h4>
  </div>
</div>

<dx-chart #chart
          [size]="{ height: 200 }"
          [dataSource]="dataUsage"
          [legend]="{ visible: false }"
>

  <dxi-series
    type="bar"
    argumentField="day"
    valueField="bytes"
    axis="datausage"
  >
  </dxi-series>

  <dxo-tooltip
    [enabled]="true"
    contentTemplate="content"
    location="edge"
  >
  </dxo-tooltip>
  <div *dxTemplate="let info of 'content'" class="state-tooltip">
    <div class="row">
      <span class="fw-bold">
        {{ 'formats:dateShort' | i18next: {date: info.originalArgument} }}
      </span>
    </div>
    <div class="row">
      <span class="text-end">
        {{ info.originalValue | byteFormat }}
      </span>
    </div>
  </div>

  <dxi-value-axis name="datausage">
  </dxi-value-axis>
  <dxo-argument-axis [visualRange]="visualRange"></dxo-argument-axis>
</dx-chart>

<dx-range-selector #rangeSelector
                   [dataSource]="dataUsage"
                   [(value)]="visualRange"
>
  <dxo-size [height]="130"></dxo-size>
  <dxo-margin [left]="10"></dxo-margin>
  <dxo-scale minorTickInterval="day"
             valueType="datetime"
             [startValue]="startDate"
             [endValue]="endDate"
             minRange="week"
  >
    <dxo-tick-interval [days]="2"></dxo-tick-interval>
  </dxo-scale>
  <dxo-behavior valueChangeMode="onHandleMove"></dxo-behavior>
  <dxo-chart>
    <dxi-series
      type="bar"
      argumentField="day"
      valueField="bytes"
    >
    </dxi-series>
  </dxo-chart>
  <dxo-slider-marker
    [format]="'shortDate'"
    [placeholderHeight]="30"
  >
  </dxo-slider-marker>
  <dxo-background color="var(--bs-wsim-gray-3)"></dxo-background>
</dx-range-selector>
