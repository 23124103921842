import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { EditableCallback, GridApi, ICellRendererParams } from 'ag-grid-community';
import { I18NextPipe } from 'angular-i18next';
import { Auth, Permission } from '../../../service/auth/WSimAuth';
import { GraphqlService } from '../../../service/graphql.service';
import { LoggerService } from '../../../service/logger';
import { StatusTransitionsService } from '../../../service/status-transitions.service';
import { ToastMessageBuilder, ToastService } from '../../../service/toast.service';
import { STATUS_ICON_MAPPING, StatusConstants } from '../../constants';


@Component({
    selector: 'app-sim-status',
    templateUrl: './sim-status.component.html',
    styleUrls: ['./sim-status.component.scss']
})
export class SimStatusComponent implements ICellRendererAngularComp {

    private readonly STATUS_FREE_TIER_EXHAUSTED = 101;
    private readonly PENDING_STATUS_IDS = [13, 14, 17];

    private gridApi!: GridApi;
    private rowData = undefined;

    protected isPendingStatus = false;
    protected statusId = 0;
    protected statusClass = '';

    protected availableTransitions: number[] | undefined;
    protected rowId: string | undefined;
    protected ableToChange: boolean | undefined | EditableCallback = false;

    public constructor(private transitionsService: StatusTransitionsService, private toastService: ToastService,
                       private graphQlClient: GraphqlService, private i18nextPipe: I18NextPipe) {
    }

    public agInit(params: ICellRendererParams): void {
        this.statusId = params.value;
        this.statusClass = STATUS_ICON_MAPPING[this.statusId];
        this.gridApi = params.api;
        this.rowData = params.data;
        if (this.rowData) {
            this.rowId = params.node.id;
        }
        this.setStatusPending();
        this.setAvailableTransitions();
        this.ableToChange = Auth.getcurrentUserPermissionsList().has(Permission.SIM_WRITE_STATUS);
    }

    public refresh(params: ICellRendererParams): boolean {
        this.statusId = params.value;
        this.statusClass = STATUS_ICON_MAPPING[this.statusId];
        this.setStatusPending();
        this.setAvailableTransitions();
        return true;
    }

    private setStatusPending() {
        if (this.rowData) {
            this.isPendingStatus = this.PENDING_STATUS_IDS.indexOf(this.statusId) > -1;
        }
    }

    private setAvailableTransitions() {
        if (this.rowData) {
            if (this.statusId !== this.STATUS_FREE_TIER_EXHAUSTED) {
                this.transitionsService.getAvailableTransitions(this.rowData['providerid'], this.statusId).then((result) => {
                    this.availableTransitions = result;
                });
            }
        }
    }

    public executeTransition(toStatusId: number) {
        LoggerService.debug('Execute Transition to ', toStatusId);
        if (this.rowData) {
            const oldStatusId = this.rowData['statusid'];
            this.graphQlClient.updateSim(this.rowData['iccid'], toStatusId, undefined, undefined)
                .then((data) => {
                    LoggerService.debug('executeTransition(): got as response: ', data);
                }).catch((error) => { // rollback in case of an error
                if (error) {
                    if (this.rowData) {
                        this.toastService.show(ToastMessageBuilder.error().text(this.i18nextPipe.transform('error.statusUpdate.description', {
                            iccId: this.rowData['iccid'],
                            errorMessage: error.errorMessage
                        })).build());
                    } else {
                        this.toastService.show(ToastMessageBuilder.error().text(error.errorMessage).build());
                    }
                    if (this.rowId) {
                        const rowNode = this.gridApi.getRowNode(this.rowId);
                        const dataCopy = JSON.parse(JSON.stringify(rowNode?.data));
                        this.statusId = oldStatusId;
                        dataCopy['statusid'] = oldStatusId;
                        rowNode?.setData(dataCopy);
                        this.setStatusPending();
                        this.setAvailableTransitions();
                    }
                }
            });
        }
    }

    protected readonly STATUS_ICON_MAPPING = STATUS_ICON_MAPPING;
    protected readonly StatusConstants = StatusConstants;
}
