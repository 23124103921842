<ng-template #content let-modal>
  <div class="modal-header" data-cy="modal-bulk-change-sim-data-limit-dialog">
    <h1 class="modal-title fs-5"
        id="bulkChangeSimDataLimitLabel">{{ 'bulkDataLimitChange.title' | i18next }}</h1>
    <button type="button" class="btn-close" aria-label="Close" [disabled]="inProgress" (click)="modal.close('X')">
    </button>
  </div>
  <div class="modal-body">
    <div class="mb-3">
      <div class="col">
        {{ 'bulkDataLimitChange.headline' | i18next: {count: selectedSimCards?.length} }}
      </div>
      <div class="input-group">
        <input (keydown)="onKeyDown($event)" [(ngModel)]="unsavedLimit" (ngModelChange)="evaluateTargets()"
               autofocus class="form-control number-input" id="inlineFormInputGroupLimit" required digitOnly
               data-cy="modal-bulk-change-sim-data-limit-input" type="text">
        <button class="btn btn-outline-secondary dropdown-toggle" data-bs-toggle="dropdown" type="button"
                data-cy="modal-bulk-change-sim-data-limit-dropdown-button">
          {{ currentDataUnit }}
        </button>
        <ul class="dropdown-menu">
          <li *ngFor="let size of availableDataUnits">
            <a (click)="changeDataUnit(size)" class="dropdown-item"
               data-cy="modal-bulk-change-sim-data-limit-dropdown-item"> {{ size }} </a>
          </li>
        </ul>
      </div>
      <div class="form-text">{{ 'bulkDataLimitChange.hint' | i18next }}</div>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <div class="alert alert-light" *ngIf="enabled>0"
           data-cy="modal-bulk-change-sim-data-limit-alert-sims-enabled">
        <a [routerLink]="[]" [queryParams]="getQueryParamFilter(enabledList)" target="_blank">
          {{ 'bulkDataLimitChange.enabled' | i18next: {count: enabled} }}
        </a>
      </div>
      <div class="alert alert-light" *ngIf="disabled>0"
           data-cy="modal-bulk-change-sim-data-limit-alert-sims-disabled">
        <a [routerLink]="[]" [queryParams]="getQueryParamFilter(disabledList)" target="_blank">
          {{ 'bulkDataLimitChange.disabled' | i18next: {count: disabled} }}
        </a>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="col float-start">
      <button type="button" class="btn btn-secondary" [disabled]="inProgress" (click)="modal.close('Cancel')"
              data-cy="modal-bulk-change-sim-data-limit-cancel-button">
        {{ 'shared.btn.cancel' | i18next }}
      </button>
    </div>
    <div class="col float-end text-end" [hidden]="saveEnabled">
      <button type="button" (click)="commitChange()" [disabled]="inProgress"
              class="btn btn-primary" data-cy="modal-bulk-change-sim-data-limit-save-button">
        {{ 'apply' | i18next }}
        <span *ngIf="inProgress" class="spinner-border spinner-border-sm" aria-hidden="true"></span>
      </button>
    </div>
  </div>
</ng-template>
