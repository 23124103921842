import { Location } from '@angular/common';
import { Component, EventEmitter, inject, OnDestroy, Output, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { NgbOffcanvasRef } from '@ng-bootstrap/ng-bootstrap/offcanvas/offcanvas-ref';
import { Sim } from '../../../../graphql/graphql.generated';
import { GraphqlService } from '../../service/graphql.service';

@Component({
    selector: 'app-sim-detail',
    templateUrl: './sim-detail.component.html',
    styleUrl: './sim-detail.component.scss'
})
export class SimDetailComponent implements OnDestroy {

    @ViewChild('content')
    private content: TemplateRef<never> | undefined;

    @Output()
    public changedSim = new EventEmitter<{ old: Sim | undefined, new: Sim | undefined }>();

    protected sim?: Sim = undefined;

    private openCanvas?: NgbOffcanvasRef;

    private location = inject(Location);
    private router = inject(Router);
    private route = inject(ActivatedRoute);
    private offcanvasService = inject(NgbOffcanvas);
    private graphql = inject(GraphqlService);

    private readonly updateSimSubscription;

    public constructor() {
        const iccid = this.route.snapshot.paramMap.get('iccid');
        if (iccid) {
            this.graphql.getSimList(2, undefined, { 'iccid': { 'eq': iccid } }, undefined).then(({ data }) => {
                if (data.listSims && data.listSims.items && data.listSims.items.length > 0 && data.listSims.items[0]) {
                    this.open(data.listSims.items[0]);
                }
            });
        }

        this.updateSimSubscription = this.graphql.subscribeUpdatedSim().subscribe((sim: Sim) => {
            if (this.sim?.iccid === sim.iccid) {
                this.sim = sim;
            }
        });
    }

    public ngOnDestroy(): void {
        if (this.openCanvas) {
            this.openCanvas.dismiss();
        }
        if (this.updateSimSubscription) {
            this.updateSimSubscription.unsubscribe();
        }
    }

    public open(sim: Sim) {
        const oldSim = this.sim;
        this.sim = sim;
        let sidePanelCreated = false;
        if (this.openCanvas === undefined) {
            this.openCanvas = this.offcanvasService.open(this.content, {
                animation: true,
                backdrop: false,
                position: 'end',
                panelClass: 'w-50'
            });
            sidePanelCreated = true;
            this.openCanvas.result.finally(() => {
                // Remove ICCID from URL without reload
                const oldSim = this.sim;
                this.openCanvas = undefined;
                this.updateUrl(false);
                this.changedSim.emit({ old: oldSim, new: undefined });
            });
        }
        if (sidePanelCreated || (!oldSim || (oldSim && oldSim.iccid !== this.sim.iccid))) {
            // Add ICC ID to URL without reload
            this.updateUrl(true);
        }
        this.changedSim.emit({ old: oldSim, new: sim });
    }

    public currentSim() {
        return this.sim;
    }

    private updateUrl(withIccid: boolean) {
        // with router.navigate the component (sim-list) is refreshed... with this method we prevent this
        const commands = ['/sim-list'];
        if (withIccid && this.sim) {
            commands.push(this.sim.iccid);
        }

        // delete filter in URL when changing the ICC ID
        const params = { ...this.route.snapshot.queryParams };
        delete params['detailsEventTypesFilter'];
        delete params['detailsTimestampFilter'];

        const url = this.router.createUrlTree(commands, { queryParams: params }).toString();
        this.location.go(url);
    }
}
