import { AfterViewInit, Directive, ElementRef } from '@angular/core';

@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: '[autofocus]'
})
export class AutofocusDirective implements AfterViewInit {

    public constructor(private host: ElementRef) {
    }

    public ngAfterViewInit() {
        this.host.nativeElement.focus();
    }

}
