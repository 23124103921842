<div class="d-flex flex-column justify-content-evenly">
  <!-- no pending status, the status is "real" -->
  <div *ngIf="statusId > 0 && !isPendingStatus">
    <!-- variant 2 -->
    <div *ngIf="availableTransitions && availableTransitions.length > 0" class="dropdown position-static">
      <button [ngClass]="statusClass" aria-expanded="false"
              class="btn fw-semibold col-12"
              [class]="ableToChange ? 'dropdown-toggle' : 'disabled-dropdown'"
              data-bs-toggle="dropdown" type="button">
        {{ 'status.' + statusId | i18next }}
      </button>
      <div class="dropdown-menu">
        <a (click)="executeTransition(statusId)" *ngFor="let statusId of availableTransitions"
           class="dropdown-item">
          <button [ngClass]="STATUS_ICON_MAPPING[statusId]" class="btn fw-semibold pe-none ms-1"
                  type="button">
            {{ 'status.' + statusId | i18next }}
          </button>
        </a>
      </div>
    </div>

    <button [id]="'button' + rowId" *ngIf="!availableTransitions || availableTransitions.length === 0"
            [ngClass]="statusClass" aria-expanded="false" class="btn fw-semibold mb-1 col-12 disable-hover"
            type="button">
      {{ 'status.' + statusId | i18next }}

      <app-status-popover *ngIf="statusId === StatusConstants.SMS_LIMIT_EXCEEDED"
                          [statusId]="statusId" [componentId]="'button' + rowId" position="left">
      </app-status-popover>

    </button>

  </div>

  <!-- we have a pending status, display it in another view -->
  <div *ngIf="statusId > 0 && isPendingStatus">
    <button aria-expanded="false" class="btn fw-semibold mb-1 button-status-pending col-12 disable-hover"
            type="button">
      {{ 'status.' + statusId | i18next }}
    </button>
  </div>
</div>


