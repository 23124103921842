import { Component, EventEmitter, inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { I18NextPipe } from 'angular-i18next';
import { DxDataGridComponent, DxPopoverComponent } from 'devextreme-angular';

interface DetailModel {
    id: number;
    text: string;
    value: string[];
    checked: boolean;
}

@Component({
    selector: 'app-details-header',
    templateUrl: './details-header.component.html',
    styleUrl: './details-header.component.scss'
})
export class DetailsHeaderComponent implements OnInit {

    public static readonly FILTER_NAME = 'detailsEventTypesFilter';

    protected i18next = inject(I18NextPipe);
    protected router = inject(Router);
    protected route = inject(ActivatedRoute);

    protected filterActive = false;

    @Input({required: true})
    public dataGrid!: DxDataGridComponent;

    @Output()
    public filterChanged: EventEmitter<string> = new EventEmitter();

    @ViewChild('popover', {static: false})
    protected popover?: DxPopoverComponent;

    // I don't like that - because we have already defined the wsim blue light color - but it isn't simply accessible in typescript
    private filterSelectedColor = '#edf4fa'; // wsim-blue-light
    private defaultColor = '#e9e9ef'; // wsim-gray-2

    protected filterModel: DetailModel[] = [
        {
            id: 1,
            text: this.i18next.transform('eventFilter.consumption'),
            value: ['consumption'],
            checked: true
        }, {
            id: 2,
            text: this.i18next.transform('eventFilter.presence'),
            value: ['presence', 'portalInternalCancelLocation'],
            checked: true
        }, {
            id: 3,
            text: this.i18next.transform('eventFilter.usageNotification'),
            value: ['portalInternalUsageNotification'],
            checked: true
        }, {
            id: 4,
            text: this.i18next.transform('eventFilter.lifeCycle'),
            value: ['lifeCycle', 'portalInternalLifeCycle'],
            checked: true
        }];


    protected openFilter($event: MouseEvent) {
        $event.stopPropagation();
        this.popover?.instance.toggle(true);
    }

    public deleteFilter(updateUrl: boolean) {
        this.filterModel.forEach((item) => {
            item.checked = true;
        });
        // reset filter by setting empty array
        this.removeDataGridFilter();
        this.filterActive = false;
        this.getColumnHeaderHtmlElement().style.backgroundColor = this.defaultColor;
        this.popover?.instance.toggle(false);
        if (updateUrl) {
            this.storeFilterToUrl();
        }
    }

    public optionChanged(event: Event | undefined) {
        this.applySelection(event);
    }

    private applySelection(event: Event | undefined) {
        const eventTypes: string[] = [];
        let countChecked = 0;
        this.filterModel.forEach((item: DetailModel) => {
            if (item.checked) {
                eventTypes.push(...item.value);
                countChecked++;
            }
        });
        // when at least one is checked and not all, we need to set the filter. if not, we can remove the filter (show all or none)
        if (countChecked > 0 && countChecked != this.filterModel.length) {
            let currentFilter = this.dataGrid.instance.getCombinedFilter();
            const newFilterValue = ['eventTypes', eventTypes];
            if (currentFilter) {
                if (currentFilter.indexOf('eventTypes') > -1) {
                    currentFilter.splice(currentFilter.indexOf('eventTypes'), 2);
                }
                currentFilter.push(...newFilterValue);
            } else {
                currentFilter = newFilterValue;
            }
            this.dataGrid.instance.filter(currentFilter);
            this.filterActive = true;
            this.storeFilterToUrl();
            this.getColumnHeaderHtmlElement().style.backgroundColor = this.filterSelectedColor;
        } else {
            if (event && event.target) {
                (event.target as HTMLInputElement).checked = true;
            }
            this.deleteFilter(true);
        }
    }

    private removeDataGridFilter() {
        const currentFilter = this.dataGrid.instance.getCombinedFilter();
        if (currentFilter && currentFilter.indexOf('eventTypes') > -1) {
            currentFilter.splice(currentFilter.indexOf('eventTypes'), 2);
        }
        this.dataGrid.instance.filter(currentFilter);
    }

    private getColumnHeaderHtmlElement(): HTMLElement {
        return <HTMLElement>document.querySelectorAll('[id*="dx-col"]')[1];
    }

    // filter storing and loading from URL
    private storeFilterToUrl() {
        let detailsEventTypesFilter = undefined;
        if (this.filterActive) {
            const filter: number[] = [];
            this.filterModel.forEach((item) => {
                if (item.checked) {
                    filter.push(item.id);
                }
            });
            detailsEventTypesFilter = JSON.stringify(filter);
        }

        this.filterChanged.emit(detailsEventTypesFilter);

    }

    public applyFilterFromUrl() {
        if (this.route.snapshot.queryParams[DetailsHeaderComponent.FILTER_NAME]) {
            const listOfEventIds = JSON.parse(this.route.snapshot.queryParams[DetailsHeaderComponent.FILTER_NAME]);
            if (listOfEventIds) {
                this.filterModel.forEach((item) => {
                    item.checked = listOfEventIds.indexOf(item.id) > -1;
                });
                this.applySelection(undefined);
            }
        }
    }

    public ngOnInit(): void {
        this.applyFilterFromUrl();
    }

}
