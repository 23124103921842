<ng-template #content let-modal>
  <form [formGroup]="formGroup" (ngSubmit)="commitChange()">
    <div class="modal-header" data-cy="modal-bulk-change-sim-voice-limit-dialog">
      <h1 class="modal-title fs-5"
          id="bulkChangeSimVoiceLimitLabel">{{ 'bulkVoiceLimitChange.title' | i18next }}</h1>
      <button type="reset" class="btn-close" aria-label="Close" [disabled]="inProgress" (click)="modal.close('X')">
      </button>
    </div>
    <div class="modal-body">
      <div class="mb-3 gap-3">
        <div class="col">
          {{ 'bulkVoiceLimitChange.headline' | i18next: {count: selectedSimCards?.length} }}
        </div>

        <div class="input-group d-flex align-items-center gap-2">
          <input formControlName="voiceLimitInMinutes" (input)="evaluateTargets()"
                 autofocus class="form-control" id="inlineFormInputGroupLimit" digitOnly
                 data-cy="modal-bulk-change-sim-voice-limit-input" type="text">
          <span>{{ 'minutes.full' | i18next }}</span>
        </div>
        <div class="form-text">{{ 'bulkDataLimitChange.hint' | i18next }}</div>
      </div>
      <div>
        <div class="alert alert-light" *ngIf="enabled>0"
             data-cy="modal-bulk-change-sim-voice-limit-alert-sims-enabled">
          <a [routerLink]="[]" [queryParams]="getQueryParamFilter(enabledList)" target="_blank">
            {{ 'bulkVoiceLimitChange.enabled' | i18next: {count: enabled} }}
          </a>
        </div>
        <div class="alert alert-light" *ngIf="disabled>0"
             data-cy="modal-bulk-change-sim-voice-limit-alert-sims-disabled">
          <a [routerLink]="[]" [queryParams]="getQueryParamFilter(disabledList)" target="_blank">
            {{ 'bulkVoiceLimitChange.disabled' | i18next: {count: disabled} }}
          </a>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <div class="col float-start">
        <button type="reset" class="btn btn-secondary" [disabled]="inProgress" (click)="modal.close('Cancel')"
                data-cy="modal-bulk-change-sim-voice-limit-cancel-button">
          {{ 'shared.btn.cancel' | i18next }}
        </button>
      </div>
      <div class="col float-end text-end">
        <button type="submit" [disabled]="formGroup.invalid || inProgress"
                class="btn btn-primary" data-cy="modal-bulk-change-sim-voice-limit-save-button">
          {{ 'apply' | i18next }}
          <span *ngIf="inProgress" class="spinner-border spinner-border-sm" aria-hidden="true"></span>
        </button>
      </div>
    </div>
  </form>
</ng-template>
