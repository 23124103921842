<div class="d-flex flex-row">
  <div class="ps-2">
    <i *ngIf="data.json.enabled && !data.json.lockedImei" class="fa-solid fa-lock color-locked"></i>
    <i *ngIf="!data.json.enabled && !data.json.lockedImei" class="fa-solid fa-unlock color-unlocked"></i>
    <i *ngIf="data.json.lockedImei" class="fa-solid fa-lock color-unauthorized"></i>
  </div>
  <!-- imei lock has been enabled with known IMEI -->
  <div class="ps-1" *ngIf="data.json.enabled && data.json.imei">
    {{ 'imeiLockEvent.enabled.imeiKnown' | i18next: {imei: data.json.imei} }}
  </div>
  <!-- imei lock has been enabled with auto detect -->
  <div class="ps-1" *ngIf="data.json.enabled && !data.json.imei">
    {{ 'imeiLockEvent.enabled.imeiAutoDetect' | i18next }}
  </div>
  <!-- imei lock has been disabled -->
  <div class="ps-1" *ngIf="data.json.enabled !== undefined && !data.json.enabled">
    {{ 'imeiLockEvent.disabled' | i18next }}
  </div>
  <!-- sim card has been locked by imei lock -->
  <div class="ps-1" *ngIf="data.json.lockedImei">
    {{ 'imeiLockEvent.locked' | i18next: {lockedImei: data.json.lockedImei, imeiDetected: data.json.detectedImei} }}
  </div>
</div>
