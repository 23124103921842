<div class="container">
  <div class="row align-items-center">
    <div class="mx-auto col-10 col-md-8 col-lg-4">

      <div *ngIf="error" class="alert alert-secondary alert-dismissible d-flex align-items-center fade show" id="alert">
        <i class="fa-solid fa-triangle-exclamation"></i>
        <strong *ngIf="error.type" class="mx-2">{{ error.type }}</strong>
        {{ error.message }}
        <button class="btn-close" data-bs-dismiss="alert" type="button"></button>
      </div>

      <app-login (changeStep)="changeStep($event)" *ngIf="step===LoginStep.Login"></app-login>
      <app-inital-password-change (changeStep)="changeStep($event)"
                                  *ngIf="step===LoginStep.ResetPassword"></app-inital-password-change>
      <app-new-pass (changeStep)="changeStep($event)" (showError)="showError($event)"
                    *ngIf="step===LoginStep.NewPassword"></app-new-pass>

    </div>
  </div>
</div>
