{
  "assets/devex/de.json": "IJKmX_4bUUgGMGgLNv9ZdEqPSZz8TKCYEoFNFbM7OvE",
  "assets/devex/en.json": "fhXwfwhXigLXla2n9R9M8HhGYeNqZ8rLMI5sRLq6Ymw",
  "assets/fav-icons/android-chrome-192x192.png": "hjIcr_cv40KuZuFBs-fr37q2dgoF0QJIn5zsQ-nBNnY",
  "assets/fav-icons/android-chrome-512x512.png": "WXh-DtKlcpCadT9gYGaItq6ICV2s_tEdigYtPxiIEdg",
  "assets/fav-icons/apple-touch-icon.png": "2jwFj8AHe-jkLsW7V-AvR9efYiyHu1tXaGZGBsGIxPU",
  "assets/fav-icons/favicon-16x16.png": "krWuXt6CAoQ8hQEwzEMM3y9k6MAbB4ak65IZOxFoO74",
  "assets/fav-icons/favicon-32x32.png": "rTeQGGeARjVF6zx397IPDC58Wum0-GkXJKecqdBo-Q0",
  "assets/fav-icons/mstile-150x150.png": "-yYj3a1iu3awBOAa5Hl2W1ffyYuOVqi3FTG1wyjcwtg",
  "assets/i18n-iso-countries/de.json": "QzTsMjFqVkPkSfZLmLcR2g1ifEfMECjp7fkCOQhTTYg",
  "assets/i18n-iso-countries/en.json": "dEUYXWQk5XQCenzRQCngAHhOqgOiuDqwl3QgefcB5wI",
  "assets/locales/de/default.json": "ouiQQh61YctMxl2CftpU6M6hxbpVM3TcZg_GDGW-nmY",
  "assets/locales/de/formats.json": "r_HGGBj5_HkFNf-mtqocg0cSJTzxDfn28gj5JhJRa5g",
  "assets/locales/de/grid.json": "g4-BLCAW53DbrCcHKwcfqSuTtN8DMsmqPWzAcd-OYl4",
  "assets/locales/en/default.json": "T8zzEkrsrYYt6YFy51_PqSHqXte-VabDL8ctVt-rBiU",
  "assets/locales/en/formats.json": "r_HGGBj5_HkFNf-mtqocg0cSJTzxDfn28gj5JhJRa5g",
  "assets/locales/en/grid.json": "guS4NukXMvzmYrj_Iq5o1NvOcw4aPPRT1g4yytJr85c",
  "assets/whereverSIM-Logo.svg": "woiDa-ikW_v2LP3VS2-53jNQMFikwy71m-GEabJy63U"
}