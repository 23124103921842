import { ProviderIds } from './constants';

export interface ProviderFeature {
    mtSmsNumbers: boolean;
}

export class ProviderFeaturesLinkPlus implements ProviderFeature {
    public mtSmsNumbers = true;
}

export class ProviderFeaturesConnectPlus implements ProviderFeature {
    public mtSmsNumbers = true;
}

export class ProviderFeaturesTechPlus implements ProviderFeature {
    public mtSmsNumbers = true;
}

export class ProviderFeaturesDataPlus implements ProviderFeature {
    public mtSmsNumbers = true;
}

export class ProviderNotFound extends Error {
}

export abstract class ProviderFeatures {
    private static providerMap = new Map<number, ProviderFeature>([
        [ProviderIds.LINK_PLUS, new ProviderFeaturesLinkPlus()],
        [ProviderIds.CONNECT_PLUS, new ProviderFeaturesConnectPlus()],
        [ProviderIds.DATA_PLUS, new ProviderFeaturesDataPlus()],
        [ProviderIds.TECH_PLUS_PRO, new ProviderFeaturesTechPlus()],
        [ProviderIds.TECH_PLUS_BASIC, new ProviderFeaturesTechPlus()]
    ]);

    public static hasMtSmsNumbers(providerId: number) {
        const provider = this.providerMap.get(providerId);
        if (provider) return provider.mtSmsNumbers;
        throw new ProviderNotFound();
    }
}
