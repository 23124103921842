import { Component, Input, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { FetchResult } from '@apollo/client/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { I18NextPipe } from 'angular-i18next';
import { Sim, UpdateSimMutation } from '../../../../graphql/graphql.generated';
import { GraphqlService } from '../../service/graphql.service';
import { LoggerService } from '../../service/logger';
import { ToastMessageBuilder, ToastService } from '../../service/toast.service';
import { StatusConstants } from '../../shared/constants';

@Component({
    selector: 'app-modal-bulk-change-sim-voice-limit',
    templateUrl: './modal-bulk-change-sim-voice-limit.component.html',
    styleUrl: './modal-bulk-change-sim-voice-limit.component.scss'
})
export class ModalBulkChangeSimVoiceLimitComponent {

    @Input()
    public selectedSimCards?: Sim[];

    @ViewChild('content')
    private content: TemplateRef<never> | undefined;
    private modal?: NgbModalRef;

    protected inProgress: boolean = false;

    protected disabled: number = 0;
    protected enabled: number = 0;
    protected enabledList: Sim[] = [];
    protected disabledList: Sim[] = [];

    public constructor(private graphQlService: GraphqlService, private toastService: ToastService,
                       private i18nextPipe: I18NextPipe, private modalService: NgbModal) {
    }

    public formGroup: FormGroup = new FormGroup({
        voiceLimitInMinutes: new FormControl<number|null>(null)
    });

    public open(): NgbModalRef {
        this.evaluateTargets();
        this.modal = this.modalService.open(this.content, { ariaLabelledBy: 'bulkChangeSimVoiceLimitLabel' });
        return this.modal;
    }

    protected commitChange(): void {
        if (this.inProgress) return;
        if (!this.selectedSimCards || this.selectedSimCards.length === 0) return;
        this.inProgress = true;
        const voiceLimitInSeconds: number = this.formGroup.value.voiceLimitInMinutes ?
            Number.parseInt(this.formGroup.value.voiceLimitInMinutes) * 60 : 0;

        // collect the promises and wait with closing the dialog until we sent out all ...
        const successfulArray: string[] = [];
        const promises: Promise<FetchResult<UpdateSimMutation>>[] =
            this.selectedSimCards.map((simCard: Sim): Promise<FetchResult<UpdateSimMutation>> => {
            return this.graphQlService.updateSim(simCard.iccid, undefined, undefined, undefined, undefined, undefined, voiceLimitInSeconds);
        });

        Promise.allSettled(promises).then((results: PromiseSettledResult<FetchResult<UpdateSimMutation>>[]): void => {
            results.forEach((promiseResult, index: number): void => {
                if (promiseResult.status === 'fulfilled') {
                    if (promiseResult.value.data?.updateSim.iccid) {
                        successfulArray.push(promiseResult.value.data?.updateSim.iccid);
                    }
                } else {
                    const iccId = this.selectedSimCards![index].iccid;
                    const text = this.i18nextPipe.transform('bulkVoiceLimitChange.errorChange', {
                        iccId: iccId,
                        error: promiseResult.reason?.message
                    });
                    this.toastService.show(ToastMessageBuilder.error().text(text).build());
                }
            });
            this.inProgress = false;
            if (successfulArray.length > 0) {
                const text = this.i18nextPipe.transform('bulkVoiceLimitChange.successChange', { count: successfulArray.length });
                this.toastService.show(ToastMessageBuilder.success().text(text).build());
            }
            this.modal?.close();
        });
    }

    protected evaluateTargets() {
        const voiceLimitInSeconds: number | null = this.formGroup.value.voiceLimitInMinutes * 60
        LoggerService.debug('Eval: New limit', voiceLimitInSeconds);
        let enabled = 0;
        let disabled = 0;
        const disabledList: Sim[] = [];
        const enabledList: Sim[] = [];

        this.selectedSimCards?.forEach((sim) => {
            LoggerService.debug('Eval:', sim.monthly_data_limit, sim.monthly_data_volume, sim.statusid);

            if (this.isLimitIncreased(voiceLimitInSeconds, sim)) {
                if (this.isSimGetsActivated(voiceLimitInSeconds, sim)) {
                    enabled++;
                    enabledList.push(sim);
                }
            } else if (this.isLimitReduced(voiceLimitInSeconds, sim)) {
                if (this.isSimGetsDeactivated(voiceLimitInSeconds, sim)) {
                    disabled++;
                    disabledList.push(sim);
                }
            }
        });
        this.disabled = disabled;
        this.enabled = enabled;
        this.enabledList = enabledList;
        this.disabledList = disabledList;
    }

    private isLimitIncreased(newLimit: number | null, sim: Sim): boolean {
        if (newLimit) {
            return (sim.monthly_voice_mo_limit && newLimit > sim.monthly_voice_mo_limit);
        } else {
            // undefined == no data limit
            return (sim.monthly_voice_mo_limit);
        }
    }

    private isLimitReduced(newLimit: number | null, sim: Sim) {
        if (newLimit) {
            return (!sim.monthly_voice_mo_limit || newLimit < sim.monthly_voice_mo_limit);
        } else {
            return false;
        }
    }

    private isSimGetsActivated(newLimit: number | null, sim: Sim) {
        return sim.statusid === StatusConstants.VOICE_LIMIT_EXCEEDED && (!newLimit || newLimit >= sim.monthly_voice_mo);
    }

    private isSimGetsDeactivated(newLimit: number | null, sim: Sim) {
        return sim.statusid === StatusConstants.ACTIVATED && newLimit && newLimit < sim.monthly_voice_mo
    }

    protected getQueryParamFilter(params: Sim[]): { filter: string } {
        const filter = {
            'iccid': {
                'filterType': 'text',
                'type': 'in',
                'filter': params.map((value: Sim): string => value.iccid).join(',')
            }
        };
        return { filter: JSON.stringify(filter) };
    }
}
