import { Injectable, OnDestroy } from '@angular/core';
import { I18NextPipe } from 'angular-i18next';
import { GraphQLFormattedError } from 'graphql/error';
import { Observable, Subject, Subscription } from 'rxjs';
import { KeyValueInput } from '../../../graphql/graphql.generated';
import { AuthService, LOGGED_IN } from './auth/auth.service';
import { GraphqlService } from './graphql.service';
import { ToastMessageBuilder, ToastService } from './toast.service';

@Injectable({
    providedIn: 'root'
})
export class UserConfigService implements OnDestroy {

    public static readonly KEY_COLUMN_STATE_SIM_LIST: string = 'simListColumnState';

    private localStore?: Map<string, (string | undefined | null)>;
    private userConfigLoaded = new Subject<boolean>();

    private authStatusSubscription?: Subscription;

    public constructor(private graphQlService: GraphqlService,
                       private authService: AuthService,
                       private toastService: ToastService,
                       private i18nextPipe: I18NextPipe) {
        // it is possible, the user is already logged in when the service is created - so there is no broadcast from the subscription
        // coming in anymore. We could use a BehaviorSubject and get the current value, but this is a red flag.
        if (this.authService.isLoggedIn()) {
            this.loadUserConfig();
        }
        // in case the user is not logged in (or not yet) subscribe to login changes and then load the user config
        this.authStatusSubscription = this.authService.subscribeAuthStatus().subscribe((status) => {
            if (status == LOGGED_IN) {
                this.loadUserConfig();
            }
        });
    }

    public ngOnDestroy() {
        this.authStatusSubscription?.unsubscribe();
    }


    private loadUserConfig() {
        this.graphQlService.getUserConfig(false).then((value) => {
            this.localStore = new Map<string, (string | undefined | null)>;
            if (value.data) {
                value.data.getUserConfig.forEach((key) => {
                    if (key) {
                        this.localStore?.set(key.key, key.value);
                    }
                });
                // broadcast user settings loaded
                this.userConfigLoaded.next(true);
            } else if (value.errors) {
                this.showError(value.errors[0], 'errorLoad');
            }
        }).catch((error) => {
            if (error) {
                this.showError(error, 'errorLoad');
            }
        });
    }

    /**
     * Stores all locally stored user config values to the backend
     */
    public storeAll(showSuccess: boolean = true) {
        const mapToStore: KeyValueInput[] = [];
        this.localStore?.forEach((value, key) => {
            mapToStore.push({ key: key, value: value });
        });
        this.graphQlService.setUserConfig(mapToStore).then((result) => {
            if (!result.data && (result.errors && result.errors.length > 0)) {
                this.showError(result.errors[0], 'errorStore');
            } else if (showSuccess) {
                this.toastService.show(ToastMessageBuilder.success().text(this.i18nextPipe.transform('userConfig.storeTableConfigSuccessful')).build());
            }
        }).catch((error) => {
            if (error) {
                this.showError(error, 'errorStore');
            }
        });
    }

    /**
     * Returns the user configuration as a Map - may have to wait
     */
    public getUserConfig(): Map<string, (string | undefined | null)> | undefined {
        return this.localStore;
    }

    /**
     * Subscribe to user config loaded subject
     */
    public subscribeUserConfigLoaded(): Observable<boolean> {
        return this.userConfigLoaded.asObservable();
    }

    /**
     * Shows an error via ToastService - either while storing or loading data
     * @param error the error object to get a message from
     * @param kind the kind - 'errorStore' or 'errorLoad'
     */
    private showError(error: GraphQLFormattedError, kind: string) {
        const text: string = this.i18nextPipe.transform('userConfig.' + kind, { error: error.message });
        this.toastService.show(ToastMessageBuilder.error().text(text).build());
    }
}
