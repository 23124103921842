import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'durationFormat'
})
export class DurationFormatPipe implements PipeTransform {
    public transform(duration: number | undefined): string | number | undefined {
        if (duration) {
            const hours = Math.floor(duration / 3600);
            const minutes = Math.floor((duration % 3600) / 60);
            const seconds = duration % 60;
            return [hours, minutes, seconds].map((number) => number.toString().padStart(2, '0')).join(':');
        } else {
            return duration;
        }
    }
}
