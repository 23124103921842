<div class="flex-column d-flex flex-fill set-height">

  <div class="row align-items-center pt-2">
    <app-bulk-actions *ngIf="selectedRows" [selectedRows]="selectedRows" class="col-auto"></app-bulk-actions>

    <app-table-quicksearch (quickSearch)="quickSearchEvent($event)" class="col col-lg-6"></app-table-quicksearch>

    <app-table-config *ngIf="gridApi" [gridApi]="gridApi" class="col-auto ms-auto"></app-table-config>
  </div>

  <div class="row mt-2 d-flex flex-column align-items-end flex-fill">
    <ag-grid-angular [gridOptions]="getGridOptions()" class="ag-theme-alpine h-100 w-100" data-cy="table-main">
    </ag-grid-angular>
  </div>

  <app-statistic class="mt-2"></app-statistic>
</div>

<app-sim-detail (changedSim)="onDetailsSimChanged($event)"></app-sim-detail>
