import { Component, Input, OnInit } from '@angular/core';

interface JsonDataInterface {
    json: {
        presenceLevel: string,
        presenceEvent: string,
        operator: string,
        nwc: string,
        ip: string,
        countryCode: string
    };
}

@Component({
    selector: 'app-detail-presence',
    templateUrl: './detail-presence.component.html',
    styleUrl: './detail-presence.component.scss'
})
export class DetailPresenceComponent implements OnInit {

    @Input({ required: true })
    public data!: JsonDataInterface;

    protected iconClass: string | undefined = undefined;
    protected i18NextKeyForIcon: string | undefined;
    protected dataSessionActive: boolean = false;

    protected DEFAULT_ICON_CLASS = 'fa-solid fa-circle-xmark icon-hidden'

    protected PRESENCE_MAPPING = new Map<string, string> (
        [
            ['GPRS_UP', 'fa-solid fa-globe color-gprs-up'],
            ['GPRS_DOWN_TER_OK', 'fa-solid fa-globe color-gprs-down'],
            ['GPRS_DOWN_TER_ERR', 'fa-solid fa-globe color-gprs-down'],
            ['GPRS_DOWN_NET_OK', 'fa-solid fa-globe color-gprs-down'],
            ['GPRS_DOWN_NET_ERR', 'fa-solid fa-globe color-gprs-down'],
            ['GPRS_DOWN_NET_TOUT', 'fa-solid fa-globe color-gprs-down'],
            ['ASSUMED_IDLE', 'fa-solid fa-triangle-exclamation color-gprs-down'],
            ['CAMEL_BUSY', 'fa-solid fa-square-phone-flip color-gprs-down-blue'],
            ['MS_PURGED', 'fa-solid fa-circle-xmark color-gprs-down'],
            ['IMSI_DETACHED', 'fa-solid fa-circle-xmark color-gprs-down'],
            ['RESTRICTED_AREA', 'fa-solid fa-circle-xmark color-gprs-down'],
            ['NOT_REGISTERED', 'fa-solid fa-circle-xmark color-gprs-down'],
            ['REGISTRATION', 'fa-solid fa-circle-up color-gprs-down-green'],
        ]
    );

    public ngOnInit(): void {
        this.iconClass = this.PRESENCE_MAPPING.get(this.data.json.presenceEvent);
        if (!this.iconClass) {
            this.iconClass = this.DEFAULT_ICON_CLASS;
        }
        this.i18NextKeyForIcon = this.data.json.presenceEvent;
        if (this.data.json.presenceEvent === 'GPRS_UP') {
            this.dataSessionActive = true;
        }
    }

}
