import { Component, ElementRef, ViewChild } from '@angular/core';
import { ICellEditorAngularComp } from 'ag-grid-angular';
import { ICellEditorParams } from 'ag-grid-community';
import { I18NextPipe } from 'angular-i18next';
import { GraphqlService } from '../../../service/graphql.service';
import { ToastMessageBuilder, ToastService } from '../../../service/toast.service';

@Component({
    selector: 'app-voice-limit-edit',
    templateUrl: './voice-limit-edit.component.html',
    styleUrl: './voice-limit-edit.component.scss'
})
export class VoiceLimitEditComponent implements ICellEditorAngularComp {
    @ViewChild('limitInputVoiceMo')
    public input: ElementRef | undefined;

    private iccId: string | undefined;
    protected saving = false;
    protected unsavedVoiceMoLimit?: string;
    private params: ICellEditorParams | undefined;
    protected cellWidth: string | undefined;
    private saveSuccessful = false;


    public constructor(private graphQlService: GraphqlService,
                       private toastService: ToastService, private i18Next: I18NextPipe) {
    }

    public agInit(params: ICellEditorParams): void {
        const data = params.data;
        this.iccId = data.iccid;
        this.params = params;
        this.saveSuccessful = false;
        if (!this.unsavedVoiceMoLimit && data.monthly_voice_mo_limit) {
            this.unsavedVoiceMoLimit = (data.monthly_voice_mo_limit / 60).toString();
        }
        this.cellWidth = params.column.getActualWidth() + 'px';
    }

    /**
     * On Key down in the input field
     * @param $event
     */
    protected onKeyDown($event: KeyboardEvent) {
        if ($event.key === 'Escape') {
            this.close();
        } else if ($event.key === 'Enter') {
            $event.stopPropagation();
            this.save();
        } else if ($event.code.startsWith('Arrow')) {
            // Allow cursor movement
            $event.stopPropagation();
        } else if ($event.metaKey && $event.code === 'KeyA') {
            // Select All
            $event.stopPropagation();
        }
    }

    protected submitSave($event: MouseEvent) {
        if (!this.saving) {
            $event.stopPropagation();
            this.save();
        }
    }

    /**
     * Stores the data currently configured in this view
     */
    protected save() {
        if (!this.saving && this.iccId) {
            this.saving = true;
            // voice limit is set in seconds! but we configure minutes!
            const newVoiceMoLimit = Number(this.unsavedVoiceMoLimit || 0) * 60;
            this.graphQlService.updateSim(this.iccId, undefined, undefined, undefined, undefined, undefined, newVoiceMoLimit).then((result) => {
                if (result.data) {
                    this.saveSuccessful = true;
                    this.saving = false;
                    this.close();
                }
            }).catch((error) => {
                this.unsavedVoiceMoLimit = undefined;
                // show an error message!
                this.toastService.show(ToastMessageBuilder.error().text(this.i18Next.transform('error.voiceLimitUpdate', { errorMsg: error.message })).build());
                this.saving = false;
                this.close();
            });
        }
    }

    public close() {
        this.params?.stopEditing(true);
    }

    public getValue() {
        if (this.saveSuccessful) {
            let resultValue = undefined;
            if (this.unsavedVoiceMoLimit) {
                resultValue = Number(this.unsavedVoiceMoLimit) * 60;
            }
            return resultValue;
        }
        return undefined;
    }

    public afterGuiAttached() {
        // force to focus the MO input field when gui is shown!
        this.input?.nativeElement.focus();
    }

    protected clearInput(): void {
        this.unsavedVoiceMoLimit = undefined;
        this.afterGuiAttached();
    }

}
