import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ApplyColumnStateParams, ColumnState, GridApi } from 'ag-grid-community';
import { DndDropEvent } from 'ngx-drag-drop';
import { LoggerService } from '../../service/logger';
import { UserConfigService } from '../../service/user-config.service';

@Component({
    selector: 'app-table-config',
    templateUrl: './table-config.component.html',
    styleUrl: './table-config.component.scss'
})
export class TableConfigComponent implements OnInit, OnDestroy {

    private readonly EVENT_COLUMN_MOVED = 'columnMoved';
    private readonly EVENT_COLUMN_VISIBLE = 'columnVisible';

    @Input()
    public gridApi?: GridApi;

    protected columns?: ColumnState[];

    protected hideEntry = ['0'];

    public constructor(private userConfig: UserConfigService) {
    }

    public ngOnInit(): void {
        this.refreshColumns();
        this.gridApi?.addEventListener(this.EVENT_COLUMN_MOVED, () => this.refreshColumns());
        this.gridApi?.addEventListener(this.EVENT_COLUMN_VISIBLE, () => this.refreshColumns());
    }

    public ngOnDestroy(): void {
        this.gridApi?.removeEventListener(this.EVENT_COLUMN_MOVED, () => this.refreshColumns());
        this.gridApi?.removeEventListener(this.EVENT_COLUMN_VISIBLE, () => this.refreshColumns());
    }

    private refreshColumns() {
        this.columns = this.gridApi?.getColumnState();
    }

    protected changeVisibility(col: ColumnState) {
        col.hide = !col.hide;
        this.applyCurrentColumnState();
    }

    protected isMovable(col: ColumnState) {
        return !this.gridApi?.getColumnDef(col.colId)?.suppressMovable;
    }

    protected isVisibleLocked(col: ColumnState) {
        return !this.gridApi?.getColumnDef(col.colId)?.lockVisible;
    }

    protected onDragMoved(col: ColumnState) {
        if (this.columns) {
            const index = this.columns.indexOf(col);
            LoggerService.debug('onDragMoved: ', index, col);
            this.columns.splice(index, 1);
            this.applyCurrentColumnState();
        }
    }

    protected onDrop($event: DndDropEvent) {
        LoggerService.debug('onDrop: ', $event);
        if (!this.columns) {
            return;
        }
        let index = $event.index;
        if (typeof index === 'undefined') {
            index = this.columns.length;
        } else {
            index = index + this.hideEntry.length;
        }
        LoggerService.debug('onDrop: ', index, $event.data);
        this.columns.splice(index, 0, $event.data);
    }

    private applyCurrentColumnState() {
        const stateParams: ApplyColumnStateParams = {
            applyOrder: true,
            defaultState: { sort: null },
            state: this.columns
        };
        this.gridApi?.applyColumnState(stateParams);
    }

    protected resetTable() {
        this.gridApi?.resetColumnState();
        this.gridApi?.resetColumnGroupState();
        this.refreshColumns();
    }

    protected storeTable() {
        this.userConfig.storeAll();
    }

    protected showEntry(col: ColumnState) {
        return this.hideEntry.indexOf(col.colId) === -1;
    }
}
