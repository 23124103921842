<div class="container border bg-white shadow pt-2 pb-2 ag-custom-component-popup">
  <div class="row">
    <div class="col-12 text-center">
      <h6>{{ 'send_sms.title' | i18next }}</h6>
      <button (click)="close()" aria-label="Close" class="btn-close position-absolute top-0 end-0 m-1"
              type="button"></button>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <label class="form-label" for="smsBody">{{ 'message' | i18next }}</label>
      <textarea (keyup)="checkSmsBody()" (keydown.enter)="$event.stopPropagation();" [(ngModel)]="smsBody" autofocus class="form-control"
                id="smsBody"
                rows="3"></textarea>
    </div>
  </div>
  <!-- display character count (and probably not gsm 7bit conform error message) -->
  <div class="row mb-3">
    <div *ngIf="!gsm7BitConform" class="col-10 text-danger text-start">
      {{ 'send_sms.noGsmAlphabetShort' | i18next }}
    </div>
    <div [class]="gsm7BitConform ? 'col-12 text-end' : 'col-2 text-end'">
      {{ 'formats:number' | i18next: {val: smsBody.length} }}
    </div>
  </div>
  <!-- submit SMS button -->
  <div class="row">
    <div class="col-12 d-grid">
      <button (click)="sendSMS()" [disabled]="sending || smsBody.length === 0 || !gsm7BitConform"
              class="btn btn-primary">{{ ['send_sms.submit', 'button.submit'] | i18next }}
        <i *ngIf="sending" class="fa-solid fa-solid fa-circle-notch fa-spin"></i>
      </button>
    </div>
  </div>
</div>
