<form (ngSubmit)="changePassword()" [formGroup]="form">
  <div class="col-6 col-lg-6 col-sm-12 mx-auto">
    <h1>{{ 'changePassword.title' | i18next }}</h1>

    <div *ngIf="error" class="alert alert-danger alert-dismissible d-flex align-items-center fade show mt-2" id="alert">
      <i class="fa-solid fa-triangle-exclamation"></i>
      <strong class="ms-2 mx-2">{{ error | i18next }}</strong>
    </div>

    <div class="form-group mt-4">
      <label class="fw-bolder" for="password">{{ 'changePassword.old_password' | i18next }}</label>
      <input (input)="onOldPasswordChanged()" [placeholder]="'password' | i18next" class="form-control password mt-1"
             data-cy="input-old-password" formControlName="oldPassword" id="oldPassword" name="oldPassword" required
             type="password" [max]="environment.passwordRequirements.maxLength" autocomplete="current-password"/>
    </div>

    <div class="form-group mt-4">
      <label class="fw-bolder" for="password">{{ 'changePassword.new_password' | i18next }}</label>
      <input (input)="onNewPasswordChanged()" [placeholder]="'password' | i18next" class="form-control password mt-1"
             data-cy="input-new-password" formControlName="newPassword" id="password" name="newPassword" required
             type="password" [max]="environment.passwordRequirements.maxLength" autocomplete="new-password"/>
      <div *ngIf="oldPassMatchesNewPass" class="text-danger fs-6 mt-1 " data-cy="error-new-password"><small><i
        class="fa-solid fa-circle-exclamation me-1"></i>{{ 'changePassword.errors.oldAndNewPasswordMustBeDifferent' | i18next }}</small>
      </div>
    </div>

    <app-password-strength></app-password-strength>

    <div class="form-group mt-3">
      <label class="fw-bolder" for="password">{{ 'changePassword.repeat_new_password' | i18next }}</label>
      <input (input)="onNewRepeatPasswordChanged()" [placeholder]="'password' | i18next"
             class="form-control password mt-1" data-cy="input-new-password-repeat" formControlName="newPasswordRepeat"
             id="passwordRepeat" name="newPasswordRepeat" required type="password"
             [max]="environment.passwordRequirements.maxLength" autocomplete="new-password"/>
      <div *ngIf="!newPasswordMatching" class="text-danger fs-6 mt-1" data-cy="error-new-password-repeat"><small><i
        class="fa-solid fa-circle-exclamation me-1"></i>{{ 'changePassword.errors.newPasswordMustMatch' | i18next }}</small>
      </div>
    </div>

    <div class="alert alert-info mt-3" role="alert">
      <i class="fa-solid fa-circle-info mx-2"></i> {{ 'changePassword.info' | i18next }}
    </div>

    <div class="d-flex flex-row justify-content-center">
      <button
        [disabled]="form.invalid || newPassDisabled || !passwordGuidelinesMatch || !newPasswordMatching || oldPassMatchesNewPass"
        class="btn btn-primary btn-customized mt-4" data-cy="change-password-button"
        type="submit">
        {{ 'button.change_password' | i18next }}
      </button>
    </div>
  </div>
</form>
