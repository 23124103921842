import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
    selector: 'app-in-session',
    templateUrl: './in-session.component.html',
    styleUrls: ['./in-session.component.scss']
})
export class InSessionComponent implements ICellRendererAngularComp {

    public inSessionSince = 0;
    public showStatus = false;

    public agInit(params: ICellRendererParams): void {
        if (params.data) {
            this.showStatus = true;
        }
        if (params.value) {
            this.inSessionSince = params.value;
        } else {
            this.inSessionSince = 0;
        }
    }

    public refresh(params: ICellRendererParams): boolean {
        let newSession = 0;
        if (params.value) {
            newSession = params.value;
        }
        if (newSession !== this.inSessionSince) {
            this.inSessionSince = newSession;
            return true;
        }
        return false;
    }

    protected get inSession(): boolean {
        return this.inSessionSince > 0;
    }

}
