import { inject, Pipe, PipeTransform } from '@angular/core';
import { CacheBustingService } from '../service/cache-busting.service';

@Pipe({
    name: 'fileHash',
    standalone: true
})
export class FileHashPipe implements PipeTransform {

    private cacheBuster = inject(CacheBustingService);

    public transform(filePath: string): string {
        return this.cacheBuster.getHashForStaticAsset(filePath);
    }

}
