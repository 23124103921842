import { Component, Input } from '@angular/core';
import { USAGE_TYPE_DATA, USAGE_TYPE_SMS } from '../../../../shared/constants';

interface JsonDataInterface {
    json: {
        emails: string[],
        notificationType: number,
        amount: number,
        limit: number,
        usageTypeId: number,
        dataAmount: number, // this is old and can be safely removed after 2025-01-31 (only to show old data)
        dataLimit: number // this is old and can be safely removed after 2025-01-31 (only to show old data)
    };
}

@Component({
    selector: 'app-detail-usage-notification',
    templateUrl: './detail-usage-notification.component.html',
    styleUrl: './detail-usage-notification.component.scss'
})
export class DetailUsageNotificationComponent {

    @Input({ required: true })
    public data!: JsonDataInterface;

    protected readonly USAGE_TYPE_SMS = USAGE_TYPE_SMS;
    protected readonly USAGE_TYPE_DATA = USAGE_TYPE_DATA;
}
