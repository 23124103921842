<div class="container" xmlns="http://www.w3.org/1999/html">
  <div class="row align-items-center">
    <div class="mx-auto col-10 col-md-8 col-lg-4">

      <h2 class="text-center py-4">{{ 'reset-password.title' | i18next }}</h2>

      {{ 'reset-password.set.subtitle' | i18next: {email: userEmail} }}

      <div class="alert alert-warning mt-4 p-2" role="alert">
        {{ 'reset-password.hint' | i18next }}
      </div>

      <form (ngSubmit)="doSubmit()" [formGroup]="form">

        <div [hidden]="userEmail" class="form-group mt-4">
          <label class="form-label" for="username">{{ 'email' | i18nextCap }}</label>
          <input [autofocus]="!userEmail && !code" class="form-control" formControlName="emailAddress" id="username"
                 name="username" required type="text" autocomplete="username" data-cy="input-username-reset-password2"/>
        </div>

        <div class="form-group mt-4">
          <label class="form-label" for="code">{{ 'reset-password.code' | i18next }}</label>
          <input [autofocus]="userEmail && !code" class="form-control" formControlName="code" id="code" name="code"
                 required type="text" data-1p-ignore data-cy="input-code-set-password"/>
        </div>

        <div class="form-group mt-4">
          <label class="form-label" for="newPassword">{{ 'reset-password.new-password' | i18next }}</label>
          <input [autofocus]="userEmail && code" (input)="onOldPasswordChanged()"
                 [max]="environment.passwordRequirements.maxLength" class="form-control"
                 formControlName="newPassword" id="newPassword" name="newPassword" required
                 type="password" autocomplete="new-password" data-cy="input-password-set-password"/>
        </div>

        <app-password-strength></app-password-strength>

        <div class="d-grid gap-2 col-12 mx-auto">
          <button [disabled]="form.invalid || !passwordGuidelinesMatch || submitDisabled" class="btn btn-secondary mt-4"
                  type="submit" data-cy="button-submit-set-password">
            {{ 'button.change_password' | i18next }}
          </button>

          <span class="text-center py-3">
            <a routerLink="../../signin" class="link-secondary" data-cy="link-back-to-login-set-password">
              {{ 'button.back_to_login' | i18next }}
            </a>
          </span>

        </div>
      </form>
    </div>
  </div>
</div>
