<div class="container">
  <div class="row align-items-center">
    <div class="mx-auto col-10 col-md-8 col-lg-4">

      <h2 class="text-center py-4">{{ 'reset-password.title' | i18next }}</h2>

      {{ 'reset-password.subtitle' | i18next }}

      <form (ngSubmit)="doSubmit()" [formGroup]="form">

        <div class="form-group mt-4">
          <label class="form-label" for="username">{{ 'email' | i18nextCap }}</label>
          <input autofocus class="form-control mt-1" formControlName="emailAddress" id="username" name="username"
                 required type="text" autocomplete="username" data-cy="input-username-reset-password2"/>
        </div>

        <div class="d-grid gap-2 col-12 mx-auto">
          <button [disabled]="form.invalid || submitDisabled" class="btn btn-secondary mt-4" type="submit"
                  data-cy="button-submit-reset-password2">
            {{ 'reset-password.request-code' | i18next }}
            <i class="fa-solid fa-arrow-right"></i>
          </button>

          <span class="text-center py-3">
            <a routerLink="../signin" class="link-secondary" data-cy="link-back-to-login-reset-password2">
              {{ 'button.back_to_login' | i18next }}
            </a>
          </span>

        </div>
      </form>
    </div>
  </div>
</div>
