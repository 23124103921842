"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _v = _interopRequireDefault(require("./v35.js"));
var _sha = _interopRequireDefault(require("./sha1.js"));
function _interopRequireDefault(e) {
  return e && e.__esModule ? e : {
    default: e
  };
}
var v5 = (0, _v.default)('v5', 0x50, _sha.default);
var _default = exports.default = v5;