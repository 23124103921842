<div (mouseenter)="hidden = !hidden" (mouseleave)="hidden = !hidden"
     class="container-fluid p-0">
  <div class="d-flex flex-row">
    <span class="text-truncate flex-fill mb-auto">{{ labelName }}</span>
    <div *ngIf="showEdit" class="ps-1 pe-1">
      <i (click)="startEditMode()" class="fa-solid fa-pen p btn p-0"></i>
    </div>
    <div>
      <i [@fade]="hidden ? 'hidden' : 'visible'" (click)="copyClick($event)" [cbContent]="labelName"
         class="ms-auto fa-solid fa-copy p btn p-0" ngxClipboard></i>
    </div>
    <div>
    </div>
  </div>
</div>
