import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
    selector: 'app-msisdn',
    templateUrl: './msisdn.component.html',
    styleUrls: ['./msisdn.component.scss'],
    animations: [
        trigger('fade', [
            state('visible', style({ opacity: 1 })),
            state('hidden', style({ opacity: 0 })),
            transition('visible <=> hidden', animate('600ms'))
        ])
    ]
})
export class MsisdnComponent implements ICellRendererAngularComp {

    public msisdn = '';
    public hidden = true;
    public isCopied = false;

    public agInit(params: ICellRendererParams): void {
        this.msisdn = params.value;
    }

    public refresh(params: ICellRendererParams): boolean {
        if (this.msisdn !== params.value) {
            this.msisdn = params.value;
            return true;
        }
        return false;
    }

}
