/**
 * The current status of a query’s execution in our system.
 */
export var NetworkStatus;
(function (NetworkStatus) {
  /**
   * The query has never been run before and the query is now currently running. A query will still
   * have this network status even if a partial data result was returned from the cache, but a
   * query was dispatched anyway.
   */
  NetworkStatus[NetworkStatus["loading"] = 1] = "loading";
  /**
   * If `setVariables` was called and a query was fired because of that then the network status
   * will be `setVariables` until the result of that query comes back.
   */
  NetworkStatus[NetworkStatus["setVariables"] = 2] = "setVariables";
  /**
   * Indicates that `fetchMore` was called on this query and that the query created is currently in
   * flight.
   */
  NetworkStatus[NetworkStatus["fetchMore"] = 3] = "fetchMore";
  /**
   * Similar to the `setVariables` network status. It means that `refetch` was called on a query
   * and the refetch request is currently in flight.
   */
  NetworkStatus[NetworkStatus["refetch"] = 4] = "refetch";
  /**
   * Indicates that a polling query is currently in flight. So for example if you are polling a
   * query every 10 seconds then the network status will switch to `poll` every 10 seconds whenever
   * a poll request has been sent but not resolved.
   */
  NetworkStatus[NetworkStatus["poll"] = 6] = "poll";
  /**
   * No request is in flight for this query, and no errors happened. Everything is OK.
   */
  NetworkStatus[NetworkStatus["ready"] = 7] = "ready";
  /**
   * No request is in flight for this query, but one or more errors were detected.
   */
  NetworkStatus[NetworkStatus["error"] = 8] = "error";
})(NetworkStatus || (NetworkStatus = {}));
/**
 * Returns true if there is currently a network request in flight according to a given network
 * status.
 */
export function isNetworkRequestInFlight(networkStatus) {
  return networkStatus ? networkStatus < 7 : false;
}
/**
 * Returns true if the network request is in ready or error state according to a given network
 * status.
 */
export function isNetworkRequestSettled(networkStatus) {
  return networkStatus === 7 || networkStatus === 8;
}
