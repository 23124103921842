import { Component, Input, OnInit } from '@angular/core';
import { StatusConstants } from '../../../../shared/constants';

interface JsonDataInterface {
    json: {
        toStatusId: number
    };
}

@Component({
    selector: 'app-detail-lifecycle',
    templateUrl: './detail-lifecycle.component.html',
    styleUrl: './detail-lifecycle.component.scss'
})
export class DetailLifecycleComponent implements OnInit {

    @Input({ required: true })
    public data!: JsonDataInterface;

    private STATUS_ICON_MAPPING = {
        [StatusConstants.ACTIVATED]: {
            'icon': 'fa-solid fa-circle-play activated'
        },
        [StatusConstants.DEACTIVATED]: {
            'icon': 'fa-solid fa-circle-pause deactivated'
        }
    };

    private DEFAULT_ICON = 'fa-solid fa-circle-info icon-hidden';

    protected statusIcon: string | undefined;

    public ngOnInit(): void {
        const mapping = this.STATUS_ICON_MAPPING[this.data.json.toStatusId];
        if (mapping) {
            this.statusIcon = mapping.icon;
        } else {
            this.statusIcon = this.DEFAULT_ICON;
        }
    }


}
