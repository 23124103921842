import { Component } from '@angular/core';
import { IFilterAngularComp } from 'ag-grid-angular';
import { FilterModel, IDoesFilterPassParams, IFilterParams } from 'ag-grid-community';

/**
 * Interface that is used for Checkboxes
 */
interface SelectModel {
    id: number;
    selected: boolean;
}

interface StatusFilterModel extends FilterModel {
    filterType?: 'array';
    type: 'in';
    filter: number[];
}

/**
 * A simple filter component which is used for filtering statuses in the table.
 * I have decided on a component which contains a number of checkboxes to get selected/unselected.
 */
@Component({
    selector: 'app-status-filter',
    templateUrl: './status-filter.component.html',
    styleUrls: ['./status-filter.component.scss']
})
export class StatusFilterComponent implements IFilterAngularComp {

    // current filter parameters from the grid
    private params: IFilterParams | undefined;

    // define available status(es) that should come later through API!
    public statuses: SelectModel[] = [];

    public constructor() {
        for (const statusId of [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 18]) {
            this.statuses.push(
                { selected: true, id: statusId }
            );
        }
    }

    public agInit(params: IFilterParams): void {
        this.params = params;
    }

    public isFilterActive(): boolean {
        // when any status is unselected, the filter is active!
        return this.statuses.filter(s => !s.selected).length > 0;
    }

    /* eslint-disable @typescript-eslint/no-unused-vars */
    public doesFilterPass(params: IDoesFilterPassParams): boolean {
        // normally this method is used to filter given rows - but as we use a data source for infinite scrolling
        // this method return true all the time cause its pre-filtered.
        return true;
    }

    public getModel(): StatusFilterModel | null {
        const model = this.statuses.filter((e) => e.selected).map((e) => e.id);
        if (model.length === this.statuses.length) {
            // Return no Model if no filter is active (all options are selected)
            return null;
        }
        return {
            'filter': model,
            'filterType': 'array',
            'type': 'in'
        };
    }

    public setModel(model: StatusFilterModel) {
        // no model set (all selected)
        if (!model || model.filter.length === 0) {
            this.selectAllOptions();
        } else {
            // model set, select only given by model
            this.statuses.forEach((value: SelectModel) => {
                value.selected = model.filter.indexOf(value.id) > -1;
            });
        }
    }

    public updateFilter() {
        this.params?.filterChangedCallback();
    }

    public statusChanged(event: Event) {
        if (this.statuses.filter(s => s.selected).length === 0) {
            this.selectAllOptions();
            // when all have been deselected it's the same as all have been selected. but our element
            // the change event is coming from has "old" state and needs to get reselected!
            if (event && event.target) {
                (event.target as HTMLInputElement).checked = true;
            }
        }
        this.updateFilter();
    }

    public reset() {
        if (this.selectAllOptions()) {
            this.updateFilter();
        }
    }

    private selectAllOptions() {
        const deselectedOptions = this.statuses.filter((s) => !s.selected).length > 0;
        if (deselectedOptions) {
            this.statuses.forEach((value: SelectModel) => {
                value.selected = true;
            });
        }
        return deselectedOptions;
    }
}
