{
  "assets/devex/de.json": "U5zfkuCFr-KsHAksUXzvpHWfAMg0gK4Z1m3mCMx2FpM",
  "assets/devex/en.json": "_xndfdFCBdQVtMXFAPBb6eXcaT8Yk9x1w-C4Tuc2rY8",
  "assets/fav-icons/android-chrome-192x192.png": "hjIcr_cv40KuZuFBs-fr37q2dgoF0QJIn5zsQ-nBNnY",
  "assets/fav-icons/android-chrome-512x512.png": "WXh-DtKlcpCadT9gYGaItq6ICV2s_tEdigYtPxiIEdg",
  "assets/fav-icons/apple-touch-icon.png": "2jwFj8AHe-jkLsW7V-AvR9efYiyHu1tXaGZGBsGIxPU",
  "assets/fav-icons/favicon-16x16.png": "krWuXt6CAoQ8hQEwzEMM3y9k6MAbB4ak65IZOxFoO74",
  "assets/fav-icons/favicon-32x32.png": "rTeQGGeARjVF6zx397IPDC58Wum0-GkXJKecqdBo-Q0",
  "assets/fav-icons/mstile-150x150.png": "-yYj3a1iu3awBOAa5Hl2W1ffyYuOVqi3FTG1wyjcwtg",
  "assets/i18n-iso-countries/de.json": "QzTsMjFqVkPkSfZLmLcR2g1ifEfMECjp7fkCOQhTTYg",
  "assets/i18n-iso-countries/en.json": "dEUYXWQk5XQCenzRQCngAHhOqgOiuDqwl3QgefcB5wI",
  "assets/locales/de.default.json": "S0VJyAaEBcqOBv1TZYZDRdUEbwWHdtMrLQi7TbMJHUc",
  "assets/locales/de.formats.json": "yj0WO6sFU4GCciYUBWjzvvfqrBh869doeOC2Pp5EI1Y",
  "assets/locales/de.grid.json": "7bYB-SXS0_-V4AIgA2pcxKA2mBih-DSU8S6tMllL-0o",
  "assets/locales/en.default.json": "FiKpE5Ts0QZO1QtseFzZEgxYvIdNyhsUxBCPYs8hxx8",
  "assets/locales/en.formats.json": "WcWyldOJjQVVWD-xNQqMwo3PNCmTzdhNaY77pWRiapw",
  "assets/locales/en.grid.json": "xZANuedwY1-S0gNPDgmsTwS8EKteI-w9tvqDHTJhKs8",
  "assets/whereverSIM-Logo.svg": "woiDa-ikW_v2LP3VS2-53jNQMFikwy71m-GEabJy63U"
}