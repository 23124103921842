import { AfterViewChecked, ChangeDetectorRef, Component, inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CognitoUser, CognitoUserPool } from 'amazon-cognito-identity-js';
import { environment } from '../../../environments/environment';
import { LoggerService } from '../../service/logger';
import { ToastMessageBuilder, ToastService } from '../../service/toast.service';

@Component({
    selector: 'app-forgot-password',
    templateUrl: './forgot-password.component.html',
    styleUrl: './forgot-password.component.scss'
})
export class ForgotPasswordComponent implements AfterViewChecked {

    protected form: FormGroup;
    protected submitDisabled: boolean = false;
    protected userEmail?: string;

    private readonly changeDetectorRef = inject(ChangeDetectorRef);
    private router = inject(Router);
    private activeRoute = inject(ActivatedRoute);
    private formBuilder = inject(FormBuilder);
    private toastService = inject(ToastService);

    public constructor() {
        this.userEmail = localStorage.getItem('user.email') || undefined;

        this.form = this.formBuilder.group({
            emailAddress: [this.userEmail, [Validators.required, Validators.email]]
        });
    }

    // this fixes an issue from time to time with throwing an exception in the console!
    public ngAfterViewChecked(): void {
        this.changeDetectorRef.detectChanges();
    }

    protected doSubmit(): void {
        this.submitDisabled = true;

        const formValue = this.form.value;
        const emailAddress = formValue['emailAddress'];
        this.userEmail = emailAddress;

        const userPool = new CognitoUserPool(environment.cognito);
        const userData = { Username: emailAddress, Pool: userPool };
        const cognitoUser = new CognitoUser(userData);

        cognitoUser.forgotPassword({
            onSuccess: (data) => {
                this.submitDisabled = false;
                LoggerService.debug('forgotPassword onSuccess', data);
            },
            onFailure: (err: Error) => {
                LoggerService.debug('forgotPassword failed:', err);
                this.submitDisabled = false;
                this.toastService.show(ToastMessageBuilder.error().text('Passwort reset failed: ' + err).build());
            },
            inputVerificationCode: () => {
                localStorage.setItem('user.email', emailAddress);
                this.submitDisabled = false;
                this.router.navigate(['set'], { relativeTo: this.activeRoute }).then();
            }
        });

    }

}
