import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { Auth, Permission } from '../../../service/auth/WSimAuth';
import { ProviderFeatures } from '../../provider-features';

@Component({
    selector: 'app-sms-amount',
    templateUrl: './sms-amount.component.html',
    styleUrls: ['./sms-amount.component.scss']
})
export class SmsAmountComponent implements ICellRendererAngularComp {

    protected smsCount = 0;
    protected smsCountMo = 0;
    protected smsCountMt = 0;
    protected showCellData = false;
    protected showMt = true;

    private params: ICellRendererParams | undefined;

    public agInit(params: ICellRendererParams): void {
        this.showCellData = (params.node?.id !== undefined);
        this.updateCounter(params);
    }

    public refresh(params: ICellRendererParams): boolean {
        this.updateCounter(params);
        return true;
    }

    private updateCounter(params: ICellRendererParams) {
        this.params = params;
        if (params.data) {
            this.smsCountMo = params.data.monthly_sms_mo || 0;
            this.smsCountMt = params.data.monthly_sms_mt || 0;
            this.smsCount = params.value;
            this.showMt = ProviderFeatures.hasMtSmsNumbers(params.data.providerid);
        }
    }

    public startEditMode() {
        if (this.params?.column && this.params?.node.rowIndex) {
            this.params.api.startEditingCell({ rowIndex: this.params.node.rowIndex, colKey: this.params.column });
        }
    }

    protected readonly Auth = Auth;
    protected readonly Permission = Permission;
}
