import { Component, inject, Input, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router, UrlTree } from '@angular/router';
import { FetchResult } from '@apollo/client/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { I18NextPipe } from 'angular-i18next';
import i18next from 'i18next';
import { ImeiLockInput, Sim, UpdateSimMutation } from '../../../../graphql/graphql.generated';
import { GraphqlService } from '../../service/graphql.service';
import { ToastMessageBuilder, ToastService } from '../../service/toast.service';

@Component({
    selector: 'app-modal-bulk-change-sim-imei-lock',
    templateUrl: './modal-bulk-change-sim-imei-lock.component.html',
    styleUrl: './modal-bulk-change-sim-imei-lock.component.scss'
})
export class ModalBulkChangeSimImeiLockComponent {
    @Input() public selectedSimCards?: Sim[];
    public inProgress: boolean = false;
    public saveEnabled: boolean = true;


    @ViewChild('content') private content: TemplateRef<never> | undefined;
    private modal?: NgbModalRef;

    private graphQlService: GraphqlService = inject(GraphqlService);
    private toastService: ToastService = inject(ToastService);
    protected i18nextPipe: I18NextPipe = inject(I18NextPipe);
    private modalService: NgbModal = inject(NgbModal);
    private router: Router = inject(Router);
    private route: ActivatedRoute = inject(ActivatedRoute);

    protected imeiLockActive = false;

    public open(): NgbModalRef {
        this.modal = this.modalService.open(this.content, { ariaLabelledBy: 'modalSimImeiLock' });
        this.modal.result.then((): void => {
            this.saveEnabled = true;
        });
        return this.modal;
    }


    public commitChange(): void {
        if (this.inProgress || !this.selectedSimCards) {
            return;
        }
        this.inProgress = true;
        // collect the promises and wait with closing the dialog until we sent out all ...
        const promises: Promise<FetchResult<UpdateSimMutation>>[] = [];
        const successfulArray: string[] = [];
        this.selectedSimCards.forEach((simCard: Sim): void => {
            const imei_lock_input: ImeiLockInput = {
                lock: this.imeiLockActive
            };
            if (this.imeiLockActive) {
                imei_lock_input.imei = simCard.imei;
            }
            promises.push(this.graphQlService.updateSim(
                simCard.iccid, undefined, undefined, undefined, undefined, undefined, undefined, undefined, imei_lock_input));
        });

        // very important: promises are resolved in order. so we can access the original array for icc id mutation was called for:
        // https://stackoverflow.com/a/67274260
        Promise.allSettled(promises).then((results: PromiseSettledResult<FetchResult<UpdateSimMutation>>[]): void => {
            results.forEach((promiseResult: PromiseSettledResult<FetchResult<UpdateSimMutation>>, index: number): void => {
                if (promiseResult.status === 'fulfilled') {
                    if (promiseResult.value.data?.updateSim.iccid) {
                        successfulArray.push(promiseResult.value.data?.updateSim.iccid);
                    }
                } else {
                    if (!this.selectedSimCards) return;
                    const iccId = this.selectedSimCards[index].iccid;
                    const text = this.i18nextPipe.transform(
                        'bulkImeiLockChange.errorChangeForIccId', {
                            iccId: iccId,
                            error: promiseResult.reason?.message
                        });
                    this.toastService.show(ToastMessageBuilder.error().text(text).build());
                }
            });
            this.inProgress = false;
            if (successfulArray.length > 0) {
                const text: string = this.i18nextPipe.transform('bulkImeiLockChange.successChange',
                    { count: successfulArray.length });
                this.toastService.show(ToastMessageBuilder.success().text(text).build());
            }
            this.imeiLockActive = false;
            this.modal?.close();
        });
    }

    protected getQueryParamFilter(params: Sim[]): { filter: string } {
        const filter = {
            'iccid': {
                'filterType': 'text',
                'type': 'in',
                'filter': params.map((value: Sim): string => value.iccid).join(',')
            }
        };
        return { filter: JSON.stringify(filter) };
    }

    protected onClosed(): void {
        this.imeiLockActive = false;
        this.modal?.close('Cancel');
    }

    protected readonly i18next = i18next;

    protected generateLink(length: number): string {
        if (this.selectedSimCards) {
            const queryParams: { filter: string } = this.getQueryParamFilter(this.selectedSimCards);
            const urlTree: UrlTree = this.router.createUrlTree([], {
                relativeTo: this.route, queryParams
            });
            const newUrl: string = this.router.serializeUrl(urlTree);
            return `<a href="${newUrl}" target="_blank">${this.i18nextPipe.transform('sim-cards', { count: length })}</a>`;
        }
        return '';
    }

}
