<h1>{{ 'login.title_reset_password' | i18next }}</h1>
<form (ngSubmit)="doSubmit()" [formGroup]="form">

  <div class="form-group mt-4">
    <label for="username">{{ 'email' | i18nextCap }}</label>
    <input class="form-control username mt-1" formControlName="emailAddress" id="username"
           name="username" placeholder="user@email.com" required type="text"/>
  </div>

  <div class="form-group mt-3">
    <label for="resetCode">{{ 'login.code' | i18next }}    </label>
    <button [disabled]="requestNewCode" (click)="requestCode()" type="button" class="btn btn-link p-1">Request new code
      <span *ngIf="requestNewCode" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
    </button>
    <input autofocus class="form-control mt-1" formControlName="resetCode" id="resetCode" name="resetCode"
           placeholder="{{ 'login.code' | i18next }}"
           required type="text"/>
  </div>

  <div class="form-group mt-3">
    <label for="password">{{ 'login.new_password' | i18nextCap }}</label>
    <input class="form-control password mt-1" formControlName="password" id="password" name="password"
           placeholder="{{ 'login.new_password' | i18nextCap }}"
           required type="password"/>
  </div>

  <div class="d-grid gap-2 col-12 mx-auto">
    <button [disabled]="form.invalid || resetDisabled" class="btn btn-primary btn-customized mt-4"
            type="submit">
      {{ 'button.change_password' | i18next }}
    </button>
  </div>
</form>
