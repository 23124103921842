import { Component, Input } from '@angular/core';

interface JsonDataInterface {
    json: {
        smsDaily: number,
        smsMonthly: number,
        dataDaily: number,
        dataMonthly: number,
        voiceDaily: number,
        voiceMonthly: number
    };
}

@Component({
    selector: 'app-detail-consumption',
    templateUrl: './detail-consumption.component.html',
    styleUrl: './detail-consumption.component.scss'
})
export class DetailConsumptionComponent {

    @Input({ required: true })
    public data!: JsonDataInterface;

}
