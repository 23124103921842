<ngb-toast
  *ngFor="let toast of toastService.toasts"
  [class]="toast.className"
  [autohide]="toast.autoHide"
  [delay]="toast.delay || 5000"
  (hidden)="toastService.remove(toast)"
  data-cy="wsim-toast"
>
  <div class="d-flex">
    <div class="toast-body w-100">
      {{ toast.text }}
    </div>
    <button type="button" class="btn-close top-0 end-0" data-bs-dismiss="toast" aria-label="Close"></button>
  </div>
</ngb-toast>
