import gql from 'graphql-tag';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  AWSDate: { input: any; output: any; }
  AWSDateTime: { input: any; output: any; }
  AWSEmail: { input: any; output: any; }
  AWSIPAddress: { input: any; output: any; }
  AWSJSON: { input: any; output: any; }
  AWSPhone: { input: any; output: any; }
  AWSTime: { input: any; output: any; }
  AWSTimestamp: { input: any; output: any; }
  AWSURL: { input: any; output: any; }
  Long: { input: any; output: any; }
};

export type ApiKey = {
  __typename?: 'ApiKey';
  admin: Scalars['Boolean']['output'];
  comment?: Maybe<Scalars['String']['output']>;
  created: Scalars['Long']['output'];
  customerid: Scalars['Int']['output'];
  key: Scalars['String']['output'];
  keyid: Scalars['Int']['output'];
  lastused?: Maybe<Scalars['Long']['output']>;
};

export type CancelLocation = {
  __typename?: 'CancelLocation';
  blockedUntil: Scalars['Long']['output'];
  sent: Scalars['Boolean']['output'];
};

export type Consumption = {
  __typename?: 'Consumption';
  date_str: Scalars['String']['output'];
  type_of_consumption: Scalars['Int']['output'];
  volume: Scalars['Long']['output'];
};

export type ConsumptionList = {
  __typename?: 'ConsumptionList';
  customerid: Scalars['Int']['output'];
  daily?: Maybe<Array<Maybe<Consumption>>>;
  iccid: Scalars['ID']['output'];
  monthly?: Maybe<Array<Maybe<Consumption>>>;
};

export type Customer = {
  __typename?: 'Customer';
  current_name: Scalars['String']['output'];
  customerid: Scalars['Int']['output'];
  is_test_customer: Scalars['Boolean']['output'];
};

export type DataConsumption = {
  __typename?: 'DataConsumption';
  bytes: Scalars['Long']['output'];
  day: Scalars['String']['output'];
};

export type DataConsumptionFilterInput = {
  start: Scalars['String']['input'];
  stop: Scalars['String']['input'];
};

export type EndCustomerLabel = {
  __typename?: 'EndCustomerLabel';
  name: Scalars['String']['output'];
};

export type EndCustomerLabelInput = {
  name: Scalars['String']['input'];
};

export type EndCustomerLabels = {
  __typename?: 'EndCustomerLabels';
  items?: Maybe<Array<Maybe<EndCustomerLabel>>>;
  nextToken?: Maybe<Scalars['String']['output']>;
};

export type KeyValue = {
  __typename?: 'KeyValue';
  key: Scalars['String']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type KeyValueInput = {
  key: Scalars['String']['input'];
  value?: InputMaybe<Scalars['String']['input']>;
};

export type ListEndCustomerLabelNameFilterInput = {
  beginsWith?: InputMaybe<Scalars['String']['input']>;
  contains?: InputMaybe<Scalars['String']['input']>;
};

export type ListEndCustomerLabelsFilterInput = {
  name?: InputMaybe<ListEndCustomerLabelNameFilterInput>;
};

export type Mutation = {
  __typename?: 'Mutation';
  addSimEvent: SimEvent;
  addUsageNotification?: Maybe<UsageNotification>;
  cancelLocation: CancelLocation;
  createApiKey?: Maybe<ApiKey>;
  createUserAccount: UserAccount;
  deleteApiKey?: Maybe<ApiKey>;
  deleteUsageNotification?: Maybe<UsageNotification>;
  deleteUserAccount: UserAccount;
  resendUserAccountInvite: Scalars['Boolean']['output'];
  sendSms: Scalars['Boolean']['output'];
  setUserConfig: Scalars['Boolean']['output'];
  updateApiKey?: Maybe<ApiKey>;
  updateSim: Sim;
  updateUsageNotification?: Maybe<UsageNotification>;
  updateUserAccount: UserAccount;
  updatedConsumption: ConsumptionList;
  updatedSim: Sim;
};


export type MutationAddSimEventArgs = {
  iccid: Scalars['ID']['input'];
  simEventId: Scalars['ID']['input'];
};


export type MutationAddUsageNotificationArgs = {
  customerid?: InputMaybe<Scalars['Int']['input']>;
  emails: Array<InputMaybe<Scalars['String']['input']>>;
  percent90: Scalars['Boolean']['input'];
  percent100: Scalars['Boolean']['input'];
};


export type MutationCancelLocationArgs = {
  iccid: Scalars['ID']['input'];
};


export type MutationCreateApiKeyArgs = {
  admin?: InputMaybe<Scalars['Boolean']['input']>;
  comment?: InputMaybe<Scalars['String']['input']>;
  customerid?: InputMaybe<Scalars['Int']['input']>;
};


export type MutationCreateUserAccountArgs = {
  customerid?: InputMaybe<Scalars['Int']['input']>;
  email: Scalars['String']['input'];
  groups?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  locale: Scalars['String']['input'];
  zoneinfo: Scalars['String']['input'];
};


export type MutationDeleteApiKeyArgs = {
  keyid: Scalars['ID']['input'];
};


export type MutationDeleteUsageNotificationArgs = {
  id: Scalars['Int']['input'];
};


export type MutationDeleteUserAccountArgs = {
  id: Scalars['String']['input'];
};


export type MutationResendUserAccountInviteArgs = {
  id: Scalars['String']['input'];
};


export type MutationSendSmsArgs = {
  iccid: Scalars['ID']['input'];
  originator?: InputMaybe<Scalars['String']['input']>;
  text: Scalars['String']['input'];
};


export type MutationSetUserConfigArgs = {
  user_config: Array<InputMaybe<KeyValueInput>>;
};


export type MutationUpdateApiKeyArgs = {
  comment?: InputMaybe<Scalars['String']['input']>;
  keyid: Scalars['ID']['input'];
};


export type MutationUpdateSimArgs = {
  custom_field_1?: InputMaybe<Scalars['String']['input']>;
  customerid?: InputMaybe<Scalars['Int']['input']>;
  end_customer_label?: InputMaybe<EndCustomerLabelInput>;
  iccid: Scalars['ID']['input'];
  monthly_data_limit?: InputMaybe<Scalars['Long']['input']>;
  monthly_sms_mo_limit?: InputMaybe<Scalars['Long']['input']>;
  monthly_sms_mt_limit?: InputMaybe<Scalars['Long']['input']>;
  monthly_voice_mo_limit?: InputMaybe<Scalars['Long']['input']>;
  statusid?: InputMaybe<Scalars['Int']['input']>;
};


export type MutationUpdateUsageNotificationArgs = {
  customerid?: InputMaybe<Scalars['Int']['input']>;
  emails: Array<InputMaybe<Scalars['String']['input']>>;
  id: Scalars['Int']['input'];
  percent90: Scalars['Boolean']['input'];
  percent100: Scalars['Boolean']['input'];
};


export type MutationUpdateUserAccountArgs = {
  groups?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  zoneinfo?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpdatedConsumptionArgs = {
  iccid: Scalars['ID']['input'];
};


export type MutationUpdatedSimArgs = {
  iccid: Scalars['ID']['input'];
};

export type Query = {
  __typename?: 'Query';
  getAvailableStatusTransitions: Array<Maybe<SimTransition>>;
  getCustomerData: Customer;
  getDataConsumptionReport?: Maybe<Array<Maybe<DataConsumption>>>;
  getStatistics: Statistic;
  getUsageNotification?: Maybe<UsageNotification>;
  getUserConfig: Array<Maybe<KeyValue>>;
  listApiKeys: Array<Maybe<ApiKey>>;
  listEndCustomerLabels?: Maybe<EndCustomerLabels>;
  listSimEvents?: Maybe<SimEvents>;
  listSims?: Maybe<SimList>;
  listUserAccounts: Array<Maybe<UserAccount>>;
};


export type QueryGetDataConsumptionReportArgs = {
  filterOptions: DataConsumptionFilterInput;
  iccid: Scalars['ID']['input'];
};


export type QueryGetStatisticsArgs = {
  customerid?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryGetUsageNotificationArgs = {
  customerid?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryListApiKeysArgs = {
  customerid?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryListEndCustomerLabelsArgs = {
  filter?: InputMaybe<ListEndCustomerLabelsFilterInput>;
  limit: Scalars['Int']['input'];
  nextToken?: InputMaybe<Scalars['String']['input']>;
};


export type QueryListSimEventsArgs = {
  filterOptions?: InputMaybe<SimEventFilterInput>;
  iccid: Scalars['ID']['input'];
  limit: Scalars['Int']['input'];
  nextToken?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<SimEventOrder>;
};


export type QueryListSimsArgs = {
  filter?: InputMaybe<SimFilterInput>;
  limit: Scalars['Int']['input'];
  nextToken?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<SimListOrder>;
  quickSearch?: InputMaybe<Scalars['String']['input']>;
};


export type QueryListUserAccountsArgs = {
  customerid?: InputMaybe<Scalars['Int']['input']>;
};

export type Sim = {
  __typename?: 'SIM';
  activation_timestamp?: Maybe<Scalars['Long']['output']>;
  apn?: Maybe<Scalars['String']['output']>;
  custom_field_1?: Maybe<Scalars['String']['output']>;
  customerid?: Maybe<Scalars['Int']['output']>;
  end_customer_label?: Maybe<EndCustomerLabel>;
  iccid: Scalars['ID']['output'];
  imei?: Maybe<Scalars['String']['output']>;
  imsi?: Maybe<Scalars['String']['output']>;
  in_session_since?: Maybe<Scalars['Long']['output']>;
  ip_address?: Maybe<Scalars['String']['output']>;
  monthly_data_limit?: Maybe<Scalars['Long']['output']>;
  monthly_data_volume?: Maybe<Scalars['Long']['output']>;
  monthly_sms?: Maybe<Scalars['Int']['output']>;
  monthly_sms_mo?: Maybe<Scalars['Int']['output']>;
  monthly_sms_mo_limit?: Maybe<Scalars['Int']['output']>;
  monthly_sms_mt?: Maybe<Scalars['Int']['output']>;
  monthly_sms_mt_limit?: Maybe<Scalars['Int']['output']>;
  monthly_voice_mo?: Maybe<Scalars['Long']['output']>;
  monthly_voice_mo_limit?: Maybe<Scalars['Long']['output']>;
  msisdn?: Maybe<Scalars['String']['output']>;
  nwc?: Maybe<Scalars['String']['output']>;
  previous_month_data_volume?: Maybe<Scalars['Long']['output']>;
  providerid: Scalars['Int']['output'];
  statusid: Scalars['Int']['output'];
};

export type SimEvent = {
  __typename?: 'SimEvent';
  details?: Maybe<Scalars['String']['output']>;
  iccid: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  jsonData?: Maybe<Scalars['String']['output']>;
  timestampMilliseconds: Scalars['Long']['output'];
  type: Scalars['String']['output'];
};

export type SimEventFilterInput = {
  timestampMilliseconds?: InputMaybe<SimEventIntFilterInput>;
  type?: InputMaybe<StringFilterInput>;
};

export type SimEventIntFilterInput = {
  between?: InputMaybe<Array<InputMaybe<Scalars['Long']['input']>>>;
  eq?: InputMaybe<Scalars['Long']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Long']['input']>>>;
};

export type SimEventOrder = {
  timestampMilliseconds?: InputMaybe<Scalars['String']['input']>;
};

export type SimEvents = {
  __typename?: 'SimEvents';
  items?: Maybe<Array<Maybe<SimEvent>>>;
  nextToken?: Maybe<Scalars['String']['output']>;
  totalEvents?: Maybe<Scalars['Long']['output']>;
};

export type SimFilterInput = {
  custom_field_1?: InputMaybe<StringFilterInput>;
  customerid?: InputMaybe<SimListIntFilterInput>;
  end_customer_label?: InputMaybe<StringFilterInput>;
  iccid?: InputMaybe<StringFilterInput>;
  imei?: InputMaybe<StringFilterInput>;
  imsi?: InputMaybe<StringFilterInput>;
  msisdn?: InputMaybe<StringFilterInput>;
  providerid?: InputMaybe<SimListIntFilterInput>;
  statusid?: InputMaybe<SimListIntFilterInput>;
};

export type SimList = {
  __typename?: 'SimList';
  items?: Maybe<Array<Maybe<Sim>>>;
  nextToken?: Maybe<Scalars['String']['output']>;
};

export type SimListIntFilterInput = {
  beginsWith?: InputMaybe<Scalars['Int']['input']>;
  eq?: InputMaybe<Scalars['Int']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
};

export type SimListOrder = {
  activation_timestamp?: InputMaybe<Scalars['String']['input']>;
  custom_field_1?: InputMaybe<Scalars['String']['input']>;
  end_customer_label?: InputMaybe<Scalars['String']['input']>;
  iccid?: InputMaybe<Scalars['String']['input']>;
  imei?: InputMaybe<Scalars['String']['input']>;
  imsi?: InputMaybe<Scalars['String']['input']>;
  in_session_since?: InputMaybe<Scalars['String']['input']>;
  monthly_data_limit?: InputMaybe<Scalars['String']['input']>;
  monthly_data_volume?: InputMaybe<Scalars['String']['input']>;
  monthly_sms_mo?: InputMaybe<Scalars['String']['input']>;
  monthly_sms_mt?: InputMaybe<Scalars['String']['input']>;
  monthly_voice_mo?: InputMaybe<Scalars['String']['input']>;
  monthly_voice_mo_limit?: InputMaybe<Scalars['String']['input']>;
  msisdn?: InputMaybe<Scalars['String']['input']>;
  previous_month_data_volume?: InputMaybe<Scalars['String']['input']>;
  providerid?: InputMaybe<Scalars['String']['input']>;
  statusid?: InputMaybe<Scalars['String']['input']>;
};

export type SimTransition = {
  __typename?: 'SimTransition';
  fromstatusid: Scalars['Int']['output'];
  providerid: Scalars['Int']['output'];
  tostatusid: Scalars['Int']['output'];
};

export type Statistic = {
  __typename?: 'Statistic';
  activeSimCards: Scalars['Int']['output'];
  currentMonthlyDataUsage: Scalars['Long']['output'];
  previousMonthDataUsage: Scalars['Long']['output'];
  totalSimCards: Scalars['Int']['output'];
};

export type StringFilterInput = {
  beginsWith?: InputMaybe<Scalars['String']['input']>;
  eq?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Subscription = {
  __typename?: 'Subscription';
  updatedConsumption?: Maybe<ConsumptionList>;
  updatedSim?: Maybe<Sim>;
  updatedSimEvent?: Maybe<SimEvent>;
};


export type SubscriptionUpdatedConsumptionArgs = {
  customerid?: InputMaybe<Scalars['Int']['input']>;
};


export type SubscriptionUpdatedSimArgs = {
  customerid?: InputMaybe<Scalars['Int']['input']>;
};


export type SubscriptionUpdatedSimEventArgs = {
  iccid: Scalars['ID']['input'];
};

export type UsageNotification = {
  __typename?: 'UsageNotification';
  emails: Array<Maybe<Scalars['String']['output']>>;
  id: Scalars['Int']['output'];
  percent90: Scalars['Boolean']['output'];
  percent100: Scalars['Boolean']['output'];
};

export type UserAccount = {
  __typename?: 'UserAccount';
  email: Scalars['String']['output'];
  groups?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  id: Scalars['String']['output'];
  locale: Scalars['String']['output'];
  status: Scalars['String']['output'];
  zoneinfo: Scalars['String']['output'];
};

export type ListSimsQueryVariables = Exact<{
  filter?: InputMaybe<SimFilterInput>;
  order?: InputMaybe<SimListOrder>;
  limit: Scalars['Int']['input'];
  nextToken?: InputMaybe<Scalars['String']['input']>;
  quickSearch?: InputMaybe<Scalars['String']['input']>;
}>;


export type ListSimsQuery = { __typename?: 'Query', listSims?: { __typename?: 'SimList', nextToken?: string | null, items?: Array<{ __typename?: 'SIM', iccid: string, customerid?: number | null, statusid: number, providerid: number, imei?: string | null, imsi?: string | null, msisdn?: string | null, activation_timestamp?: any | null, in_session_since?: any | null, previous_month_data_volume?: any | null, monthly_data_volume?: any | null, monthly_data_limit?: any | null, monthly_sms_mo?: number | null, monthly_sms_mt?: number | null, monthly_sms?: number | null, monthly_sms_mo_limit?: number | null, monthly_sms_mt_limit?: number | null, monthly_voice_mo?: any | null, monthly_voice_mo_limit?: any | null, custom_field_1?: string | null, ip_address?: string | null, nwc?: string | null, apn?: string | null, end_customer_label?: { __typename?: 'EndCustomerLabel', name: string } | null } | null> | null } | null };

export type GetCustomerDataQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCustomerDataQuery = { __typename?: 'Query', getCustomerData: { __typename?: 'Customer', customerid: number, is_test_customer: boolean, current_name: string } };

export type UpdateSimMutationVariables = Exact<{
  iccid: Scalars['ID']['input'];
  customerid?: InputMaybe<Scalars['Int']['input']>;
  statusid?: InputMaybe<Scalars['Int']['input']>;
  monthly_data_limit?: InputMaybe<Scalars['Long']['input']>;
  custom_field_1?: InputMaybe<Scalars['String']['input']>;
  monthly_sms_mo_limit?: InputMaybe<Scalars['Long']['input']>;
  monthly_sms_mt_limit?: InputMaybe<Scalars['Long']['input']>;
  monthly_voice_mo_limit?: InputMaybe<Scalars['Long']['input']>;
  end_customer_label?: InputMaybe<EndCustomerLabelInput>;
}>;


export type UpdateSimMutation = { __typename?: 'Mutation', updateSim: { __typename?: 'SIM', customerid?: number | null, activation_timestamp?: any | null, imei?: string | null, imsi?: string | null, msisdn?: string | null, iccid: string, providerid: number, statusid: number, in_session_since?: any | null, monthly_data_volume?: any | null, monthly_data_limit?: any | null, monthly_sms_mo?: number | null, monthly_sms_mt?: number | null, monthly_sms?: number | null, monthly_sms_mo_limit?: number | null, monthly_sms_mt_limit?: number | null, monthly_voice_mo?: any | null, monthly_voice_mo_limit?: any | null, custom_field_1?: string | null, previous_month_data_volume?: any | null, apn?: string | null, ip_address?: string | null, nwc?: string | null, end_customer_label?: { __typename?: 'EndCustomerLabel', name: string } | null } };

export type SendSmsMutationVariables = Exact<{
  iccid: Scalars['ID']['input'];
  text: Scalars['String']['input'];
  originator?: InputMaybe<Scalars['String']['input']>;
}>;


export type SendSmsMutation = { __typename?: 'Mutation', sendSms: boolean };

export type GetUsageNotificationQueryVariables = Exact<{
  customerid?: InputMaybe<Scalars['Int']['input']>;
}>;


export type GetUsageNotificationQuery = { __typename?: 'Query', getUsageNotification?: { __typename?: 'UsageNotification', id: number, emails: Array<string | null>, percent90: boolean, percent100: boolean } | null };

export type AddUsageNotificationMutationVariables = Exact<{
  customerid?: InputMaybe<Scalars['Int']['input']>;
  emails: Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>;
  percent90: Scalars['Boolean']['input'];
  percent100: Scalars['Boolean']['input'];
}>;


export type AddUsageNotificationMutation = { __typename?: 'Mutation', addUsageNotification?: { __typename?: 'UsageNotification', id: number, emails: Array<string | null>, percent90: boolean, percent100: boolean } | null };

export type UpdateUsageNotificationMutationVariables = Exact<{
  id: Scalars['Int']['input'];
  customerid?: InputMaybe<Scalars['Int']['input']>;
  emails: Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>;
  percent90: Scalars['Boolean']['input'];
  percent100: Scalars['Boolean']['input'];
}>;


export type UpdateUsageNotificationMutation = { __typename?: 'Mutation', updateUsageNotification?: { __typename?: 'UsageNotification', id: number, emails: Array<string | null>, percent90: boolean, percent100: boolean } | null };

export type DeleteUsageNotificationMutationVariables = Exact<{
  id: Scalars['Int']['input'];
}>;


export type DeleteUsageNotificationMutation = { __typename?: 'Mutation', deleteUsageNotification?: { __typename?: 'UsageNotification', id: number, emails: Array<string | null>, percent90: boolean, percent100: boolean } | null };

export type CancelLocationMutationVariables = Exact<{
  iccid: Scalars['ID']['input'];
}>;


export type CancelLocationMutation = { __typename?: 'Mutation', cancelLocation: { __typename?: 'CancelLocation', blockedUntil: any, sent: boolean } };

export type GetAvailableStatusTransitionsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAvailableStatusTransitionsQuery = { __typename?: 'Query', getAvailableStatusTransitions: Array<{ __typename?: 'SimTransition', fromstatusid: number, tostatusid: number, providerid: number } | null> };

export type UpdatedSimSubscriptionVariables = Exact<{
  customerid?: InputMaybe<Scalars['Int']['input']>;
}>;


export type UpdatedSimSubscription = { __typename?: 'Subscription', updatedSim?: { __typename?: 'SIM', activation_timestamp?: any | null, customerid?: number | null, iccid: string, imei?: string | null, imsi?: string | null, msisdn?: string | null, providerid: number, statusid: number, in_session_since?: any | null, monthly_data_volume?: any | null, monthly_data_limit?: any | null, monthly_sms_mo?: number | null, monthly_sms_mt?: number | null, custom_field_1?: string | null, previous_month_data_volume?: any | null, monthly_sms?: number | null, monthly_sms_mo_limit?: number | null, monthly_sms_mt_limit?: number | null, monthly_voice_mo?: any | null, monthly_voice_mo_limit?: any | null, nwc?: string | null, apn?: string | null, ip_address?: string | null, end_customer_label?: { __typename?: 'EndCustomerLabel', name: string } | null } | null };

export type GetUserConfigQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUserConfigQuery = { __typename?: 'Query', getUserConfig: Array<{ __typename?: 'KeyValue', key: string, value?: string | null } | null> };

export type GetStatisticsQueryVariables = Exact<{
  customerid?: InputMaybe<Scalars['Int']['input']>;
}>;


export type GetStatisticsQuery = { __typename?: 'Query', getStatistics: { __typename?: 'Statistic', totalSimCards: number, activeSimCards: number, currentMonthlyDataUsage: any, previousMonthDataUsage: any } };

export type SetUserConfigMutationVariables = Exact<{
  user_config: Array<InputMaybe<KeyValueInput>> | InputMaybe<KeyValueInput>;
}>;


export type SetUserConfigMutation = { __typename?: 'Mutation', setUserConfig: boolean };

export type ListSimEventsQueryVariables = Exact<{
  iccid: Scalars['ID']['input'];
  limit: Scalars['Int']['input'];
  nextToken?: InputMaybe<Scalars['String']['input']>;
  filterOptions?: InputMaybe<SimEventFilterInput>;
  order?: InputMaybe<SimEventOrder>;
}>;


export type ListSimEventsQuery = { __typename?: 'Query', listSimEvents?: { __typename?: 'SimEvents', nextToken?: string | null, totalEvents?: any | null, items?: Array<{ __typename?: 'SimEvent', id: string, iccid: string, details?: string | null, timestampMilliseconds: any, type: string, jsonData?: string | null } | null> | null } | null };

export type UpdatedSimEventSubscriptionVariables = Exact<{
  iccid: Scalars['ID']['input'];
}>;


export type UpdatedSimEventSubscription = { __typename?: 'Subscription', updatedSimEvent?: { __typename?: 'SimEvent', id: string, iccid: string, jsonData?: string | null, type: string, timestampMilliseconds: any, details?: string | null } | null };

export type GetDataConsumptionReportQueryVariables = Exact<{
  iccid: Scalars['ID']['input'];
  filterOptions: DataConsumptionFilterInput;
}>;


export type GetDataConsumptionReportQuery = { __typename?: 'Query', getDataConsumptionReport?: Array<{ __typename?: 'DataConsumption', day: string, bytes: any } | null> | null };

export type ListUserAccountsQueryVariables = Exact<{
  customerid?: InputMaybe<Scalars['Int']['input']>;
}>;


export type ListUserAccountsQuery = { __typename?: 'Query', listUserAccounts: Array<{ __typename?: 'UserAccount', id: string, email: string, groups?: Array<string | null> | null, locale: string, status: string, zoneinfo: string } | null> };

export type CreateUserAccountMutationVariables = Exact<{
  email: Scalars['String']['input'];
  locale: Scalars['String']['input'];
  zoneinfo: Scalars['String']['input'];
  groups?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>>;
  customerid: Scalars['Int']['input'];
}>;


export type CreateUserAccountMutation = { __typename?: 'Mutation', createUserAccount: { __typename?: 'UserAccount', email: string, groups?: Array<string | null> | null, id: string, locale: string, status: string, zoneinfo: string } };

export type UpdateUserAccountMutationVariables = Exact<{
  id: Scalars['String']['input'];
  locale: Scalars['String']['input'];
  zoneinfo: Scalars['String']['input'];
  groups?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>>;
}>;


export type UpdateUserAccountMutation = { __typename?: 'Mutation', updateUserAccount: { __typename?: 'UserAccount', zoneinfo: string, status: string, locale: string, id: string, groups?: Array<string | null> | null, email: string } };

export type DeleteUserAccountMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type DeleteUserAccountMutation = { __typename?: 'Mutation', deleteUserAccount: { __typename?: 'UserAccount', zoneinfo: string, status: string, locale: string, id: string, groups?: Array<string | null> | null, email: string } };

export type ResendUserAccountInviteMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type ResendUserAccountInviteMutation = { __typename?: 'Mutation', resendUserAccountInvite: boolean };

export type ListEndCustomerLabelsQueryVariables = Exact<{
  filter?: InputMaybe<ListEndCustomerLabelsFilterInput>;
  limit: Scalars['Int']['input'];
  nextToken?: InputMaybe<Scalars['String']['input']>;
}>;


export type ListEndCustomerLabelsQuery = { __typename?: 'Query', listEndCustomerLabels?: { __typename?: 'EndCustomerLabels', nextToken?: string | null, items?: Array<{ __typename?: 'EndCustomerLabel', name: string } | null> | null } | null };

export const ListSimsDocument = gql`
    query ListSims($filter: SimFilterInput, $order: SimListOrder, $limit: Int!, $nextToken: String, $quickSearch: String) {
  listSims(
    filter: $filter
    order: $order
    limit: $limit
    nextToken: $nextToken
    quickSearch: $quickSearch
  ) {
    items {
      iccid
      customerid
      statusid
      providerid
      imei
      imsi
      msisdn
      activation_timestamp
      in_session_since
      previous_month_data_volume
      monthly_data_volume
      monthly_data_limit
      monthly_sms_mo
      monthly_sms_mt
      monthly_sms @client
      monthly_sms_mo_limit
      monthly_sms_mt_limit
      monthly_voice_mo
      monthly_voice_mo_limit
      custom_field_1
      ip_address
      nwc
      apn
      end_customer_label {
        name
      }
    }
    nextToken
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ListSimsGQL extends Apollo.Query<ListSimsQuery, ListSimsQueryVariables> {
    override document = ListSimsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetCustomerDataDocument = gql`
    query GetCustomerData {
  getCustomerData {
    customerid
    is_test_customer
    current_name
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetCustomerDataGQL extends Apollo.Query<GetCustomerDataQuery, GetCustomerDataQueryVariables> {
    override document = GetCustomerDataDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateSimDocument = gql`
    mutation UpdateSim($iccid: ID!, $customerid: Int, $statusid: Int, $monthly_data_limit: Long, $custom_field_1: String, $monthly_sms_mo_limit: Long, $monthly_sms_mt_limit: Long, $monthly_voice_mo_limit: Long, $end_customer_label: EndCustomerLabelInput) {
  updateSim(
    iccid: $iccid
    customerid: $customerid
    statusid: $statusid
    monthly_data_limit: $monthly_data_limit
    custom_field_1: $custom_field_1
    monthly_sms_mo_limit: $monthly_sms_mo_limit
    monthly_sms_mt_limit: $monthly_sms_mt_limit
    monthly_voice_mo_limit: $monthly_voice_mo_limit
    end_customer_label: $end_customer_label
  ) {
    customerid
    activation_timestamp
    imei
    imsi
    msisdn
    iccid
    providerid
    statusid
    in_session_since
    monthly_data_volume
    monthly_data_limit
    monthly_sms_mo
    monthly_sms_mt
    monthly_sms @client
    monthly_sms_mo_limit
    monthly_sms_mt_limit
    monthly_voice_mo
    monthly_voice_mo_limit
    custom_field_1
    previous_month_data_volume
    apn
    ip_address
    nwc
    end_customer_label {
      name
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateSimGQL extends Apollo.Mutation<UpdateSimMutation, UpdateSimMutationVariables> {
    override document = UpdateSimDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SendSmsDocument = gql`
    mutation SendSms($iccid: ID!, $text: String!, $originator: String) {
  sendSms(iccid: $iccid, text: $text, originator: $originator)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SendSmsGQL extends Apollo.Mutation<SendSmsMutation, SendSmsMutationVariables> {
    override document = SendSmsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetUsageNotificationDocument = gql`
    query GetUsageNotification($customerid: Int) {
  getUsageNotification(customerid: $customerid) {
    id
    emails
    percent90
    percent100
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetUsageNotificationGQL extends Apollo.Query<GetUsageNotificationQuery, GetUsageNotificationQueryVariables> {
    override document = GetUsageNotificationDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AddUsageNotificationDocument = gql`
    mutation AddUsageNotification($customerid: Int, $emails: [String]!, $percent90: Boolean!, $percent100: Boolean!) {
  addUsageNotification(
    customerid: $customerid
    emails: $emails
    percent90: $percent90
    percent100: $percent100
  ) {
    id
    emails
    percent90
    percent100
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AddUsageNotificationGQL extends Apollo.Mutation<AddUsageNotificationMutation, AddUsageNotificationMutationVariables> {
    override document = AddUsageNotificationDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateUsageNotificationDocument = gql`
    mutation UpdateUsageNotification($id: Int!, $customerid: Int, $emails: [String]!, $percent90: Boolean!, $percent100: Boolean!) {
  updateUsageNotification(
    id: $id
    customerid: $customerid
    emails: $emails
    percent90: $percent90
    percent100: $percent100
  ) {
    id
    emails
    percent90
    percent100
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateUsageNotificationGQL extends Apollo.Mutation<UpdateUsageNotificationMutation, UpdateUsageNotificationMutationVariables> {
    override document = UpdateUsageNotificationDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteUsageNotificationDocument = gql`
    mutation DeleteUsageNotification($id: Int!) {
  deleteUsageNotification(id: $id) {
    id
    emails
    percent90
    percent100
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteUsageNotificationGQL extends Apollo.Mutation<DeleteUsageNotificationMutation, DeleteUsageNotificationMutationVariables> {
    override document = DeleteUsageNotificationDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CancelLocationDocument = gql`
    mutation CancelLocation($iccid: ID!) {
  cancelLocation(iccid: $iccid) {
    blockedUntil
    sent
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CancelLocationGQL extends Apollo.Mutation<CancelLocationMutation, CancelLocationMutationVariables> {
    override document = CancelLocationDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetAvailableStatusTransitionsDocument = gql`
    query GetAvailableStatusTransitions {
  getAvailableStatusTransitions {
    fromstatusid
    tostatusid
    providerid
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetAvailableStatusTransitionsGQL extends Apollo.Query<GetAvailableStatusTransitionsQuery, GetAvailableStatusTransitionsQueryVariables> {
    override document = GetAvailableStatusTransitionsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdatedSimDocument = gql`
    subscription UpdatedSim($customerid: Int) {
  updatedSim(customerid: $customerid) {
    activation_timestamp
    customerid
    iccid
    imei
    imsi
    msisdn
    providerid
    statusid
    in_session_since
    monthly_data_volume
    monthly_data_limit
    monthly_sms_mo
    monthly_sms_mt
    custom_field_1
    previous_month_data_volume
    monthly_sms @client
    monthly_sms_mo_limit
    monthly_sms_mt_limit
    monthly_voice_mo
    monthly_voice_mo_limit
    nwc
    apn
    ip_address
    end_customer_label {
      name
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdatedSimGQL extends Apollo.Subscription<UpdatedSimSubscription, UpdatedSimSubscriptionVariables> {
    override document = UpdatedSimDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetUserConfigDocument = gql`
    query GetUserConfig {
  getUserConfig {
    key
    value
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetUserConfigGQL extends Apollo.Query<GetUserConfigQuery, GetUserConfigQueryVariables> {
    override document = GetUserConfigDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetStatisticsDocument = gql`
    query GetStatistics($customerid: Int) {
  getStatistics(customerid: $customerid) {
    totalSimCards
    activeSimCards
    currentMonthlyDataUsage
    previousMonthDataUsage
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetStatisticsGQL extends Apollo.Query<GetStatisticsQuery, GetStatisticsQueryVariables> {
    override document = GetStatisticsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SetUserConfigDocument = gql`
    mutation SetUserConfig($user_config: [KeyValueInput]!) {
  setUserConfig(user_config: $user_config)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SetUserConfigGQL extends Apollo.Mutation<SetUserConfigMutation, SetUserConfigMutationVariables> {
    override document = SetUserConfigDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ListSimEventsDocument = gql`
    query ListSimEvents($iccid: ID!, $limit: Int!, $nextToken: String, $filterOptions: SimEventFilterInput, $order: SimEventOrder) {
  listSimEvents(
    iccid: $iccid
    limit: $limit
    nextToken: $nextToken
    filterOptions: $filterOptions
    order: $order
  ) {
    items {
      id
      iccid
      details
      timestampMilliseconds
      type
      jsonData
    }
    nextToken
    totalEvents
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ListSimEventsGQL extends Apollo.Query<ListSimEventsQuery, ListSimEventsQueryVariables> {
    override document = ListSimEventsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdatedSimEventDocument = gql`
    subscription UpdatedSimEvent($iccid: ID!) {
  updatedSimEvent(iccid: $iccid) {
    id
    iccid
    jsonData
    type
    timestampMilliseconds
    details
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdatedSimEventGQL extends Apollo.Subscription<UpdatedSimEventSubscription, UpdatedSimEventSubscriptionVariables> {
    override document = UpdatedSimEventDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetDataConsumptionReportDocument = gql`
    query GetDataConsumptionReport($iccid: ID!, $filterOptions: DataConsumptionFilterInput!) {
  getDataConsumptionReport(iccid: $iccid, filterOptions: $filterOptions) {
    day
    bytes
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetDataConsumptionReportGQL extends Apollo.Query<GetDataConsumptionReportQuery, GetDataConsumptionReportQueryVariables> {
    override document = GetDataConsumptionReportDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ListUserAccountsDocument = gql`
    query listUserAccounts($customerid: Int) {
  listUserAccounts(customerid: $customerid) {
    id
    email
    groups
    locale
    status
    zoneinfo
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ListUserAccountsGQL extends Apollo.Query<ListUserAccountsQuery, ListUserAccountsQueryVariables> {
    override document = ListUserAccountsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateUserAccountDocument = gql`
    mutation createUserAccount($email: String!, $locale: String!, $zoneinfo: String!, $groups: [String], $customerid: Int!) {
  createUserAccount(
    email: $email
    locale: $locale
    zoneinfo: $zoneinfo
    groups: $groups
    customerid: $customerid
  ) {
    email
    groups
    id
    locale
    status
    zoneinfo
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateUserAccountGQL extends Apollo.Mutation<CreateUserAccountMutation, CreateUserAccountMutationVariables> {
    override document = CreateUserAccountDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateUserAccountDocument = gql`
    mutation updateUserAccount($id: String!, $locale: String!, $zoneinfo: String!, $groups: [String]) {
  updateUserAccount(
    id: $id
    groups: $groups
    locale: $locale
    zoneinfo: $zoneinfo
  ) {
    zoneinfo
    status
    locale
    id
    groups
    email
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateUserAccountGQL extends Apollo.Mutation<UpdateUserAccountMutation, UpdateUserAccountMutationVariables> {
    override document = UpdateUserAccountDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteUserAccountDocument = gql`
    mutation deleteUserAccount($id: String!) {
  deleteUserAccount(id: $id) {
    zoneinfo
    status
    locale
    id
    groups
    email
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteUserAccountGQL extends Apollo.Mutation<DeleteUserAccountMutation, DeleteUserAccountMutationVariables> {
    override document = DeleteUserAccountDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ResendUserAccountInviteDocument = gql`
    mutation resendUserAccountInvite($id: String!) {
  resendUserAccountInvite(id: $id)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ResendUserAccountInviteGQL extends Apollo.Mutation<ResendUserAccountInviteMutation, ResendUserAccountInviteMutationVariables> {
    override document = ResendUserAccountInviteDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ListEndCustomerLabelsDocument = gql`
    query listEndCustomerLabels($filter: ListEndCustomerLabelsFilterInput, $limit: Int!, $nextToken: String) {
  listEndCustomerLabels(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      name
    }
    nextToken
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ListEndCustomerLabelsGQL extends Apollo.Query<ListEndCustomerLabelsQuery, ListEndCustomerLabelsQueryVariables> {
    override document = ListEndCustomerLabelsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }