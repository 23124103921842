import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import {
    CognitoUser,
    CognitoUserAttribute,
    CognitoUserPool,
    CognitoUserSession,
    UpdateAttributesNodeCallback
} from 'amazon-cognito-identity-js';
import { Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { LoggerService } from '../logger';
import { Auth } from './WSimAuth';

export const LOGGED_IN = 'LOGGED_IN';
export const LOGGED_OUT = 'LOGGED_OUT';

@Injectable({
    providedIn: 'root'
})
export class AuthService {

    private loginStatus = new Subject<string>();
    private readonly loggedIn: Promise<boolean>;

    public constructor(private router: Router, private ngZone: NgZone) {
        this.loggedIn = new Promise((resolve) => {
            if (this.isLoggedIn()) {
                resolve(true);
            } else {
                this.loginStatus.asObservable().subscribe(() => resolve(true));
            }
        });
    }

    public isLoggedIn(): boolean {
        let isAuth = false;
        const cognitoUser = this.getCurrentUser();
        if (cognitoUser !== null) {
            cognitoUser.getSession((err: Error | null, session: CognitoUserSession) => {
                if (!err) {
                    isAuth = session.isValid();
                    Auth.setCurrentUserSession(session);
                }
            });
        }
        return isAuth;
    }

    public getCurrentUser(): CognitoUser | null {
        if (Auth.getCurrentUser() === null) {
            LoggerService.debug('getCurrentUser(): user is null, creating new one!');
            const userPool = new CognitoUserPool(environment.cognito);
            const currentUser = userPool.getCurrentUser();
            Auth.setCurrentUser(currentUser);
            if (currentUser) {
                Auth.setCurrentUserSession(currentUser.getSignInUserSession());
            }
        }
        return Auth.getCurrentUser();
    }

    public logout(global = false) {
        const callback = () => {
            Auth.clear();
            this.ngZone.run(() => {
                this.router.navigate(['/account/signin']).then();
                this.loginStatus.next(LOGGED_OUT);
            });
        };

        if (!global) {
            this.getCurrentUser()?.signOut(callback);
        } else {
            this.getCurrentUser()?.globalSignOut({
                onSuccess: callback,
                onFailure: (err: Error) => {
                    LoggerService.warn('logout globally: got error: ', err);
                    this.logout(false);
                }
            });
        }
    }

    public subscribeAuthStatus(): Observable<string> {
        return this.loginStatus.asObservable();
    }

    public getLoggedInPromise() {
        return this.loggedIn;
    }

    /**
     * Update a specific user attribute in cognito user
     * @param attributeName the name of the attribute, keep in mind, e.g. customer id key is "custom:customer_id"
     * @param attributeValue the value of the attribute to update the value to in cognito
     * @param callback the callback that gets called when attribute change was done (successfully or not)
     */
    public updateUserAttribute(attributeName: string, attributeValue: string,
                               callback: UpdateAttributesNodeCallback<Error, string, string>) {
        const customAttribute = new CognitoUserAttribute({
            Name: attributeName,
            Value: attributeValue
        });
        this.getCurrentUser()?.updateAttributes([customAttribute], callback);
    }

    /**
     * Broadcast a login successful
     */
    public broadcastLoggedIn(): void {
        this.loginStatus.next(LOGGED_IN);
    }

}

