<div (click)="startEditMode()" class="container-fluid p-0">
  <div class="d-flex d-inline-flex g-2">
    <div (click)="startEditMode()">
      {{ dataVolumeLimit | byteFormat }}
    </div>
    <div *ngIf="showEdit" (click)="startEditMode()" class="btn">
      <i class="fa-solid fa-pen"></i>
    </div>
  </div>
</div>
