<div [style.width]="cellWidth" class="container-fluid p-0">
  <div class="row align-items-center g-0 p-0 ps-1 pe-1">
    <div class="col align-items-center">
      <label class="visually-hidden col-form-label col-form-label-sm" for="inlineFormInputGroupLimit">Limit</label>
      <div class="input-group input-group-sm">
        <input #limitInput (keydown)="onKeyDown($event)"
               [(ngModel)]="unsavedLimit" class="form-control form-control-sm number-input"
               digitOnly id="inlineFormInputGroupLimit" required
               type="text">
        <div class="input-group-text">
          <div class="dropdown position-static" data-cy="data-volume-dropdown">
            <button aria-expanded="false" class="btn button-size dropdown-toggle fw-semibold" data-bs-toggle="dropdown"
                    type="button">
              {{ currentDataSize }}
            </button>
            <div class="dropdown-menu">
              <a (click)="changeDataSize(size)" *ngFor="let size of availableDataSizes" class="dropdown-item">
                <button class="btn button-size fw-semibold pe-none" type="button">
                  {{ size }}
                </button>
              </a>
            </div>
          </div>
        </div>
        <a (click)="submitSave($event)" data-cy="data-volume-save-button" class="btn btn-primary">
          <i *ngIf="!saving" class="fa-solid fa-check"></i>
          <i *ngIf="saving" class="fa-solid fa-solid fa-circle-notch fa-spin"></i>
        </a>
      </div>
    </div>
  </div>
</div>

