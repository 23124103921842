<div class="d-flex flex-row">
  <div class="ps-2"><i class="fa-solid fa-circle-info info-color"></i></div>
  <div class="ps-1">
    <div class="row">
      <div>{{ 'consumption.usageUntil' | i18next }}</div>
    </div>
    <div class="row" *ngIf="data.json.dataDaily">
      <div>{{ 'consumption.dataToday' | i18next: {data: data.json.dataDaily | byteFormat} }}</div>
    </div>
    <div class="row" *ngIf="data.json.dataMonthly">
      <div>{{ 'consumption.dataMonthly' | i18next: {data: data.json.dataMonthly | byteFormat} }}</div>
    </div>
    <div class="row" *ngIf="data.json.smsDaily">
      <div>{{ 'consumption.smsToday' | i18next: {data: data.json.smsDaily} }}</div>
    </div>
    <div class="row" *ngIf="data.json.smsMonthly">
      <div>{{ 'consumption.smsMonthly' | i18next: {data: data.json.smsMonthly} }}</div>
    </div>
    <div class="row" *ngIf="data.json.voiceDaily">
      <div>{{ 'consumption.callsToday' | i18next: {data: data.json.voiceDaily | durationFormat} }}</div>
    </div>
    <div class="row" *ngIf="data.json.voiceMonthly">
      <div>{{ 'consumption.callsMonthly' | i18next: {data: data.json.voiceMonthly | durationFormat} }}</div>
    </div>
  </div>
</div>
