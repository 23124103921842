<button class="btn btn-secondary btn-sm" id="cancelLocationBtn" style="pointer-events: auto; "
        [disabled]="isLoading || btnDisable" (click)="sendRequest()" data-cy="cancel-location-btn">
  <i [class]="isLoading ? 'fa-solid fa-circle-notch fa-spin' : 'fa-solid fa-rotate-right'"></i>
  {{ 'button.cancel_location.title' | i18nextCap }}
</button>
<app-cancel-location-popover position="left"
                             [isRequestAvailable]="!btnDisable"
                             [blockedUntil]="blockedUntil"
                             [iccid]="sim?.iccid">
</app-cancel-location-popover>
