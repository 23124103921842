import { Component, Input } from '@angular/core';
import { HorizontalAlignment, PositionAlignment, VerticalAlignment } from 'devextreme/common';

@Component({
    selector: 'app-status-popover',
    templateUrl: './status-popover.component.html',
    styleUrl: './status-popover.component.scss'
})
export class StatusPopoverComponent {

    @Input({required: true})
    public statusId: number = -1;

    @Input({required: true})
    public componentId: string | undefined;

    @Input()
    public position: PositionAlignment | { x?: HorizontalAlignment, y?: VerticalAlignment } | undefined;

}
