<div class="d-flex align-items-center" xmlns="http://www.w3.org/1999/html">
  <div class="flex-grow-1">{{ 'table.header.timestamp' | i18next: {format: 'Cap'} }}</div>
  <i #filter class="ps-1 fa-solid fa-filter" [ngClass]="(filterActive)?'filter-selected':'filter-not-selected'"
     (click)="openFilter($event)"></i>
</div>

<!-- popover opening on clicking the filter icon -->
<dx-popover
  #popover
  [hideOnOutsideClick]="true"
  [height]="'auto'"
  [width]="'auto'"
  [shading]="false"
  [showCloseButton]="false"
  [showTitle]="false">

  <!-- set position of the popover with offset to have the arrow more up than center! -->
  <dxo-position my="center left" at="right" [of]="filter" offset="0 130"></dxo-position>

  <div class="d-flex mb-2"><b>Filter</b></div>
  <!-- radio group with pre-selections and date selector -->
  <div class="pt-2 d-flex">
    <dx-radio-group #radioGroup
                    [dataSource]="dataSource"
                    [ngModel]="selectedValue"
                    (onOptionChanged)="optionChanged($event)"
                    itemTemplate="radio">
      <div *dxTemplate="let data of 'radio'" class="radio">
        <!-- preselected date ranges -->
        <div *ngIf="data.value !== RANGE" class="ps-1">{{ data.text }}</div>
        <!-- user specified date range -->
        <div *ngIf="data.value === RANGE" class="align-items-center">
          <div class="d-flex flex-row align-items-center ps-1">{{ 'timestampFilter.userDefined' | i18next }}</div>
          <div class="d-flex flex-row align-items-center pt-1">
            <dx-date-box #startDateBox
                         class="left-rounded-corners"
                         type="date"
                         [width]="75" [height]="30"
                         [useMaskBehavior]="true"
                         [displayFormat]="dateFormat"
                         [showDropDownButton]="false"
                         (onValueChanged)="handleValueChange($event, 'start')"
                         [(value)]="startDate"
                         (click)="openDateBox(startDateBox)">
            </dx-date-box>
            <div class="vr"></div>
            <dx-date-box #stopDateBox
                         class="right-rounded-corners"
                         type="date"
                         [width]="75" [height]="30"
                         [useMaskBehavior]="true"
                         [displayFormat]="dateFormat"
                         [showDropDownButton]="false"
                         (onValueChanged)="handleValueChange($event, 'stop')"
                         [(value)]="stopDate"
                         (click)="openDateBox(stopDateBox)">
            </dx-date-box>
          </div>
        </div>
      </div>
    </dx-radio-group>
  </div>
  <!-- clear filter button -->
  <div class="pt-2 d-flex justify-content-center">
    <button class="btn btn-secondary btn-sm btn-block w-100 btn-border" type="button" aria-expanded="false"
            (click)="deleteFilter(true)">
      <i class="fa-solid fa-trash-can pe-2"></i> {{ 'deleteFilter' | i18next }}
    </button>
  </div>
</dx-popover>


