import { Component } from '@angular/core';
import { environment } from '../../../environments/environment';

@Component({
    selector: 'app-password-strength',
    templateUrl: './password-strength.component.html',
    styleUrl: './password-strength.component.scss'
})
export class PasswordStrengthComponent {

    // password guidelines
    protected passwordGuideLines = {
        minimumLength: {
            result: false,
            regEx: '^.{' + environment.passwordRequirements.minLength + ',}$'
        },
        atLeastOneNumber: {
            result: false,
            regEx: '[0-9]'
        },
        atLeastOneSmallLetter: {
            result: false,
            regEx: '[a-z]'
        },
        atLeastOneBigLetter: {
            result: false,
            regEx: '[A-Z]'
        },
        atLeastOneSpecialChar: {
            result: false,
            regEx: '[\\^|\\$|\\*|\\.|\\[|\\]|\\{|\\}|\\(|\\$)|\\?|\\-|\\"|\\!|\\@|\\#|\\%|\\&|\\/|\\\\|\\,|\\<|\\>|\\:|\\;' +
                '|\\||\\_|\\~|\\`|\\+|\\=|\']'
        }
    };

    protected readonly specialChars = '^ $ * . [ ] { } ( ) ? " ! @ # % & / \\ , > < \' : ; | _ ~ ` = + - ';

    public checkPassword(password: string): boolean {
        const result = Object.values(this.passwordGuideLines).map(e => {
            const checkResult = password.match(e.regEx);
            e.result = (checkResult !== null);
            return (checkResult !== null);
        });

        return result.every(Boolean);
    }

    protected readonly environment = environment;
}
