import { AfterViewChecked, ChangeDetectorRef, Component, inject, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CognitoUser, CognitoUserPool } from 'amazon-cognito-identity-js';
import { I18NextPipe } from 'angular-i18next';
import { environment } from '../../../../environments/environment';
import { ToastMessageBuilder, ToastService } from '../../../service/toast.service';
import { PasswordStrengthComponent } from '../../../shared/password-strength/password-strength.component';

@Component({
    selector: 'app-set-password',
    templateUrl: './set-password.component.html',
    styleUrl: './set-password.component.scss'
})
export class SetPasswordComponent implements OnInit, AfterViewChecked {

    protected form: FormGroup;
    protected submitDisabled: boolean = false;
    protected userEmail?;
    protected readonly environment = environment;

    // This is set by path variable
    @Input()
    public code? = undefined;

    protected newPassword? = undefined;
    protected passwordGuidelinesMatch: boolean = false;

    @ViewChild(PasswordStrengthComponent)
    private passwordStrengthComponent!: PasswordStrengthComponent;

    private readonly changeDetectorRef = inject(ChangeDetectorRef);
    private router = inject(Router);
    private formBuilder = inject(FormBuilder);
    private toastService = inject(ToastService);
    private i18next = inject(I18NextPipe);
    private activeRoute = inject(ActivatedRoute);

    public constructor() {
        this.userEmail = localStorage.getItem('user.email') || undefined;

        this.form = this.formBuilder.group({
            emailAddress: [this.userEmail, Validators.required],
            code: [null, [Validators.required]],
            newPassword: [null, [Validators.required]]
        });
    }

    public ngOnInit(): void {
        if (this.code) {
            this.form.controls['code'].setValue(this.code);
        }
    }

    public ngAfterViewChecked(): void {
        // fixes an exception for the change detector from time to time
        this.changeDetectorRef.detectChanges();
    }

    protected doSubmit() {
        this.submitDisabled = true;

        const formValue = this.form.value;
        const emailAddress = formValue['emailAddress'];
        const code = formValue['code'];
        const newPassword = formValue['newPassword'];

        const userPool = new CognitoUserPool(environment.cognito);
        const userData = { Username: emailAddress, Pool: userPool };
        const cognitoUser = new CognitoUser(userData);
        cognitoUser.confirmPassword(code, newPassword, {
            onSuccess: () => {
                const message = this.i18next.transform('reset-password.toast-successful');
                this.toastService.show(ToastMessageBuilder.success().text(message).build());
                this.router.navigate(['/']).then();
            },
            onFailure: (err: Error) => {
                this.submitDisabled = false;
                const message = this.i18next.transform('reset-password.error.' + err.name, {
                    defaultValue: this.i18next.transform('defaultError', { error: err }),
                    error: err
                });
                this.toastService.show(ToastMessageBuilder.error().text(message).build());
                this.router.navigate(['..'], { relativeTo: this.activeRoute }).then();
            }
        });
    }

    protected onOldPasswordChanged() {
        const newPass = this.form.value['newPassword'];
        this.passwordGuidelinesMatch = this.passwordStrengthComponent.checkPassword(newPass);
    }
}
